<template>
  <div>
    <div :class="this.classValue" :data-label="Label+ this.invalidFeedback">
      <div class="form-group">
        <multiselect
          :label="labelOptions"
          :track-by="valueOptions"
          :tag-placeholder="Placeholder"
          :close-on-select="closeOnSelect"
          v-model="model"
          :options="Options"
          :multiple="Multiple"
          :taggable="true"
          @input="changeDropDown($event)"                
          :disabled="IsDisabled"
          maxlength="this.MaxLength"
          :aria-describedby="Name"
          :id="Name"
          :name="Name"
          :allowEmpty="AllowEmpty"
          :custom-label="CustomLabel"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
            <span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
            >{{ values.length }} options selected</span>
          </template>
        </multiselect>
        <!-- <b-form-invalid-feedback v-if="isRequired" :id="Name">
                      {{FeedBackText}}
        </b-form-invalid-feedback> -->
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { EventBus } from '@/event-bus.js';
export default {
  components: { Multiselect },
  props: {
    Options: Array,
    ModelArray: Object,
    Model: [String, Number,Boolean,Object,Array],
    Placeholder: String,
    Multiple: Boolean,
    Input: Function,    
    Label: String,
    FieldDescription: String,
    FieldCode: String,
    IsDisabled: Boolean,
    MaxLength: String,
    isRequired:Boolean,
    isValid:Boolean,
    FeedBackText:String,
    Name:String,
    IfUsevalidation:Boolean,
    Tag: String,
    RequireEvent:Boolean,
    Position:Number,
    UseParamsAux:Boolean,
    AuxParam:String,
    UseResetModel:Boolean,    
    AllowEmpty: {
      default: true,
      type: Boolean
    },
    CustomLabel: Function   
   
  },
  watch: {
    Model: async function(newVal) {
       let optionsList = await this.Options
       if (this.counterModel == 0 && newVal && optionsList && optionsList.length > 0) {
        var result = optionsList.find(valueIndex => valueIndex[this.FieldCode] === newVal);
        if (result && result!=='undefined'){
          newVal = result[this.FieldCode];
          const description = result[this.FieldDescription];
          this.model = { [this.FieldCode ? this.FieldCode : 'value']: newVal, [this.FieldDescription ? this.FieldDescription : 'text']: description };
        }  
        this.counterModel++
      }
      if(newVal === ''){
         this.model = { [this.FieldCode ? this.FieldCode : 'value']: "", [this.FieldDescription ? this.FieldDescription : 'text']: "" };
      }
    },
    isValid: function (newValue) {
      this.classValue= newValue ? "textlabel" : "textlabel-invalid"
      this.invalidFeedback = newValue ? "": ": "+ this.$t('Msg.RequiredField')
    },
    ModelArray: function(newValue) {
      this.model = newValue
    }
  },

  data() {
    return {
      model: this.ModelArray ? this.ModelArray : this.Model,
      closeOnSelect: this.Multiple ? false : true,
      labelOptions: this.FieldDescription ? this.FieldDescription : 'text',
      valueOptions: this.FieldCode ? this.FieldCode : 'value',
      counterModel: 0,
      classValue:"textlabel",
      invalidFeedback:""
    }
  },

  methods: {

    setValid(state){       
      this.classValue= state ? "textlabel" : "textlabel-invalid"
      this.invalidFeedback = state ? "": ": "+this.$t('Msg.RequiredField')
    },

    
    changeDropDown(evt) {          
      this.checkIsRequired(evt)
      if (this.Input) {   
       this.UseParamsAux ? this.Input(this.model,this.AuxParam) : this.Input(this.model,this.Position);
        var changed = {
        "Changed":true,
        "ComponentName":this.Label,
        "Tag": this.Tag,
        "Value" : this.model,        
        }
        EventBus.$emit('onChanged', changed);        
      }
    },

    checkIsRequired(evt){
      if (this.IfUsevalidation == false) {
        return;
      }
      let Invalid = evt[this.valueOptions].length == 0 && this.isRequired ? true : false;
      this.classValue= Invalid ? "textlabel-invalid" : ""
       this.invalidFeedback = Invalid ? ": "+this.$t('Msg.InvalidField'): ""
    },

    clearSelection() {
      this.model = null
    }
  },

  created(){
    EventBus.$on("ChangedDrop", function (e) {   
      if(this.RequireEvent){
        this.model = e;
      }    
          
     }.bind(this));

     EventBus.$on("OnResetModelSelect", function () {  
      if(this.UseResetModel){
        this.model = null; 
      }
        
     }.bind(this));
  },
  mounted() {
    
  }
}

</script>
<style src="@/assets/css/clinical/multiselect.css">
@import "../../../assets/css/flex.css";
</style>