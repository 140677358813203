<template>
   <div class="row page-height">
      <div class="col-12">
      <h4 v-if="this.Mode" class="page-title text-truncate"> {{$t('doctor.Edit')}} </h4>
      <h4 v-if="!this.Mode" class="page-title text-truncate"> {{$t('doctor.Add')}} </h4>
         <b-row>
            <b-col md="3" lg="3" sm="12">
              <MdEdit 
                     v-bind:Label="$t('Shared.Code')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'code'"
                      v-bind:isRequired="true"
                     v-bind:MaxLengthProp="8"
                     v-bind:isValid="this.isCodeValid"
                     v-bind:isReadOnly="this.Mode"
                     v-on:blur="OnCodeBlur(doctor.code)"
                     v-model="doctor.code"
                     v-bind:Id="'input_d_code'">                                     
               </MdEdit>
            </b-col>
             <b-col md="3" lg="3" sm="12">
              <MdEdit 
                     v-bind:Label="$t('Shared.FirstName')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'firstname'"
                     v-bind:isRequired="true"
                     v-bind:MaxLengthProp="15"
                     v-bind:isValid="this.isFirstNameValid"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_doctor_first_name'"
                     v-model="doctor.firstname">                                     
               </MdEdit>
            </b-col>
             <b-col md="3" lg="3" sm="12">
              <MdEdit 
                     v-bind:Label="$t('Shared.LastName')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'LastName'"
                     v-bind:isRequired="true"
                     v-bind:isValid="this.isLastNameValid"
                     v-bind:Id="'input_doctor_lastname'"
                     v-bind:MaxLengthProp="15"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.lastname">                                     
               </MdEdit>
            </b-col>
             <b-col md="3" lg="3" sm="12">
              <MdEdit 
                     v-bind:Label="$t('Patient.Initial')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'INITIAL'"
                     v-bind:MaxLengthProp="1"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.initial"
                     v-bind:Id="'input_d_ini'">                                     
               </MdEdit>
            </b-col>            
         </b-row>
         <b-row>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Shared.Address1')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'Address1'"
                     v-bind:MaxLengthProp="30"
                     v-model="doctor.address1"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_d_addrs1'">
               </MdEdit>
            </b-col>
             <b-col lg="3" md="3" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Shared.Address2')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'Address2'"
                     v-bind:MaxLengthProp="30"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.address2"
                     v-bind:Id="'input_d_addrs2'">                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Shared.Zip')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'Zip'"
                     v-bind:MaxLengthProp="10"
                     v-bind:isReadOnly="isReadOnly"
                     v-on:blur="doctorZipBlur(doctor.zip)"
                     v-on:change="doctorZipChange($event)"
                     v-model="doctor.zip"
                     v-bind:Id="'input_d_zip'">                                     
               </MdEdit>
            </b-col>
             <b-col lg="3" md="3" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Shared.City')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'City'"
                     v-bind:MaxLengthProp="30"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.city"
                     v-bind:Id="'input_d_city'">                                     
               </MdEdit>
            </b-col>
        </b-row>
         <b-row>
             <b-col lg="3" md="3" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Shared.State')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'State'"
                     v-bind:MaxLengthProp="2"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.state"
                     v-bind:Id="'input_d_state'">                                     
               </MdEdit>
            </b-col>
             <b-col lg="3" md="3" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Shared.Country')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'Country'"
                     v-bind:MaxLengthProp="30"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.country"
                     v-bind:Id="'input_d_country'">                                     
               </MdEdit>
            </b-col>
           <b-col md="3" lg="3" sm="12">
              <MdEdit 
                     v-bind:Label="$t('employee.Company')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'Company'"
                     v-bind:MaxLengthProp="50"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.company"
                     v-bind:Id="'input_d_company'">                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                     v-bind:Label="$t('doctor.ContactFirstName')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'ContactFirstName'"
                     v-bind:MaxLengthProp="50"
                     v-model="doctor.contactFirstName"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_d_cont_fn'">
               </MdEdit>
            </b-col>            
         </b-row>
         <b-row>
             <b-col md="3" lg="3" sm="12">
              <MdEdit 
                     v-bind:Label="$t('doctor.ContactLastName')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'ContactLastName'"
                     v-bind:MaxLengthProp="50"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.contact"
                     v-bind:Id="'input_d_cont_ln'">                                     
               </MdEdit>
            </b-col>
             <b-col lg="3" md="3" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Shared.Phone')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'Phone'"
                     v-bind:Mask="'(###)###-####'"
                     v-bind:MaxLengthProp="15"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.phone"
                     v-bind:Id="'input_d_ph'">                                     
               </MdEdit>
            </b-col>
             <b-col lg="3" md="3" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Patient.LegalFax')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'Fax'"
                     v-bind:Mask="'(###)###-####'"
                     v-bind:MaxLengthProp="15"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.fax"
                     v-bind:Id="'input_d_lf'">                                     
               </MdEdit>
            </b-col>    
              <b-col lg="3" md="3" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Shared.Email')"
                     v-bind:TypeProp="'email'" 
                     v-bind:Name="'Email'"
                     v-bind:MaxLengthProp="50"
                     v-model="doctor.email"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_d_email'">
               </MdEdit>
            </b-col>              
         </b-row>
         <b-row>
             <b-col md="3" lg="3" sm="12">
              <MdEdit 
                     v-bind:Label="$t('doctor.LOCALITY')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'Locality'"
                     v-bind:MaxLengthProp="3"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.locality"
                     v-bind:Id="'input_d_loc'">                                     
               </MdEdit>
            </b-col>  
             <b-col md="3" lg="3" sm="12">
              <MdEdit 
                     v-bind:Label="$t('doctor.DoctorID')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'doctorId'"
                     v-bind:MaxLengthProp="15"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.doctorId">                                     
               </MdEdit>
            </b-col>                            
            <b-col lg="3" md="3" sm="12">
               <MdLookup
                     v-bind:Label="$t('employee.UserID')"
                     v-bind:ModelCode="doctor.userid"
                     v-bind:ModelDescription="Userdescription"
                     v-bind:RefCode="'UserIdCode'"
                     v-bind:Fields="UserFields"
                     v-bind:Api="'Users'"
                     v-bind:FieldCode="'userCode'"
                     v-bind:FieldDescription="'userName'"                   
                     :SearchTitle="'Users'"
                     v-bind:WrittenCodeisValid="UserIdWrittedIsValid"
                     :GetValues="onCodeDescriptionOk"
                     :InvalidFeedback="'Invalid UserId'"
                     :SearchFieldCode="'Code'"
                     v-bind:IsDisabled="isReadOnly" 
               />
            </b-col>             
            <b-col lg="3" md="3" sm="12">
               <MdEdit 
                     v-bind:Label="$t('employee.Credentials')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'Credentials'"
                     v-bind:MaxLengthProp="9"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.credentials"
                     v-bind:Id="'input_d_cred'">                                     
               </MdEdit>
            </b-col>      
         </b-row>

         <MdTitle v-bind:Text="$t('doctor.Reference')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
         <b-row>
             <b-col sm="12" md="3" lg="3">  
                 <MdEdit 
                     v-bind:Label="$t('doctor.Upin')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'Country'"
                     v-bind:MaxLengthProp="6"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.upin"
                     v-bind:Id="'input_d_uoin'">                                     
               </MdEdit>
             </b-col>
              <b-col sm="12" md="3" lg="3">  
                 <MdEdit 
                     v-bind:Label="$t('Contact.SSN')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'SSN'"
                     v-bind:MaxLengthProp="11"
                     v-bind:Mask="'###-##-####'"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.ssn"
                     v-bind:Id="'input_d_ssn'">                                     
               </MdEdit>
             </b-col>
              <b-col sm="12" md="3" lg="3">  
                 <MdEdit 
                     v-bind:Label="'EIN'"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'EIN'"
                     v-bind:MaxLengthProp="10"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.ein"
                     v-bind:Id="'input_d_ein'">                                     
               </MdEdit>
             </b-col>
              <b-col sm="12" md="3" lg="3">  
                 <MdEdit 
                     v-bind:Label="$t('doctor.LicenceNo')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'LicenceNo'"
                     v-bind:MaxLengthProp="15"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.me">                                     
               </MdEdit>
             </b-col>
         </b-row>
         <b-row>
             <b-col sm="12" md="3" lg="3">  
                 <MdEdit 
                     v-bind:Label="'NPI'"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'NPI'"
                     v-bind:MaxLengthProp="15"
                     v-bind:isReadOnly="isReadOnly"
                     v-model="doctor.npi"
                     v-bind:Id="'input_d_npi'">                                     
               </MdEdit>
             </b-col>
             <b-col sm="12" md="3" lg="3">  
                 <MdSelect
                     v-bind:Label="$t('doctor.Specialty')"
                     data-field="Specialty"
                     v-bind:Options="SpecialtyList"
                     v-bind:FieldDescription="'description'"
                     v-bind:FieldCode="'code'"
                     v-bind:Model="doctor.type"                       
                     v-bind:Multiple="false"
                     :Input="ChangeSpecialty"
                     v-bind:IsDisabled="isReadOnly"
               />
             </b-col>
         </b-row>
      <!--
         <MdTitle v-bind:Text="'Provider ID'" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
         <b-row>
            <b-col sm="12" md="12" lg="12">  
               <MdTableProvider 
                v-bind:items="ProviderItems"
                v-bind:ObjectItems="ObjectItems"
                v-bind:fields="fields"     
                v-bind:isReadOnly="isReadOnly"               
              />
            </b-col>
         </b-row>-->
                  
         <div class="space-actions"></div>
         <div class="page-actions">
            <MdButton
               v-bind:Label="$t('doctor.List')"
               v-bind:Name="'btndoctorlist'"
               v-bind:ClassProp="'secondary mx-2 btn-login'"
               v-on:click="DiscardChanges"/>
            <MdButton
               v-bind:Label="$t('Shared.Save')"
               v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
               v-on:click="SaveOrEdit"
               v-bind:isReadOnly="isReadOnly"
               v-bind:Name="'btn_save_doctor_setup'"
               />
         </div>
      </div>
   </div>
</template>

<script>

//import EnumService from '@/services/enum.service' 
import DoctorService from '@/services/doctor.service'
//import Utils from '@/common/utils.js'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
//import StorageService from '@/services/storage.service'
import { EventBus } from '@/event-bus.js';
import GlobalService from '@/services/global.service'
//import moment from 'moment'

let elemSaveBtn = '';
export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },

   beforeRouteLeave(to, from, next) {
	if (!this.isReadOnly && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('doctor.DoctorSetupTitle'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              next();
            } else {
              this.SaveOrEdit();
              next();
            }
          })		
	} else {
		next()
	}
    },

   mixins: [ShowMessage, DBFunctions],
   name: 'DoctorDetails',  
    data() {
      return {   
      doctorZipChanged:false, 
      UserIdWrittedIsValid:true,
      isCodeValid:true,
      permission:[],
      Menu : this.$store.state.leftMenu,
      CodeExist:false,  
       UserFields:[{ key: 'userName', sortable: true, label: this.$t('doctor.UserName') },{ key: 'userCode',sortable: true, label: this.$t('doctor.UserCode')}],   
      OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },
      ProviderItems:[],
      isFirstNameValid: true,
      isLastNameValid:true,
      Userdescription:"",
      ObjectItems:{},
      DoctorId:null,
      SpecialtyList:[],
       PatientInfo:{},
       PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:''
     },   
         fields: [
            { key: 'Insurance', sortable: true, label: this.$tc('Insurance.Insurance'), class: 'text-rigth' },
            { key: 'ProviderId', sortable: true, label: this.$t('employee.ProviderId'), class: 'text-rigth' },
          ],   
        Mode:this.$attrs.editMode,  
        Loading:true,
        isReadOnly:false,       
        doctor:{
            code:"",            
        }, 
        pendingChanges: false,
        emailValid:false,
         reg: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/, 
      }
    },

    methods:{
      
    CanView(){
      return this.permission[0].roleCanView;
    },

       validate_entries() {
         this.emailValid = (this.doctor.email == "" || this.doctor.email == null)? true : (this.reg.test(this.doctor.email)) ? true : false;       
         if( this.emailValid) {
               return true;
            } else {
               return false;   
            }
      },

       ChangeSpecialty(value){
          this.doctor.type = value ? value.code : null;
       },

       isUserIdValid(){
          return true;
       },

       OnCodeBlur(value){
            //if (this.patientZipChanged && value.trim()!== '') {
               if(!this.Mode){
                  if (value.trim()!== '') {
          
            var parameter = {
               "code":value
            };
            DoctorService.getDoctorByCode(parameter)
                .then((response) => {
                    if(response.status == '200' && response.data){
                      // this.$emit('load', false);  
                       if(response.data.length > 0){
                          this.showMessage(this.$t('doctor.DoctorSetupTitle'),this.$t('doctor.DoctorCodeExist'),"error");
                          this.CodeExist = true;
                       }else{
                           this.CodeExist = false;
                       } 
                    }  
                     this.$emit('load', false);                          
                })
                .catch((error) => {
                    this.$emit('load', false); 
                  //  this.patient.city = '';
                   // this.patient.state = '';
                  if(error.response.status !== 404 && error.response.status !== 500){
                      if(error.response) {
                        this.showMessage(this.$t('doctor.DoctorSetupTitle'),error,"error");
                    }
                  }
                    
                });  
            }
               }
    
       },

       UpdateDoctor(){
           DoctorService.update(this.DoctorId, this.doctor)
        .then((response) => {             
                if(response.status == '200'){
                  this.pendingChanges = false;
                  this.$emit('load', false);
                  this.$router.push({name: "doctorSetup"});                          
                    setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    
                }
            })
        .catch((error) => {
            if (!error.response) {
               this.showMessage(this.$t('doctor.DoctorSetupTitle'),this.$t('Msg.NotAbleToInsert'),"error");
            }                      
            else if(error.response.status === 400 ){
               this.showMessage(this.$t('doctor.DoctorSetupTitle'),error.response.data.errorMessage,"error");
            } else if(error.response.status === 500){
                  this.showMessage(this.$t('doctor.DoctorSetupTitle'),this.$t('Msg.ThereWereSomeError'),"error");
               } else{
                  this.showMessage(this.$t('doctor.DoctorSetupTitle'),error.response.data.errorMessage,"error");
               }
            elemSaveBtn.removeAttribute("disabled"); 
            this.$emit('load', false);
            this.$route.router.go('/');
        });
       },

      AddDoctor(){
         if(!this.CodeExist){
         DoctorService.add(this.doctor)
         .then((response) => {            
               if(response.status == '200'){
                  this.pendingChanges = false; 
                  this.$emit('load', false);
                  this.$router.push({name: "doctorSetup"});    
                    setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                }
            })
         .catch((error) => {
               if (!error.response) {
                  this.showMessage(this.$t('doctor.DoctorSetupTitle'),this.$t('Msg.NotAbleToInsert'),"error");
               }                      
               else if(error.response.status === 400 ){
                  this.showMessage(this.$t('doctor.DoctorSetupTitle'),error.response.data.errorMessage,"error");
               } else if(error.response.status === 500){
                  this.showMessage(this.$t('doctor.DoctorSetupTitle'),this.$t('Msg.ThereWereSomeError'),"error");
               } else{
                     this.showMessage(this.$t('doctor.DoctorSetupTitle'),error.response.data.errorMessage,"error");
               } 
               elemSaveBtn.removeAttribute("disabled");
               this.$emit('load', false);
               this.$route.router.go('/');
        });
         }else{
            this.showMessage(this.$t('doctor.DoctorSetupTitle'),this.$t('doctor.DoctorCodeExist'),"error");
         }
      
      },


       getDoctor(){        
       if (this.DoctorId) {
         DoctorService.get(this.DoctorId)
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.doctor = response.data;  
                 if(this.doctor.userid && this.doctor.userid != null && this.doctor.userid != ""){
                     this.getLookupFieldsDesc(this.doctor.userid);  
                 } 
                 this.$emit('load', false);                       
               }
            })
            .catch((error) => {
               this.$emit('load', false);
               if (!error.response) {
                  // network error
                  this.showMessage(this.$t('doctor.DoctorSetupTitle'),this.$t('Msg.RetrieveInformation'),"error");
               } else {
                  this.showMessage(this.$t('doctor.DoctorSetupTitle'),error,"error");
               }
               });
         }
       },

         doctorZipChange: function() {
            //this.$emit('input', value);
                this.doctorZipChanged = true;
        },

       
        doctorZipBlur: function (value) {
            if (this.doctorZipChanged && value.trim()!== '') {
            this.$emit('load', true); 
            GlobalService.getCityAndStateByZipCode(value)
                .then((response) => {
                    if(response.status == '200' && response.data){
                        var obj = response.data
                       if(obj.city) {
                            //this.doctor.city = obj.city;
                             
                             this.$set(this.doctor, 'city', obj.city);
                             this.$set(this.doctor, 'state', obj.state);
                            //this.doctor.state = obj.state;
                        }
                        this.doctorZipChanged = false;
                    }  
                     this.$emit('load', false);                          
                })
                .catch((error) => {
                    this.$emit('load', false); 
                    this.doctor.city = '';
                    this.doctor.state = '';
                  if(error.response.status !== 404 && error.response.status !== 500){
                      if(error.response) {
                        this.showMessage(this.$t('doctor.DoctorSetupTitle'),error,"error");
                    }
                  }
                    
                });  
            }
        },
 
      SaveOrEdit(){
          /*if (this.Mode) { 
               this.UpdateDoctor()           
            }else{            
               this.AddDoctor()
            }*/
        if(!this.isReadOnly) {
           if(this.validate_entries()){
            if (this.validate_required()) {       
            this.pendingChanges = false;
            this.$emit('load', true);       
            if (this.Mode) { 
               this.UpdateDoctor();         
            }else{            
               this.AddDoctor();
            }
          } else {
           this.showMessage(this.$t('doctor.DoctorSetupTitle'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
            //this.$route.router.go('/');
          }
           }else{
              this.showMessage(this.$t('doctor.DoctorSetupTitle'),this.$t('Msg.InvalidFieldValuesDetected'),"errorAutoHide");
           }
         
        } 
      },

     async getDoctorSpecialties(){           
       await DoctorService.getDoctorSpecialties()
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                       this.SpecialtyList = response.data;                                
                     }               
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage("DoctorSpecialties",error,"error");
                    }
                });     
      },


    DiscardChanges(){
         this.$router.push({
            name: "doctorSetup"
         });
    },

    onCodeDescriptionOk(result){
        this.doctor.userid = result.code;
        this.Userdescription = result.description;
       // this.getLookupFieldsDesc(result.code);       
    },

    getLookupFieldsDesc(Code){
        DoctorService.getUserByCode(Code)
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                        this.Userdescription = response.data[0].userName;
                     }
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('doctor.DoctorSetupTitle'),error,"error");
                    }
                });  
      },
   

      validate_required() {
         this.isCodeValid = this.doctor.code && this.doctor.code.length > 0 ? true : false;
         this.isFirstNameValid = this.doctor.firstname && this.doctor.firstname.length > 0 ? true : false;
         this.isLastNameValid  = this.doctor.lastname && this.doctor.lastname.length > 0 ? true : false;
         var isUserIdvalid = this.UserIdWrittedIsValid ? true : false;
         return this.isCodeValid && this.isFirstNameValid && this.isLastNameValid && isUserIdvalid;
      },

      async Initialize(){
         await this.getDoctorSpecialties();
         if(this.Mode){
            this.$emit('load', true);  
            this.getDoctor(); 
         }
      },

      beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		},
      checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      }
    },

   async mounted(){  
      if(this.permission.length == 0){
         this.$router.push({name: "Main"});    
            setTimeout(() => 
              this.showPermissionMessage()
            , 50); 
         this.$emit('load', false); 
      }else{
        if(this.Mode){
          if(!this.permission[0].roleCanEdit){
         this.$router.push({name: "Main"});    
            setTimeout(() => 
              this.showPermissionMessage()
            , 50); 
         this.$emit('load', false); 
          }else{
           await this.Initialize();
          }
        }else{
          if(!this.permission[0].roleCanAdd){
         this.$router.push({name: "Main"});    
            setTimeout(() => 
              this.showPermissionMessage()
            , 50); 
         this.$emit('load', false); 
          }else{
            await this.Initialize();
          }
        }
      }
      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_doctor_setup');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );  
     
    },

    created () {
      this.DoctorId = this.$route.params.id;
      this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
      this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);      
      EventBus.$on("IsCorrectCode", function (valid) {       
         this.UserIdWrittedIsValid = valid;         
     }.bind(this));

       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed;
          this.checkUserAction();
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
  }
</script>


