<template>
  <div>
    <b-modal id="ReasonModal" size="md" :visible="true" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered title="Reason Codes" @ok="this.emitEventOK" @cancel="this.emitEventCancel"> 
      <div v-if="errorMessage" class="pt-3">
          <div id="alert" class="alert alert-fill-danger" role="alert">
            <i class="mdi mdi-alert-circle"></i>
            {{ errorMessage }}
          </div>
      </div>
      <b-row>
         <b-col lg="12" md="12" sm="12">
             <MdSelect
                        v-bind:Label="'Reason Codes'"
                        v-bind:Options="ReasonCodesList"
                        v-bind:ModelArray="Reason"
                        v-bind:FieldDescription="'name'"
                        v-bind:FieldCode="'code'"                      
                        :Input="ChangeReason"
                        v-bind:Name="'change_reason'"
												id="change_reason"/>
         </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import ReasonService from '@/services/reason.service'
//import StorageService from '@/services/storage.service'
  export default {
    mixins: [ShowMessage],
    data() {
      return { 
        password: '',
        errorMessage: null,
        ReasonCodesList:[],
        Reason:{
          active:false,
          code:"",
          name:"",
          reasonType:""
        }
      }
    },
    
    methods:{
      emitEventOK(){ 
        // evt.preventDefault();    
     this.$emit('getModalReasonOK', this.Reason);
        // Trigger checkPassword handler
      //  this.checkPassword(evt)
      },

      emitEventCancel(){          
        this.$emit('getModalReasonCancel', false);
      }, 

       /* emitEventOK(){          
        this.$emit('getModalReason', false);
      }, */

      ChangeReason(e){
        this.Reason = e;
      },

      getReason(){
          ReasonService.getAll()
               .then((response) => {
                 if(response.status == '200' && response){
                   this.ReasonCodesList = response.data;
                 //  this.$emit('getModalPassword', true);
                 }
               })
                 .catch((error) => {
                   if (!error.response) {
                      this.errorMessage ="Reason","We're sorry, No Data","error";
                  } else {
                      this.errorMessage = error.response.data.message;
                  }
                  
                 });
      }

      /*checkPassword() {
        const clientId = StorageService.getClientId();
        const userId = StorageService.getUserId();
        const password = this.password;
        if( clientId !== '' && userId !== '' && password !== '' ){
         UserService.verifyPassword( clientId, userId, password)
               .then((response) => {
                 if(response.status == '200'){
                   this.$emit('getModalPassword', true);
                 }
               })
                 .catch((error) => {
                   if (!error.response) {
                      this.errorMessage ="this.$t('Shared.VerifyPassword')","We're sorry, we're not able to verify password at the moment, please try back later","error";
                  } else {
                      this.errorMessage = error.response.data.message;
                  }
                  
                 });
        }
      }*/
    },
    created(){
      this.getReason();
    },
    mounted(){
      
    }
  }

</script>