<template>
  <div id="IncidentReport">
    <MdTitle v-bind:Text="$t('Incident.IDashboard')" />
      <div class="row">
          <div class="col-sm-12 col-md-3">                      
            <MdButton
              v-bind:Label="$t('Incident.AddInc')"                 
              v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'"
              v-on:click="AddIncident"
              v-bind:isReadOnly="isReadOnly"
              v-bind:Name="'btn_add_vitals'">
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control"
                  v-model="params.SearchCriteria"   
                  @keyup.enter="SearchIncident">

                <div class="input-group-append">
                  <MdButton 
                    v-bind:Name="'btnsearchinput'"
                    v-bind:VariantProp="'btn btn-sm search-attached'"
                    v-bind:IconClass="'mdi mdi-magnify'"
                    v-on:click="SearchIncident">
                  </MdButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="flex-container">
      <b-row>
        <b-col md="3" lg="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.datefrom')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'DateFrom'"
            v-model="DateFrom"
            v-bind:Min="'1900-01-01'"
            v-bind:OnPressEnter="loadListing"
            v-bind:Max="'2200-12-31'"
            v-bind:isValid="this.isDateFromValid"
            v-bind:isRequired="true"
            v-on:change="DateFromChange($event)"           
           >                                     
          </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.dateTo')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'DateTo'"
            v-bind:OnPressEnter="loadListing"
            v-model="DateTo"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-on:change="DateToChange($event)"          
            v-bind:isValid="this.isDateToValid">                                     
           </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('Shared.Category')"
            v-bind:Options="IncidentOptions"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"
            v-bind:ModelArray="IncidentModel"  
            v-bind:Multiple="false" 
            :Input="ChangeIncident"
            v-bind:AllowEmpty="false"/>
        </b-col>
        <b-col lg="3" md="3" sm="12" v-if="SeeIncidentAdmin">         
          <MdSelect
						v-bind:Label="$t('Incident.IncReporter')"
						v-bind:FieldDescription="'name'"
						v-bind:FieldCode="'code'"
						v-bind:Options="ReporterOptions"
						v-bind:ModelArray="ReporterModel"
						v-bind:Multiple="false"
            v-bind:AllowEmpty="false"
						:Input="ChangeReporter"
						v-bind:Name="'incident_report_select'"
            id="incident_report_select"/>
        </b-col>
      
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('Incident.IncSeverity')"
            v-bind:Options="SeverityOptions"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"
            v-bind:AllowEmpty="false"
            v-bind:Multiple="false"
            v-bind:ModelArray="SeverityModel"  
            :Input="ChangeSeverity"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">         
          <MdSelect
            v-bind:Label="$t('Shared.Room')"
            v-bind:Options="RoomsOptions"
            v-bind:ModelArray="roomModel"
            v-bind:FieldDescription="'roomAndBuildingName'"
            v-bind:FieldCode="'code'"
            v-bind:AllowEmpty="false"
            v-bind:Multiple="false"             
            :Input="ChangeRoom"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('Shared.Status')"
            v-bind:Options="IncidentStatusOptions"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'value'"
            v-bind:AllowEmpty="false"
            v-bind:Multiple="false"
            v-bind:ModelArray="StatusModel"  
            :Input="ChangeStatus"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdButton
          v-bind:Label="$t('Shared.Search')"
          v-bind:Name="'btnsearchincident'"
          v-bind:ClassProp="'secondary mt-3 mx-1 btn-login'"         
          v-on:click="loadListing()"/>
        </b-col>
      </b-row>
      </div>

      <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
       <div class="row">
          <div class="col-md-12">
            <MdTable 
              v-bind:fields="fields"
              v-bind:IsIncident="true"
              v-bind:items="items"
              v-bind:totalRows="totalRows"
              v-bind:isReadOnly="isReadOnly"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"
              v-bind:pageOptions="pageOptions"
              :ondblClick="OnDblCLick"
              v-bind:FieldsDate="FieldsDateArr"
              :viewIconEvent="ViewIncident"
              :onRowSelected="onRowSelected"
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :perPageFunction="PerPageFunction"
              :otherIcon="'printer-fill'"
              :otherIconEvent="showReport">
            </MdTable>
          </div>
        </div>
        
        <div class="space-actions"></div>
        <div class="page-actions">
          <MdButton
            v-bind:Label="$t('Shared.Report')"
            v-bind:Name="'btnreport'"
            v-bind:ClassProp="'secondary mx-2 btn-login'"
            v-on:click="showICRMultiple()"/>
          <MdButton
            v-bind:Label="$t('Incident.IncVoidTitle').toUpperCase()"
            v-bind:Name="'btnvoidincident'"
            v-bind:ClassProp="'secondary mx-2 btn-login'"
            v-bind:isReadOnly="btnVoidIncidentRO"
            v-on:click="voidIncident()"/>
          <MdButton 
            v-if="VisibleCompleteDraf"
            v-bind:Label="$t('Incident.IncCompleteDraft')"
            v-bind:isReadOnly="btncompleteDraft"  
            v-bind:Name="'btncompletedraft'"
            v-bind:ClassProp="'secondary mx-2 btn-login'"
            v-on:click="completeDraft()"
            ref="btncompletedraft"/>
            <MdButton
            v-if="this.SeeIncidentAdmin"
            v-bind:Label="$t('Incident.IncSetBacktoDraft')"
            v-bind:ClassProp="'secondary mx-2 btn-login'"
            v-bind:Name="'btnsetbacktodraft'"          
            v-bind:isReadOnly="btnSetBackToDraft" 
            v-on:click="SetBackTD()"
            ref="btnsetbacktodraft"/>
        </div>

        <MdPasswordDlg v-if="showSignPassword" 
        v-bind:Type="TypePassWord"
        v-bind:UserId="UserIdSelected"
        v-bind:Operation="Operation"
        v-bind:VerificationType="'SupervisorPassword'"
        @getModalPassword="onModalPasswordResult($event)"
      />

      <ICRMultipleRep v-if="isReportVisible" 
        :closeWindow="closeParamRepWindow"
        :IncidentID="IncidentID"
        :reportTitle="$t('Incident.IncReportTitle')"
      />
      <ICRMultipleRep v-if="isICRMultipleRepVisible" 
        :closeWindow="closeICRMultipleRepWindow"
        :IncidentID="''"
        v-bind:VisibleReport="SeeIncidentAdmin"
        :reportTitle="$t('Incident.IncReportTitle')"
      /> 
  </div>
</template>

<script>
import Utils from '@/common/utils'
//import { EventBus } from '@/event-bus.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import IncidentSetup from '@/services/incidentsetup.service'
import EnumService from "@/services/enum.service";
import RoomService from '@/services/rooms.service'
import IncidentReport from '@/services/incidentreport.service'
import EmployeeService from '@/services/employee.service'
import IncidentReportSeveritySetupService from '@/services/incidentreportseveritysetup.service'

export default {
  name: 'IncidentReport',
  mixins: [ShowMessage,Utils],
  components: { },
  props: {
    employee: Boolean,
    keepFilter: Boolean
  },
  data(){
    return{   
        SeeIncidentAdmin:false,
        Operation:"",    
        showSignPassword:false,
        showSignPasswordSBTD:false,
        VisibleCompleteDraf:false,
        TypePassWord:'Sign',
        UserIdSelected:'',
        isReportVisible: false,       
       // classPropBtnSetBTD :"secondary mx-2 btn-login",
        IncidentModel: {
          code:"All",
          description:this.$t('Shared.All')
        },
        roomModel: {
          code:"All",
          roomAndBuildingName:this.$t('Shared.All')
        },
        StatusModel: {
          "value":"all",
          "name":this.$t('Shared.All')
        },
        DateFrom: Utils.formatterDate(Date()),
        DateTo: Utils.formatterDate(Date()),
        ReporterModel: {
          code:"All",name:this.$t('Shared.All')
        },
        SeverityModel: {
          code:"All",description:this.$t('Shared.All')
        },
        ReporterOptions:[],
        SeverityOptions:[],
        RoomsOptions:[],
        IncidentOptions: [],
        IncidentStatusOptions:EnumService.IncidentStatusOptions,
        parameter:{
          SearchCriteria:'',
          PageNumber : null,
          PageSize:null,
          Status: true,
        },
        isDateFromValid: true,
        isDateToValid: true,
        reportParams: [],
        IncludeFiltersInReport: false,
        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,       
          favButtonAtion:false          
        },
        params: {
          "Reporter": [],
          "Severity": [],
          "Building": [],
          "Status": [],
          "DateFrom": Utils.formatterDate(Date()),
          "DateTo": Utils.formatterDate(Date()),
          "Incident": [],
          "SearchCriteria": '',
          "PageNumber": 0,
          "PageSize": 0
        },
        fields: [
          //{ key: 'status', sortable: true, label: this.$t('Shared.Status'), class: 'text-rigth' }, 
          { key: 'version', sortable: true, label: this.$t('Incident.IncVers'), class: 'hidden'},    
          { key: 'incidentDate', sortable: true, label: this.$t('Incident.IncDate'), class: 'text-rigth' }, 
          { key: 'incidentTime', sortable: true, label: this.$t('Incident.IncTime'), class: 'hidden' },
          { key: 'severityName', sortable: true, label: this.$t('Incident.IncSeverity'), class: 'text-rigth'},       
          { key: 'primaryCategoryName', sortable: true, label: this.$t('Shared.Category'), class: 'text-rigth' }, 
          { key: 'principalParticipant', sortable: true, label: this.$tc('Incident.IncParticipant'), class: 'text-rigth'}, 
          { key: 'reporterName', sortable: true, label: this.$t('Incident.IncReporter'), class: 'text-rigth' },      
          { key: 'reportedDate', sortable: true, label: this.$t('Incident.IncReportedDate'), class: 'text-rigth' }, 
          { key: 'roomName', sortable: true, label: this.$t('Shared.Room'), class: 'text-rigth' },
          { key: 'locationName', sortable: true, label: this.$t('Incident.IncLocation'), class: 'text-rigth' },
          { key: 'additionalLocation', sortable: true, label: this.$t('Incident.IncAditLocation'), class: 'text-rigth' },       
          { key: 'lastEditedUserName', sortable: true, label: this.$t('Incident.IncLastEditBy'), class: 'text-rigth' },
          { key: 'lastEditDateTime', sortable: true, label: this.$t('Incident.IncLastEditDate'), class: 'text-rigth' },               
          { key: 'incidentID', sortable: true, label: this.$t('Incident.IncId'), class: 'text-rigth' }, 
        ],
        FieldsDateArr:['incidentDate','reportedDate','lastEditDateTime'],
        items: [],
        totalRows: 0,
        isReadOnly: false,
        ShowMessageIsEmpty:false,
        currentPage: 1,
        perPage: 50,
        pageOptions: [5, 10, 15,20,30,40,50],
        selected: [],
        IncidentID: '',
        btnVoidIncidentRO: true,
        btncompleteDraft:true,
        btnSetBackToDraft: true,
        btnCompleteDraftLabel: this.$t('Incident.IncCompleteDraft'),
        isICRMultipleRepVisible: false
    }
  },
  /*watch: {
    employee(newValue) {
      this.params.Employee = newValue
      this.currentPage = 1;
      this.loadListing()
    }
  },*/

  methods:{

    onModalPasswordResult(result){      
      if(result.Event === 'Ok'){   
        if(result.status === true) {
         this.$emit('load', true); 
         if(result.Operation === "Void"){
          IncidentReport.SetVoid(this.selected[0].incidentID)
              .then(() => {
                  this.currentPage = 1;
                  this.loadListing()
              })
              .catch((error) => {
                if (!error.response) {
                  this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                } else {
                  this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                }
              });        
         } 
         else if(result.Operation === "SetBackToDraft"){
          IncidentReport.setBackToDraft(this.selected[0].incidentID)
                .then(() => {
                  this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncSetBackToDraftMsg') );
                  this.loadListing()
                })
                .catch((error) => {
                  if (!error.response) {
                    this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                  } else {
                    this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                  }
                });
         }      
        
        }
        else{
          this.showMessage(this.$t('Incident.IncVoidTitle'),this.$t('Msg.InvalidSupervisorPass'),"error");
        }
      }    
    this.showSignPassword = false;
    },

    voidIncident() {
      if (this.selected.length > 0 && !this.selected[0].isVoid) {
        this.$bvModal.msgBoxConfirm(this.$t('Incident.IncConfirmVoidMsg'), {
          title: this.$t('Incident.IncReportTitle'),
          okTitle: this.$t('Shared.TitleOK'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning',
          id:'modal_delete_vitals'
        })
        .then(value => {
          if(value) {
            this.showSignPassword = true;  
            this.Operation = "Void";   
          }
        })
      }
    },

    SetBackTD(){
      if (this.selected.length > 0 && !this.selected[0].isVoid) {
        this.$bvModal.msgBoxConfirm(this.$t('Incident.IncConfirmBackDrafMsg'), {
          title: this.$t('Incident.IncReportTitle'),
          okTitle: this.$t('Shared.TitleOK'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning',
          id:'modal_SBTD_incident'
        })
        .then(value => {
          if(value) {
            this.showSignPassword = true;  
            this.Operation = "SetBackToDraft";   
          }
        })
      }
    },
    
    completeDraft() {
      if (this.selected.length > 0) {
        if (!this.selected[0].isDraftComplete && this.$store.state.isUseCompleteDraft) {          
          IncidentReport.completeDraft(this.selected[0].incidentID, this.selected[0].reporterCode)
            .then(() => {
              this.loadListing()
              this.showMessage(this.$t('Incident.IncReportTitle'), this.$t('Incident.IncCompleteDrafMsg'));
            })
            .catch((error) => {
              if (!error.response) {
                this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
              } else {
                this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
              }
            });
        } /*else if (this.selected[0].isDraftComplete  && this.employee == false) {

        }*/
      } 
    },

    AddIncident() {
      this.$router.push({ name: "incident-report-general-info-add"});
    },

    ViewIncident(item) {
      this.$router.push({ name: "incident-content", params: { IncidentID: item.incidentID.trim()}});
    },

    showReport(item) {
      this.IncidentID = item.incidentID
      this.isReportVisible = true
    },

    closeParamRepWindow() {
      this.isReportVisible = false
      this.IncidentID = null
    },

    SearchIncident() {
      this.currentPage = 1;
      this.loadListing()
    },

    OnDblCLick(){

    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    onRowSelected(items) {
      this.selected = items;    
      if(items.length > 0){
        this.btnVoidIncidentRO = items.length > 0 && items[0].isVoid ? true : false;
        this.btnSetBackToDraft = items.length > 0 && (!items[0].isDraftComplete || items[0].isVoid )? true : false;
        this.btncompleteDraft =  items.length > 0 && items[0].isDraftComplete ? true : false;
      }else{
        this.btnVoidIncidentRO = true;
        this.btnSetBackToDraft = true;
        this.btncompleteDraft = true;
      }      
    },

    pageChanged (page) {            
      this.currentPage = page;    
      this.loadListing()    
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.loadListing() 
    },

    getIncidentReport() {
      this.params.PageNumber = this.currentPage;
      this.params.PageSize = this.perPage;     
      IncidentReport.getAll(this.params)
        .then((response) => { 
        if(response.data){
          this.items = response.data;
          this.totalRows = response.data.length > 0 ? response.data[0].maxRows : 0;
          this.ShowMessageIsEmpty = response.data.length > 0 ? false : true
          this.items.forEach(function(element) {
            if (element.reportedDate)	
              element.reportedDate = Utils.formatterDateTimeToString(element.reportedDate);
            if (element.lastEditDateTime)	
              element.lastEditDateTime = Utils.formatterDateTimeToString(element.lastEditDateTime);        
            if (element.incidentDate)
              element.incidentDate =  `${Utils.formatterDateToString(element.incidentDate)} ${Utils.formatTime2(element.incidentTime)}`;        
          });
          this.$emit("load", false);
        }             
        })
        .catch((error) => {
          this.$emit("load", false);
          if (!error.response) {
            // network error
            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
        }
        });
    },
    
    DateFromChange(value) {
      this.params.DateFrom = value
      this.$store.commit('setIncidentDBDateFrom',value);
    },

    DateToChange(value) {
      this.params.DateTo = value
      this.$store.commit('setIncidentDBDateTo',value);
    },

    ChangeReporter(item) {   
      if(item != null){
        this.params.Reporter = item.code == 'All' ? "" : item.code;
        this.ReporterModel = item; 
        this.currentPage = 1;
      }else{
        this.params.Reporter = "";
        this.ReporterModel = {code:"All",name:this.$t('Shared.All')}; 
      }  
      
      this.$store.commit('setIncidentDBReporter',this.ReporterModel);

      this.loadListing();
    },

    ChangeSeverity(item) {
      if(item != null){
        this.params.Severity = item.code == 'All' ? "" : item.code
        this.SeverityModel = item;
        this.currentPage = 1;
      }else{
        this.params.Severity = "";
        this.SeverityModel = {code:"All",description:this.$t('Shared.All')};
      }
      
      this.$store.commit('setIncidentDBSeverity',this.SeverityModel);

      this.loadListing()
    },

    ChangeRoom(item) {
      if(item != null){
        this.params.Building =  item.code == 'All' ? "" : item.code
        this.roomModel = item;
        this.currentPage = 1;
      }else{
        this.roomModel = {code:"All",roomAndBuildingName:this.$t('Shared.All')};
        this.params.Building = "";
      }
      
      this.$store.commit('setIncidentDBRoom',this.roomModel);

      this.loadListing()
    },

    ChangeStatus(item) {
      if(item != null){
        this.params.Status = item.value == 'all' ? "" : item.value
        this.StatusModel = item;
        this.currentPage = 1;
      }else{
        this.StatusModel = {value:"all",name:this.$t('Shared.All')};
        this.params.Status = "";
      }
     
      this.$store.commit('setIncidentDBStatus',this.StatusModel);

      this.loadListing()
    },

    ChangeIncident(item) {
      if(item != null){
        this.params.Incident = item.code == 'All' ? "" : item.code
        this.IncidentModel = item;
        this.currentPage = 1;
      }else{
        this.IncidentModel = {code:"All",description:this.$t('Shared.All')};
        this.params.Incident = "";
      }
      
      this.$store.commit('setIncidentDBCategory',this.IncidentModel);
     
      this.loadListing()
    },

     getIncidentSetup() {
       IncidentSetup.getAll(this.parameter)
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.IncidentOptions = response.data;
              this.IncidentOptions.unshift({code: "All", description: this.$t('Shared.All')})
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
            } 
          });  
    },

     getIncidentReportReporter() {     
           EmployeeService.getEmployeesAll()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.ReporterOptions = response.data;
              this.ReporterOptions.unshift({code: "All",name:this.$t('Shared.All')})
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
            } 
          });
    },



     getIncidentReportSeverity() {
       IncidentReportSeveritySetupService.getSeverityAll()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.SeverityOptions = response.data;
              this.SeverityOptions.unshift({code: "All",description:this.$t('Shared.All')})
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
            } 
          });  
    },

     getIncidentReportRooms() {
       RoomService.getAllRooms()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.RoomsOptions = response.data;
              this.RoomsOptions.unshift({code: 'All', roomAndBuildingName: this.$t('Shared.All')});              
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
            } 
          });  
    },

   async CanSeeIncidentAdmin() {
     await IncidentReport.CanSeeIncidentAdmin()
          .then((response) => {            
                this.SeeIncidentAdmin = response.data; 
          })
          .catch(error => {
            if (error.response) {
              this.showMessage( this.$t('Incident.IncCanSeeAdminMsg'),error,"error");
            } 
          });  
    },

    loadListing() {      
      if(this.DateFrom === null || this.DateFrom === '' || Utils.IsValidDateAndRange(this.DateFrom)){
        this.showMessage(this.$t('Incident.Incident'), this.$t('Msg.CheckRequiredFields'),"error");  
        this.isDateFromValid = false;
          return;
      }
      else if(this.DateTo === null || this.DateTo === '' || Utils.IsValidDateAndRange(this.DateTo)){
        this.showMessage(this.$t('Incident.Incident'), this.$t('Msg.CheckRequiredFields') ,"error"); 
        this.isDateToValid = false;     
          return;
      }else{
        this.$emit('load', true);
        this.isDateFromValid = true;
        this.isDateToValid = true; 
        this.getIncidentReport();
      }
      
    },

    showICRMultiple() {
      this.isICRMultipleRepVisible = true
    },

    closeICRMultipleRepWindow() {
      this.isICRMultipleRepVisible = false
    },

    loadFilters() {
      this.params.DateFrom = this.$store.state.incidentDBDateFrom ? this.$store.state.incidentDBDateFrom : this.DateFrom      
      this.DateFrom = this.$store.state.incidentDBDateFrom ? this.$store.state.incidentDBDateFrom : this.DateFrom

      this.params.DateTo = this.$store.state.incidentDBDateTo ? this.$store.state.incidentDBDateTo : this.DateTo
      this.DateTo = this.$store.state.incidentDBDateTo ? this.$store.state.incidentDBDateTo : this.DateTo

      this.params.Incident = this.$store.state.incidentDBCategory.code == 'All' ? "" : this.$store.state.incidentDBCategory.code
      this.IncidentModel = this.$store.state.incidentDBCategory.code ? this.$store.state.incidentDBCategory : {code:"All",description:this.$t('Shared.All')};      

      this.params.Reporter = this.$store.state.incidentDBReporter.code == 'All' ? "" : this.$store.state.incidentDBReporter.code
      this.ReporterModel = this.$store.state.incidentDBReporter.code ? this.$store.state.incidentDBReporter : {code:"All",name:this.$t('Shared.All')};      

      this.params.Severity = this.$store.state.incidentDBSeverity.code == 'All' ? "" : this.$store.state.incidentDBSeverity.code
      this.SeverityModel = this.$store.state.incidentDBSeverity.code ? this.$store.state.incidentDBSeverity : {code:"All",description:this.$t('Shared.All')};      

      this.params.Building = this.$store.state.incidentDBRoom.code == 'All' ? "" : this.$store.state.incidentDBRoom.code
      this.roomModel = this.$store.state.incidentDBRoom.code ? this.$store.state.incidentDBRoom : {code:"All",roomAndBuildingName:this.$t('Shared.All')}      

      this.params.Status = this.$store.state.incidentDBStatus.value == 'all' ? "" : this.$store.state.incidentDBStatus.value
      this.StatusModel = this.$store.state.incidentDBStatus.value ? this.$store.state.incidentDBStatus : {value:"all",name:this.$t('Shared.All')};      
    }
  },

  created() {
   
  },

   async mounted() {    
      this.VisibleCompleteDraf = this.$store.state.isUseCompleteDraft; 
      this.OptionsRightMenu.ItemsList = []
    let layoutStatus = this.$store.state.layoutStatus;
    switch(layoutStatus) {
      case 'L0R1':
        this.OptionsRightMenu.favButtonAtion = true;
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
      break;
      case 'L1R0':
      case 'L1R1':
          this.OptionsRightMenu.btnvisible = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
      break;
      default:
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
    await this.CanSeeIncidentAdmin();
    await this.getIncidentReportRooms();
    await this.getIncidentReportSeverity();
    await this.getIncidentReportReporter();
    await this.getIncidentSetup();
    
    if (this.keepFilter)
      this.loadFilters()
    else {
      this.$store.commit('setIncidentDBDateFrom',Utils.formatterDate(Date()));
      this.$store.commit('setIncidentDBDateTo',Utils.formatterDate(Date()));
      this.$store.commit('setIncidentDBReporter',{code:"All",name:this.$t('Shared.All')});
      this.$store.commit('setIncidentDBSeverity',{code:"All",description:this.$t('Shared.All')});
      this.$store.commit('setIncidentDBRoom',{code:"All",roomAndBuildingName:this.$t('Shared.All')});
      this.$store.commit('setIncidentDBStatus',{value:"all",name:this.$t('Shared.All')});
      this.$store.commit('setIncidentDBCategory',{code:"All",description:this.$t('Shared.All')});
    }
    
    this.loadListing();
  },

  updated(){
   // this.classPropBtnSetBTD = this.SeeIncidentAdmin ? "secondary mx-2 btn-login hidden" : "secondary mx-2 btn-login";    
  },
  
  destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);     
    }
};
</script>
<style>
  #fileList{
    padding: 1em;
  }
</style>