<template>
<div class="schedule-vue-sample">
 <div class="row">
  <div class="col-lg-10 control-section">
   <div class="content-wrapper">
    <ejs-schedule
      id='Schedule' 
      ref="ScheduleObj"
      :height='height' 
      :width='width'  
      :cssClass='cssClass'
      :eventSettings='eventSettings'
      :group='group'
      :currentView='currentView' 
      :showQuickInfo="showQuickInfo"
      :popupOpen='onPopupOpen'
      :popupClose='onPopupClose'
      :editorTemplate='editorTemplate' 
      :views='views'
      :actionBegin="onActionBegin"
      :actionComplete="onActionComplete"
      :actionFailure="onActionFailure"
      :calendarCollections='employeeDataSource'
      :selectedDate='selectedDate'
      :dataBound="onDataBound"
      :eventRendered="OnEventRendered"
      :dragStart="onDragStart"
      :resizeStart="onResizeStart"
      :created="onCreated"
      :timeScale="timeScale"
      :timezone="timezone"
      >
      <e-views>
        <e-view option="Day" :eventTemplate="dayTemplate"></e-view>
        <e-view option="Week" :eventTemplate="weekTemplate"></e-view>
        <e-view option="WorkWeek" :eventTemplate="workWeekTemplate"></e-view>
        <e-view option="Month" :eventTemplate="monthTemplate"></e-view>
      </e-views>
      <e-resources>
        <e-resource 
          field='employeeCode' 
          :title="$tc('Shared.Employee',2)"
          name='Employee' 
          :dataSource='employeeDataSource'
          textField='employeeName' 
          idField='employeeCode' 
          groupIDField='GroupId' 
          colorField='Color'
          allowMultiple="true">
        </e-resource>
      </e-resources>
    </ejs-schedule>
   </div>
  </div>
    <div class="col-lg-2 property-section HideFilter">
        <div class="textlabel" :data-label="this.$tc('Shared.Employee',2)">
         <ejs-multiselect 
           id='multiselect' 
           :dataSource='employeeDataSource' 
           :fields='fieldsEmployee' 
           :allowCustomValue='allowCustomValue'
           placeholder="Select a Employee"
           :change='onChangeResource'>
         </ejs-multiselect>
      </div>
      <div class="textlabel" data-label="Employee Types">
         <ejs-multiselect 
           id='multiselect' 
           :dataSource='employeeTypesDataSource' 
           :fields='fieldsEmployeeTypes' 
           placeholder="Employee Types"
           :change='onChangeEmployeeTypes'>
         </ejs-multiselect>
      </div>
      <MdLookup
        v-bind:Label="$tc('Shared.Patient',2)"
        v-bind:ModelCode="patientCode"
        v-bind:ModelDescription="patientName"
        v-bind:RefCode="'patientcode'"
        v-bind:Api="'Patients'"
        v-bind:FieldCode="'patientcode'"
        v-bind:FieldDescription="'firstname'"
        :SearchTitle="$tc('Shared.Patient',2)"
        :GetValues="getValues"
        :InvalidFeedback="'Invalid Patient Code'"
        :SearchFieldCode="'PatientCode'"
        :AuxFields="AuxFields"
        Placeholder="Select a Patient"
        :FieldsDate="fieldsDate"
      />
        <MdSelect
            v-bind:Label="$t('Shared.Status')"
            v-bind:Options="StatusDataSource"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'value'"           
            v-bind:Placeholder="'Select Status'"
            v-bind:Model="StatusModel"               
            v-bind:Multiple="false"                   
            :Input="onChangeStatus"            
          />


      <ejs-dialog
        ref="alertDlg"
        id="alertDialog"
        :header="confirmHeader"
        width="400px"
        isModal="true"
        showCloseIcon="true"
        target="#Schedule"
        v-bind:visible="false"
        :buttons="dlgButtons"
      >
      <table style='width:371px' id ='poschange'>
        <tr>
          <td><ejs-radiobutton id='radio1' checked=true label='This event' value='ThisEvent' name='eventSel' :change='changeRecurrenceType'></ejs-radiobutton></td>
        </tr>
        <tr>
          <td><ejs-radiobutton id='radio2' label='This and following events' value='ThisAndFollowing' name='eventSel' :change='changeRecurrenceType'></ejs-radiobutton></td>
        </tr>
        <tr>
          <td><ejs-radiobutton id='radio3' label='All events' value='AllEvents' name='eventSel' :change='changeRecurrenceType'></ejs-radiobutton></td>
        </tr>
      </table>
      </ejs-dialog>
      <SchedulerDailyRep v-if="isReportVisible" 
        :closeWindow="closeParamRepWindow"
        :startDate="startDate"
      />
      
    </div>
 </div>
  <div class="panel-scheduler" style='position: relative: !important;'>
    {{tzDisplayName}}
  </div>
</div>
</template>
<script>
import Vue from 'vue';
import { SchedulePlugin, Day, Week, WorkWeek, Month, Resize, DragAndDrop } from "@syncfusion/ej2-vue-schedule";
Vue.use(SchedulePlugin);
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DateTimePicker } from '@syncfusion/ej2-calendars';
import { L10n, Internationalization } from '@syncfusion/ej2-base';
import { TextBox } from '@syncfusion/ej2-inputs';
import { CheckBox, Button } from '@syncfusion/ej2-buttons';
import editortemplate from "./schedule-editor.vue";
import { Query, UrlAdaptor, DataManager, Predicate } from '@syncfusion/ej2-data';
import { MultiSelectComponent, MultiSelectPlugin } from '@syncfusion/ej2-vue-dropdowns';
import { ButtonPlugin, RadioButtonPlugin } from "@syncfusion/ej2-vue-buttons";
import { DialogPlugin } from "@syncfusion/ej2-vue-popups";
Vue.component(MultiSelectPlugin.name, MultiSelectComponent);
Vue.use(ButtonPlugin);
Vue.use(DialogPlugin);
Vue.use(RadioButtonPlugin);

var resourceHeaderVue = Vue.component("resource-headerTemplate", {
        template: '<div className="template-wrap"><div class="employee-category"><div class="employee-name">' +
                  '{{getEmployeeName(data)}}</div><div class="employee-designation">{{getEmployeeDesignation(data)}}</div></div></div>',
        data() {
            return {
                data: {}
            };
        },
        methods: {
            getEmployeeName: function (data) {
                let value = JSON.parse(JSON.stringify(data));
                return (value.resourceData) ? value.resourceData[value.resource.textField] : value.resourceName;
            },
            getEmployeeDesignation: function (data) {
                let value = JSON.parse(JSON.stringify(data));
                return value.resourceData.Designation;
            }
        }
    });

    L10n.load({
    'en-US': {
        'schedule': {
            'saveButton': 'SAVE',
            'cancelButton': 'CLOSE',
            'deleteButton': 'REMOVE',
            // 'newEvent': `Appointment Editor`,
            // 'editEvent': `Appointment Editor`,
        },
      }
    });
    var instance = new Internationalization();
    var dayTempVue = Vue.component("dayTemp", {
        template: '<div class="template-wrapper" v-if="!data.isBlock">'+
        '<div class="subject" :id="data.id" style="color:white; cursor:pointer">{{data.subject}} {{data.notes}} </div>'+       
        '<b-tooltip  :target="data.id" triggers="hover">'+
          '<h6 class="eventHintHeader">APPOINTMENT DETAILS</h6>'+
          '<div class="eventHint">'+
          '<b-icon icon="calendar3"></b-icon> {{getStartTime(data)}}<br>'+
          '<b-icon icon="calendar3"></b-icon> {{getEndTime(data)}}<br>'+
          '<b-icon icon="chat-right-dots"></b-icon> {{getAppointment(data)}}<br>'+
          '<b-icon icon="calendar-check"></b-icon> {{getConfirmed(data)}}<br>'+
          '<b-icon icon="person"></b-icon> {{getPatientName(data)}}<br>'+  
          '</div>'+
          '<i>{{getEmployeeName(data)}}</i>'+
          
        '</b-tooltip></div>',
        methods: {
           getStartTime: function (data) {
              return `${instance.formatDate(data.startTime, { skeleton: 'yMd' })} ${instance.formatDate(data.startTime, { skeleton: 'hm' })}`;
           },
           getEndTime: function (data) {
              return `${instance.formatDate(data.endTime, { skeleton: 'yMd' })} ${instance.formatDate(data.endTime, { skeleton: 'hm' })}`;
           },
           getAppointment: function (data) {
             return data.appointmentTypeName
           },
           getConfirmed: function (data) {
             if (data.patientConfirmed) {
               if (data.patientShowedUp) {
                 return 'Showed UP'
               }
               else if (data.reason && data.reason.length > 0) {
                 return 'Canceled'
               }
               else {
                 return 'Confirmed'
               }
             } else {
               if (data.patientShowedUp) {
                 return 'Showed UP'
               }
               else if (data.reason && data.reason.length > 0) {
                 return 'Canceled'
               }
               else {
                 return 'New'
               }
             }
           },
           getPatientName: function (data) {
             return data.patientName
           },
           getEmployeeName: function (data) {
             return data.employeeName
           }

        }
    });
    var weekTempVue = Vue.component("weekyTemp", {
        template: '<div class="template-wrapper" v-if="!data.isBlock">'+
        '<div class="subject" :id="data.id" style="color:white">{{data.subject}} {{data.notes}} </div>'+
        '<b-tooltip  :target="data.id" triggers="hover">'+
          '<h6 class="eventHintHeader">APPOINTMENT DETAILS</h6>'+
          '<div class="eventHint">'+
          '<b-icon icon="calendar3"></b-icon> {{getStartTime(data)}}<br>'+
          '<b-icon icon="calendar3"></b-icon> {{getEndTime(data)}}<br>'+
          '<b-icon icon="chat-right-dots"></b-icon> {{getAppointment(data)}}<br>'+
          '<b-icon icon="calendar-check"></b-icon> {{getConfirmed(data)}}<br>'+
          '<b-icon icon="person"></b-icon> {{getPatientName(data)}}<br>'+
          '</div>'+
          '<i>{{getEmployeeName(data)}}</i>'+
          
        '</b-tooltip></div>',
        methods: {
           getStartTime: function (data) {
              return `${instance.formatDate(data.startTime, { skeleton: 'yMd' })} ${instance.formatDate(data.startTime, { skeleton: 'hm' })}`;
           },
           getEndTime: function (data) {
              return `${instance.formatDate(data.endTime, { skeleton: 'yMd' })} ${instance.formatDate(data.endTime, { skeleton: 'hm' })}`;
           },
           getAppointment: function (data) {
             return data.appointmentTypeName
           },
           getConfirmed: function (data) {
             if (data.patientConfirmed) {
               if (data.patientShowedUp) {
                 return 'Showed UP'
               }
               else if (data.reason && data.reason.length > 0) {
                 return 'Canceled'
               }
               else {
                 return 'Confirmed'
               }
             } else {
               if (data.patientShowedUp) {
                 return 'Showed UP'
               }
               else if (data.reason && data.reason.length > 0) {
                 return 'Canceled'
               }
               else {
                 return 'New'
               }
             }
           },
           getPatientName: function (data) {
             return data.patientName
           },
           getEmployeeName: function (data) {
             return data.employeeName
           }

        }
    });
    var workWeekTempVue = Vue.component("workWeekyTemp", {
        template: '<div class="template-wrapper" v-if="!data.isBlock">'+
        '<div class="subject" :id="data.id" style="color:white">{{data.subject}} {{data.notes}} </div>'+
        '<b-tooltip  :target="data.id" triggers="hover">'+
          '<h6 class="eventHintHeader">APPOINTMENT DETAILS</h6>'+
          '<div class="eventHint">'+
          '<b-icon icon="calendar3"></b-icon> {{getStartTime(data)}}<br>'+
          '<b-icon icon="calendar3"></b-icon> {{getEndTime(data)}}<br>'+
          '<b-icon icon="chat-right-dots"></b-icon> {{getAppointment(data)}}<br>'+
          '<b-icon icon="calendar-check"></b-icon> {{getConfirmed(data)}}<br>'+
          '<b-icon icon="person"></b-icon> {{getPatientName(data)}}<br>'+  
          '</div>'+
          '<i>{{getEmployeeName(data)}}</i>'+
          
        '</b-tooltip></div>',
        methods: {
           getStartTime: function (data) {
              return `${instance.formatDate(data.startTime, { skeleton: 'yMd' })} ${instance.formatDate(data.startTime, { skeleton: 'hm' })}`;
           },
           getEndTime: function (data) {
              return `${instance.formatDate(data.endTime, { skeleton: 'yMd' })} ${instance.formatDate(data.endTime, { skeleton: 'hm' })}`;
           },
           getAppointment: function (data) {
             return data.appointmentTypeName
           },
           getConfirmed: function (data) {
             if (data.patientConfirmed) {
               if (data.patientShowedUp) {
                 return 'Showed UP'
               }
               else if (data.reason && data.reason.length > 0) {
                 return 'Canceled'
               }
               else {
                 return 'Confirmed'
               }
             } else {
               if (data.patientShowedUp) {
                 return 'Showed UP'
               }
               else if (data.reason && data.reason.length > 0) {
                 return 'Canceled'
               }
               else {
                 return 'New'
               }
             }
           },
           getPatientName: function (data) {
             return data.patientName
           },
           getEmployeeName: function (data) {
             return data.employeeName
           }

        }
    });
    var monthTempVue = Vue.component("monthTemp", {
        template: '<div class="template-wrapper" v-if="!data.isBlock">'+
        '<div class="subject" :id="data.id" style="color:white">{{data.subject}} {{data.notes}} </div>'+
        '<b-tooltip  :target="data.id" triggers="hover">'+
          '<h6 class="eventHintHeader">APPOINTMENT DETAILS</h6>'+
          '<div class="eventHint">'+
          '<b-icon icon="calendar3"></b-icon> {{getStartTime(data)}}<br>'+
          '<b-icon icon="calendar3"></b-icon> {{getEndTime(data)}}<br>'+
          '<b-icon icon="chat-right-dots"></b-icon> {{getAppointment(data)}}<br>'+
          '<b-icon icon="calendar-check"></b-icon> {{getConfirmed(data)}}<br>'+
          '<b-icon icon="person"></b-icon> {{getPatientName(data)}}<br>'+
          '</div>'+
          '<i>{{getEmployeeName(data)}}</i>'+
          
        '</b-tooltip></div>',
        methods: {
           getStartTime: function (data) {
              return `${instance.formatDate(data.startTime, { skeleton: 'yMd' })} ${instance.formatDate(data.startTime, { skeleton: 'hm' })}`;
           },
           getEndTime: function (data) {
              return `${instance.formatDate(data.endTime, { skeleton: 'yMd' })} ${instance.formatDate(data.endTime, { skeleton: 'hm' })}`;
           },
           getAppointment: function (data) {
             return data.appointmentTypeName
           },
           getConfirmed: function (data) {
             if (data.patientConfirmed) {
               if (data.patientShowedUp) {
                 return 'Showed UP'
               }
               else if (data.reason && data.reason.length > 0) {
                 return 'Canceled'
               }
               else {
                 return 'Confirmed'
               }
             } else {
               if (data.patientShowedUp) {
                 return 'Showed UP'
               }
               else if (data.reason && data.reason.length > 0) {
                 return 'Canceled'
               }
               else {
                 return 'New'
               }
             }
           },
           getPatientName: function (data) {
             return data.patientName
           },
           getEmployeeName: function (data) {
             return data.employeeName
           }

        }
    });
import CalendarResourcesService from '@/services/calendarresources.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import StorageService from '@/services/storage.service'
import ApiService from '@/services/api.service'
import EmployeeTypesService from '@/services/employeetypes.service'
import AppointmentTypes from '@/services/appointmenttypes.service'
import PatientService from '@/services/patient.service'
import ReasonService from '@/services/reason.service'
import SchedulerOptionsService from '@/services/schedulersoptions.service'
import UserAssignmentToCalResService from '@/services/userassignmenttocalres.service'
import EnumService from '@/services/enum.service'
import GlobalService from '@/services/global.service'
import Utils from "@/common/utils";

export default Vue.extend({
  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();              
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
    name: 'Schedule',
    mixins: [ ShowMessage,DBFunctions ],
    data: function () {
    return {
      dayTemplate: function () {
        return {
          template: dayTempVue
        };
      },
      weekTemplate: function () {
        return {
          template: weekTempVue
        };
      },
      workWeekTemplate: function () {
        return {
          template: workWeekTempVue
        };
      },
      monthTemplate: function () {
        return {
          template: monthTempVue
        };
      },
      Menu : this.$store.state.leftMenu,
      permission: [],
      patientCode: '',
      ShowCancelAppointment:false,
      patientName: '',
      StatusModel:{value:"All",name:this.$t('Shared.All')},
      AuxFields:['lastname'],
      currentView: 'Day',
      cssClass: 'block-events',
      showQuickInfo: false,
      height: 'calc(100vh - 110px)',
      width: '100%',
      fieldsEmployee : { text: 'employeeName', value: 'employeeCode' },
      fieldsStatus:{text: 'name', value: 'value'},
      fieldsEmployeeTypes : { text: 'description', value: 'code' },
      allowCustomValue : true,
      views: ['Day', 'Week', 'WorkWeek', 'Month'],
      weekNumberTemplate: '<div><button id="view_name"></button></div>',
      buttonRefreshTemplate: '<button id="button_view"></button></div>',
      buttonPrintTemplate: '<button id="button_print"></button></div>',
      OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,          
      },
      editorTemplate: function () {
        return { template: editortemplate }
      },
      schedulerfields: {
          // id: 'id',
          subject: { name: 'subject' },
          location: { name: 'location' },
          description: { name: 'notes' },
          startTime: { name: 'startTime' },
          endTime: { name: 'endTime' },
      },
      selectedDate: new Date(),
      eventSettings: this.getEventsSettings(),
      viewType: true,
      group: { enableCompactView: true, resources: ['Employee'] },
      employeeDataSource: [],
      StatusDataSource:EnumService.SchedulerStatus,
      employeeTypesDataSource: [],
      facilityDataSource: [],
      reasonDataSource: [],
      parameter:{
        EmployeeTypeList:[],
      },
      fieldsDate:['dob'],
      resourceHeaderTemplate: function () {
        return { template: resourceHeaderVue }
      },
      defaultAppointmentColor: '#0A64FF',
      defaultBlockingColor: '#C7C7C7',

      paramsUserAssignment: {
        UserID: ''
      },
      flag:true,
      isUserAssignmentToCalRes: false,
      timeScale: {
        enable: true,
        interval: 60,
        slotCount: 4
      },
      timezone: undefined,
      tzDisplayName: null,
      isCustomSave: false,
      eventData: [],
      fieldsToValidate: {
        patientCode: '',
        startTime: '',
        endTime: '',
        employeeCode: '',
        appointmentTypeCode: '',
        recurrence: '' 
      },
      eventAction: '',
      applyActionTo: '',
      confirmHeader: '',
      isReportVisible: false,
      startDate: null,
      dlgButtons: [
        {
          buttonModel: { content: this.$t('Shared.Save'), isPrimary: true },
          click: () => {
            const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];
            const dialogObj = document.querySelector("#alertDialog").ej2_instances[0];          
            if (this.eventAction === 'eventChange') {
              this.isCustomSave = true;
              scheduleObj.eventSettings.query.params[2].value = this.applyActionTo
              scheduleObj.saveEvent(this.eventData);
            }
            if (this.eventAction === 'eventRemove') {
              scheduleObj.eventSettings.query.params[2].value = this.applyActionTo
              scheduleObj.deleteEvent(this.eventData);
            }
            dialogObj.hide();
          },
        },
        {
          buttonModel: { content: "cancel" },
          click: () => {
            const dialogObj = document.querySelector("#alertDialog").ej2_instances[0];
            dialogObj.hide();
          },
        },
      ],
    }
  },
    provide: {
      schedule: [Day, Week, WorkWeek, Month, Resize, DragAndDrop]
    },

    methods: {
      CanView(){      
        return this.permission[0].roleCanView;
      },
      adjust(color, amount) {
        return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
      },

      changeRecurrenceType(args) {
        this.applyActionTo = args.value
      },

      OnEventRendered(args) {
        if(args.data.backgroundColor === null || args.data.backgroundColor === undefined) {
          if (args.data.isBlock){
            args.element.style.backgroundColor = this.defaultBlockingColor
          } else {
            let dColor = this.adjust(this.defaultAppointmentColor,-50);
            args.element.style.backgroundImage = "linear-gradient(0deg, "+dColor+", "+this.defaultAppointmentColor+")";
          }

        } else {
          let dColor = this.adjust(args.data.backgroundColor,-50);
          args.element.style.backgroundImage = "linear-gradient(0deg, "+dColor+", "+args.data.backgroundColor+")";
        }
      },

      onChangeView(args) {
        let scheduleObj = document.getElementById("Schedule").ej2_instances[0];
        if(args.value == 'By Calendar') {
          scheduleObj.group.resources = []
          this.viewType = false
        } else {
          scheduleObj.group.resources = ['Employee']
          this.viewType = true
        }
      },

      onActionFailure(args) {
        this.$emit('load', false);
        this.$refs.ScheduleObj.refreshEvents()
        if(args.error && args.error.length > 0 && args.error[0].error.status === 400) {
          let msgText = args.error[0].error.responseText.split(':')
          msgText = msgText[1].replaceAll('"', '').replaceAll('}', '');
          this.showMessage("Scheduler",msgText,"error");
        } else if (args.error && args.error.error && args.error.error.status === 401) {
          this.$router.go()
        }
      },

      onActionComplete(args) {
        let root = this 
        let scheduleObj = document.getElementById('Schedule'); 
        let dropdowns = scheduleObj.querySelector('#view_name');
        let button = scheduleObj.querySelector('#button_view');
        let buttonPrint = scheduleObj.querySelector('#button_print');
        if (args.requestType === "toolBarItemRendered") { 
          let dropDownListObject = new DropDownList({
            value: 'By Resource',
            dataSource: ['By Resource', 'By Calendar'],
            change: this.onChangeView.bind(this) 
          });
          dropDownListObject.appendTo(dropdowns);

          let buttonObjectRefresh = new Button({
            cssClass: 'e-tbar-btn e-tbtn-txt e-control e-btn',
            iconCss:'e-icons e-refresh-icon'
          });
          buttonObjectRefresh.appendTo(button);
          document.getElementById('button_view').setAttribute('title', 'Refresh')
          buttonObjectRefresh.element.addEventListener('click', function (args) {
            let scheduleObj = args.view.document.getElementById("Schedule").ej2_instances[0]
            scheduleObj.refreshEvents()
          });

          let buttonObjectPrint = new Button({
            cssClass: 'e-tbar-btn e-tbtn-txt e-control e-btn',
            iconCss:'e-icons e-print-icon'
          });
          buttonObjectPrint.appendTo(buttonPrint);
          document.getElementById('button_print').setAttribute('title', 'Print')
          buttonObjectPrint.element.addEventListener('click', function (args) {
            let scheduleObj = args.view.document.getElementById("Schedule").ej2_instances[0]
            root.startDate = scheduleObj.selectedDate
            root.showReport()
          });
        } 
        
        if(args.requestType === 'eventCreated' || args.requestType === 'eventChanged' || 
           args.requestType === 'eventRemoved') {
          this.$emit('load', false);
        }

        let scheduleObj1 = this.$refs.ScheduleObj;
        scheduleObj1.eventSettings.query.params[3].value = this.StatusModel.value;
      },
      
      onActionBegin: function (args) {
        const scheduleObj = document.querySelector(".e-schedule").ej2_instances[0];        
        if (args.requestType === 'toolbarItemRendering') {
          let scheduleSeperator = {
            align: "Right",
            cssClass: 'e-schedule-seperator',
            type: "Separator"
          }
          let byResourceItem = {
            align: 'Right', 
            template: this.weekNumberTemplate, 
            type: 'Input' 
          };
          let buttonItem = {
            align: 'Right', 
            template: this.buttonRefreshTemplate, 
            type: 'Input' 
          };
          let buttonPrint = {
            align: 'Right', 
            template: this.buttonPrintTemplate, 
            type: 'Input' 
          };
          args.items.push(scheduleSeperator);
          args.items.push(byResourceItem);
          args.items.push(buttonItem);
          args.items.push(buttonPrint);
        }

        
        if(args.requestType === 'eventCreate' || args.requestType === 'eventChange' || 
           args.requestType === 'eventRemove') {
           this.$emit('load', true);
        }

        if (args.requestType === "eventCreate") {
          args.data[0].id = '1'
        }
        
        /* if (args.changedRecords && args.changedRecords[0]) {
          if (!args.changedRecords[0].recurrenceRule && args.changedRecords[0].recurrence) {
            args.changedRecords[0].recurrenceRule = args.changedRecords[0].recurrence
          }
        }*/

        if (!this.isCustomSave && args.requestType === "eventChange" && this.fieldsToValidate.recurrence && 
          args.data.recurrence && this.fieldsToValidate.recurrence !== args.data.recurrence) {
            args.cancel = true;
            this.isCustomSave = true
            scheduleObj.eventSettings.query.params[2].value = 'ThisAndFollowing'
            scheduleObj.saveEvent(args.changedRecords[0]);
            this.$emit('load', false);
        }
        else if (!this.isCustomSave && args.requestType === "eventChange" && this.isModifiedFields(args.changedRecords[0])) {
          args.cancel = true;
          this.applyActionTo = 'ThisEvent'
          this.eventAction = "eventChange"
          this.confirmHeader = 'Edit recurring appointments?'
          this.eventData = args.changedRecords[0]

          const dialogObj = document.querySelector("#alertDialog").ej2_instances[0];
          const radio = document.querySelector("#radio1").ej2_instances[0];
          let dlgButtons = dialogObj.getButtons()
        
          radio.checked = true;
          dlgButtons[0].content = 'SAVE'
          
          if (args.data.parent) {
            this.eventData.id = args.data.parent.id
          }
          this.$emit('load', false);
          dialogObj.show();
        } 
        else if (!this.isCustomSave && args.requestType === "eventChange" && args.changedRecords[0].recurrence) {
          args.cancel = true;
          this.isCustomSave = true;
          scheduleObj.eventSettings.query.params[2].value = 'ThisEvent'
          this.eventData = args.changedRecords[0]
          if (args.data.parent) {
            this.eventData.id = args.data.parent.id
          }
          scheduleObj.saveEvent(this.eventData);
        }
      },

      setInitialValues(data) {
        this.fieldsToValidate.patientCode = data.patientCode
        this.fieldsToValidate.startTime = data.startTime
        this.fieldsToValidate.endTime = data.endTime
        this.fieldsToValidate.employeeCode = data.employeeCode
        this.fieldsToValidate.appointmentTypeCode = data.type
        this.fieldsToValidate.recurrence = data.recurrence
        this.isCustomSave = false;
      },

      onDragStart(args) {
        this.isCustomSave = false;
        this.setInitialValues(args.data)
      },

      onResizeStart(args) {
        this.setInitialValues(args.data)
      },

      changeResources(resources) {
        let resourcePredicate;
        let scheduleObj = this.$refs.ScheduleObj
        for (var a = 0; a < resources.length; a++) { 
          var predicate = new Predicate("employeeCode", "equal", resources[a]); 
          resourcePredicate = resourcePredicate ? resourcePredicate.or(predicate) : predicate; 
        } 
        let resourceQuery = resourcePredicate ? new Query().where(resourcePredicate) : new Query();        
        scheduleObj.ej2Instances.resources[0].query = resourceQuery
        scheduleObj.eventSettings.query.params[1].value = resources ? resources : undefined;
        console.log(scheduleObj.eventSettings.query.params);
      },

      onChangeResource: function (args) {
        if(this.isUserAssignmentToCalRes && args.value.length === 0) {
          this.getUserAssignmentToCalRes()
        } else {
          this.changeResources(args.value);          
        }
      },

      onChangeStatus: function (e) {       
        this.StatusModel = e !== null && e !== "" ? e : {value:"All",name:"All"};
        let scheduleObj = this.$refs.ScheduleObj;
        scheduleObj.eventSettings.query.params[3].value = this.StatusModel.value;
        scheduleObj.refreshEvents(); 
      },

      async onChangeEmployeeTypes (args) {
        let l = args.value.length;
        var EmployeeTypeList = [];
        if(l>0){
          let i = 0;
          args.value.forEach(function(e) {   
            if(i<l-1){
              EmployeeTypeList += e+",";
            }else{
              EmployeeTypeList += e;
            }
            i++;                    
          });
        }
        await this.getCalendarResByEmployeeType(EmployeeTypeList)
      },

      isModifiedFields(data) {
        if (this.fieldsToValidate.recurrence === null && data.recurrence) {
          return false
        }
        else if (this.fieldsToValidate.recurrence && data.recurrence === null) {
          return false
        }
        else if (data.recurrence )
          if (data.patientCode !== this.fieldsToValidate.patientCode ||
              data.employeeCode !== this.fieldsToValidate.employeeCode ||
              data.type !== this.fieldsToValidate.appointmentTypeCode ||
              data.startTime.toLocaleTimeString() !== this.fieldsToValidate.startTime.toLocaleTimeString() ||
              data.endTime.toLocaleTimeString() !== this.fieldsToValidate.endTime.toLocaleTimeString()) {
                this.showThisOpt = true
                return true
          }
      },

      onPopupClose: async function(args) {
        if(args.type === 'Editor') {
          if(args.event.target.className.includes("e-event-save")) {
            if (Utils.ValidateDateSchedulerEditor(args.data.startTime,args.data.endTime) === false) {
              args.cancel = true;
              this.showMessage("Scheduler",'Start time must be less than end time',"error");
            }
          } 
        }
      },

      onPopupOpen: async function(args) {
        if (args.type === 'EditEventInfo') {
          let titleElement = args.element.querySelector(".e-subject");
          titleElement.innerHTML = args.data.subject;
        }

        let appointmentTypesDataSource = [];         
        if ( args.data.recurrence && args.type === 'DeleteAlert') {
            this.applyActionTo = 'ThisEvent'
            args.cancel = true
            this.confirmHeader = 'Delete recurring appointments?'
            const dialogObj = document.querySelector("#alertDialog").ej2_instances[0];
            var radio = document.querySelector("#radio1").ej2_instances[0];
            radio.checked = true;
            let dlgButtons = dialogObj.getButtons()
            dlgButtons[0].content = 'REMOVE'
            this.eventAction = "eventRemove"
            this.eventData = args.data
            this.$emit('load', false);
            dialogObj.show();
        }
      
        
        async function getAppointmentTypes(employeeCode) { 
        await AppointmentTypes.getAll(employeeCode)
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              appointmentTypesDataSource = response.data;
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$t('Shared.AppointmentType'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('Shared.AppointmentType'),error,"error");
            }
          });
        }

       
        let scheduleObj = this.$refs.ScheduleObj.ej2Instances;
        let resourceCollections = scheduleObj.getResourceCollections()
        if (args.type === "RecurrenceAlert") {
          args.cancel = true;
          scheduleObj.currentAction= "EditSeries"
          scheduleObj.openEditor(args.data,'EditSeries');
        }  

        if (args.type === 'Editor') {            
          if (args.data && args.data.employeeCode) {
            await getAppointmentTypes(args.data.employeeCode)
          }
          
           let btnCollapsedPatientInfo = args.element.querySelector('#btnCollapsedPatientInfo');
          
          if(args.element.querySelector('#patientCode').value){          
            btnCollapsedPatientInfo.disabled = false;           
          }else{
             btnCollapsedPatientInfo.disabled = true;           
          }

          this.setInitialValues(args.data)
          scheduleObj.eventSettings.query.params[2].value = undefined

          let createdByElement = args.element.querySelector('#createdBy');
          if (createdByElement.classList.contains('createdByClass')) {
            createdByElement.innerHTML = args.data.createdBy;
            if (!args.data.createdBy)
              args.element.querySelector('#createdById').remove();
          }

          let creationDateElement = args.element.querySelector('#creationDate');
          if (creationDateElement.classList.contains('creationDateClass')) {
            creationDateElement.innerHTML = Utils.formatterDateTimeToString(args.data.creationDate);
            if (!args.data.creationDate)
              args.element.querySelector('#creationDateId').remove();
          }

          // Change Editor title
          // args.element.ej2_instances[0].header = `<div class="e-title-text">Appointment Editor ${args.data.patientName}</div>`
          let formElement = args.element.querySelector('.e-schedule-form');
          let subjectElement = args.element.querySelector('#subject');
          if (subjectElement.classList.contains('e-input')) {
              let subjectObject = new TextBox({
                value: args.data.subject,
                change: function () { 
                        if (typeof (document.getElementById("subject_Error")) != 'undefined' &&
                            document.getElementById("subject_Error")) { 
                              document.getElementById("subject_Error").style.display = "none"; 
                            } 
                           
                           let patientCode = args.element.querySelector('#patientCode').value;
                           let patientShowedUp = args.element.querySelector('#patientShowedUp').ej2_instances[0];
                           let patientConfirmed = args.element.querySelector('#patientConfirmed').ej2_instances[0];
                           let ReasonElement = args.element.querySelector('#reason').ej2_instances[0];

                           if(patientCode){
                             patientShowedUp.disabled = false;
                             patientConfirmed.disabled = false;
                             ReasonElement.enabled = true;
                           }
              }
            });
            subjectObject.appendTo(subjectElement);
            subjectElement.setAttribute('name', 'subject');
          }
          let typeElement = args.element.querySelector('#type');
          if (!typeElement.classList.contains('e-dropdownlist')) {
            let dropDownListObject = new DropDownList({
              placeholder: 'Choose a Type',
              value: args.data.type,
              allowFiltering: true,
              fields: { text: 'description', value: 'code' },
              dataSource: appointmentTypesDataSource,
              change: function (item) { 
                        if (typeof (document.getElementById("type_Error")) != 'undefined' &&
                            document.getElementById("type_Error")) { 
                              document.getElementById("type_Error").style.display = "none"; 
                            }
                        if(item.itemData) {
                          let duration = item.itemData.duration;                          
                          let startTimeObj = new Date(args.element.querySelector('#startTime').value);
                          let endTimeObj = new Date(startTimeObj.getTime() + duration*60000);
                          let endTime = args.element.querySelector('#endTime').ej2_instances[0]
                          endTime.value = endTimeObj
                        }
              },
              filtering: function(e) {
                  var query = new Query();
                    query = (e.text != "") ? query.where("description", "contains", e.text, true) : query;
                    e.updateData(appointmentTypesDataSource, query);
              },
            });
            dropDownListObject.appendTo(typeElement);
            typeElement.setAttribute('name', 'type');
          }

          let ReasonElement = args.element.querySelector('#reason');
          if (!ReasonElement.classList.contains('e-dropdownlist')) {
            let dropDownListObject = new DropDownList({
              value: args.data.reason,
              enabled:args.element.querySelector('#patientCode').value === '' ? false : true,
              fields: { text: 'name', value: 'code' },
              dataSource: this.reasonDataSource,
              change: function (item) {                   
                    let patientShowedUp = args.element.querySelector('#patientShowedUp').ej2_instances[0];
                    let patientConfirmed = args.element.querySelector('#patientConfirmed').ej2_instances[0]
                    let subjectElement = args.element.querySelector('#subject').ej2_instances[0]

                    if(item.itemData) {
                      let subject = new String(subjectElement.value)
                      if (subject.endsWith("- CANCELLED")) {
                        subject = subject.replaceAll(/ - CANCELLED/g, "");
                      } else if (subject.endsWith("- CONFIRMED")) {
                        subject = subject.replaceAll(/ - CONFIRMED/g, "");
                      } else if (subject.endsWith("- SHOWED UP")) {
                        subject = subject.replaceAll(/ - SHOWED UP/g, "");
                      }
                      if (item.itemData.code) {
                        subject = `${subject} - CANCELLED`;
                        patientShowedUp.checked = false
                      }
                      else if (patientConfirmed.checked && patientShowedUp.checked) {
                        subject = `${subject} - SHOWED UP`;
                      } 
                      else if (patientConfirmed.checked) {
                        subject = `${subject} - CONFIRMED`;
                      } 
                      else if (patientShowedUp.checked) {
                        subject = `${subject} - SHOWED UP`;
                      }
                      subjectElement.value = subject
                    }
              },
            });
            dropDownListObject.appendTo(ReasonElement);
            ReasonElement.setAttribute('name', 'Reason');
          }
          let EmployeeElement = args.element.querySelector('#EmployeeCode');
          if (!EmployeeElement.classList.contains('e-dropdownlist')) {
            let dropDownListObject = new DropDownList({
              placeholder: 'Choose a Employee',
              fields: { text: 'employeeName', value: 'employeeCode' },
              value: args.data.employeeCode,
              dataSource:  resourceCollections[0].dataSource,
              change: async function (item) { 
                        if (typeof (document.getElementById("EmployeeCode_Error")) != 'undefined' &&
                            document.getElementById("EmployeeCode_Error")) { 
                              document.getElementById("EmployeeCode_Error").style.display = "none"; 
                            }
                        if(item.itemData && item.itemData.employeeCode) {
                          let typeElement = args.element.querySelector('#type').ej2_instances[0]
                          await getAppointmentTypes(item.itemData.employeeCode)
                          typeElement.dataSource = null
                          typeElement.dataSource = appointmentTypesDataSource
                        }
              }
            });
            dropDownListObject.appendTo(EmployeeElement);
            EmployeeElement.setAttribute('name', 'employeeCode');
          }
          let startElement = args.element.querySelector('#startTime');
          if (!startElement.classList.contains('e-datetimepicker')) {
            new DateTimePicker({
              value: new Date(startElement.value) || new Date(),
              step: 15,
              change: function () { 
                        if (typeof (document.getElementById("startTime_Error")) != 'undefined' &&
                            document.getElementById("startTime_Error")) { 
                              document.getElementById("startTime_Error").style.display = "none"; 
                            }                          
                          let startTimeObj = new Date(args.element.querySelector('#startTime').value);
                          let typeElementSelected = args.element.querySelector('#type').ej2_instances[0];                         
                          var AppTypeDuration = 15;
                          if(typeElementSelected.itemData)
                            AppTypeDuration = typeElementSelected.itemData.duration;
                          let endTimeObj = new Date(startTimeObj.getTime() + AppTypeDuration*60000);
                          let endTime = args.element.querySelector('#endTime').ej2_instances[0];
                          endTime.value = endTimeObj;
                          endTime.min = startTimeObj  
              },
            },
              startElement
            );
          }
          let endElement = args.element.querySelector('#endTime');
          let startTimeObj = new Date(args.element.querySelector('#startTime').value);
          if (!endElement.classList.contains('e-datetimepicker')) {
            new DateTimePicker({
              value: new Date(endElement.value) || new Date(),
              step: 15,
              min: startTimeObj,
              change: function () { 
                        if (typeof (document.getElementById("endTime_Error")) != 'undefined' &&
                            document.getElementById("endTime_Error")) { 
                              document.getElementById("endTime_Error").style.display = "none"; 
                            } 
              },
              
          },
           endElement
          );
          }
          let PatientConfirmedElement = args.element.querySelector('#patientConfirmed');
          if (!PatientConfirmedElement.classList.contains('e-checkbox')) {
            new CheckBox(
              { 
                label: "Patient Confirmed", 
                checked: PatientConfirmedElement.checked,
                disabled:args.element.querySelector('#patientCode').value === '' ? true : false,
                change: function (item) {
                  let subjectElement = args.element.querySelector('#subject').ej2_instances[0]
                  let patientShowedUp = args.element.querySelector('#patientShowedUp').ej2_instances[0]
                  let subject = new String(subjectElement.value)
                  
                  if (subject.endsWith("- CANCELLED")) {
                    subject = subject.replaceAll(/ - CANCELLED/g, "");
                  } else if (subject.endsWith("- CONFIRMED")) {
                    subject = subject.replaceAll(/ - CONFIRMED/g, "");
                  } else if (subject.endsWith("- SHOWED UP")) {
                    subject = subject.replaceAll(/ - SHOWED UP/g, "");
                  }

                  if (item.checked && patientShowedUp.checked) {
                    subject = `${subject} - SHOWED UP`;
                  } else if (item.checked) {
                    subject = `${subject} - CONFIRMED`;
                  } else if (patientShowedUp.checked) {
                    subject = `${subject} - SHOWED UP`;
                  }
                  subjectElement.value = subject
                 }
              },
              PatientConfirmedElement
            );
          }
          let PatientShowedUpElement = args.element.querySelector('#patientShowedUp');
          if (!PatientShowedUpElement.classList.contains('e-checkbox')) {
            new CheckBox(
              { 
                label: "Patient Showed Up", 
                checked: PatientShowedUpElement.checked,
                 disabled:args.element.querySelector('#patientCode').value === '' ? true : false,
                change: function (item) {
                  let subjectElement = args.element.querySelector('#subject').ej2_instances[0]
                  let patientConfirmed = args.element.querySelector('#patientConfirmed').ej2_instances[0]
                  let subject = new String(subjectElement.value)
                  let reason = args.element.querySelector('#reason').ej2_instances[0]

                  if (subject.endsWith("- CANCELLED")) {
                    subject = subject.replaceAll(/ - CANCELLED/g, "");
                  } else if (subject.endsWith("- CONFIRMED")) {
                    subject = subject.replaceAll(/ - CONFIRMED/g, "");
                  } else if (subject.endsWith("- SHOWED UP")) {
                    subject = subject.replaceAll(/ - SHOWED UP/g, "");
                  }

                  if (item.checked && patientConfirmed.checked) {
                    subject = `${subject} - SHOWED UP`;
                    reason.value = ''
                  } else if (patientConfirmed.checked) {
                    subject = `${subject} - CONFIRMED`;
                  } else if (item.checked) {
                    subject = `${subject} - SHOWED UP`;
                    reason.value = '' 
                  }
                  subjectElement.value = subject
                 }
              },
              PatientShowedUpElement
            );
          }
          let recObj = args.element.querySelector('#RecurrenceEditor').ej2_instances[0]
          this.$refs.ScheduleObj.ej2Instances.eventWindow.recurrenceEditor = recObj;
          if (args.data.recurrence) {
            recObj.setRecurrenceRule(args.data.recurrence);
          }

          document.getElementById('RecurrenceBlock').style.display = (scheduleObj.currentAction == 'EditOccurrence') ? 'none' : 'table-row';
          this.removeNeverOption(args)

          let validator = (formElement).ej2_instances[0]; 
          validator.addRules('type', { required: [true] });
          validator.addRules('subject', { required: [true] });
          validator.addRules('EmployeeCode', { required: [true, 'Employee required!'] });
        }
      },

      removeNeverOption(args) {
        let recurElement2 = args.element.querySelector('.e-recurrenceeditor').ej2_instances[0];
        recurElement2.endType.element.ej2_instances[0].listData.splice(0,1)
        let recEndTypeData = recurElement2.endType.element.ej2_instances[0].listData;
        recurElement2.endType.element.ej2_instances[0].element.ej2_instances[0].dataSource = recEndTypeData
      },

      getCalendarResources() { 
        CalendarResourcesService.getAll()
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.employeeDataSource = response.data;
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$t('calendarResources.cResources'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('calendarResources.cResources'),error,"error");
            }
          });
      },

      getEventsSettings() {
        let dataManager = new DataManager({
          url: `${ApiService.APIURL}Schedulers/`,
          adaptor: new UrlAdaptor(),
          headers: [{
            Authorization: 'Bearer '+ StorageService.getOnlyToken()
          }],
          crossDomain: true,
          crudUrl: `${ApiService.APIURL}Schedulers/crud/`,
        });
        let dataQuery= new Query().addParams('PatientCodeList', undefined);
        dataQuery.addParams('EmployeeCodeList', undefined);
        dataQuery.addParams('applyActionTo', undefined);
        dataQuery.addParams('Status', undefined);
        let eventSettings = {
          dataSource:  dataManager,
          query: dataQuery,
          fields: {
            id: 'id',           
            location: {name: 'location' },
            description: {name: 'description' },
            startTime: { name: 'startTime', validation: { required: true } },
            endTime: { name: 'endTime', validation: { required: true } },
            isBlock: 'isBlock',
            recurrence: { name: 'recurrence' },       
          }
        }           
        return eventSettings
      },

      getValues: function (value) {
        let scheduleObj = this.$refs.ScheduleObj;      
        if(value.code && value.description) {
          this.patientCode = value.code;
          this.patientName = `${value.description} ${value.AuxFields && value.AuxFields[0] ? value.AuxFields[0] : ''}`
        } else {
          this.patientCode = ''
        }
        scheduleObj.eventSettings.query.params[0].value = this.patientCode ? [this.patientCode] : undefined;    

        if (scheduleObj.eventSettings.query.params[1].value && scheduleObj.eventSettings.query.params[1].value.length > 0) {
          let resourcePredicate;
          let resourceQuery = resourcePredicate ? new Query().where(resourcePredicate) : new Query(); 
          scheduleObj.ej2Instances.resources[0].query = resourceQuery
          scheduleObj.eventSettings.query.params[1].value = undefined
        } else {
          scheduleObj.refreshEvents() 
        }
        
        setTimeout(()=>{
          if(scheduleObj.ej2Instances.eventsData && scheduleObj.ej2Instances.eventsData.length > 0 && value.code) {
            let employeeList = []
            scheduleObj.ej2Instances.eventsData.forEach(element => {
              employeeList.push(element.employeeCode)
            });
            let uniqueEmployee = [...new Set(employeeList)];
            this.changeResources(uniqueEmployee)
          }
        }, 500 );
      },

      getEmployeeTypes() { 
        EmployeeTypesService.getAll()
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.employeeTypesDataSource = response.data;
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage("Employee Types",this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage("Employee Types",error,"error");
            }
          });
      },

      getCalendarResByEmployeeType(employeeTypes) {
        this.parameter.EmployeeTypeList = employeeTypes
        let employeeTypeList = []
        CalendarResourcesService.getByEmployeeType(this.parameter)
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              response.data.forEach(element => {
                employeeTypeList.push(element.employeeCode)
              });
              this.changeResources(employeeTypeList)
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$t('calendarResources.cResources'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('calendarResources.cResources'),error,"error");
            }
          });
      },

      getFacilities() { 
        PatientService.getFacilities('')
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.facilityDataSource = response.data;
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage("Facilities",this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage("Facilities",error,"error");
            }
          });
      },

      getReason() { 
        ReasonService.getAll()
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.reasonDataSource = response.data;
              this.reasonDataSource.unshift({active: true,code: "",name: "",reasonType: ""})
              this.$store.commit('setReasonDataSource',this.reasonDataSource);
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage("Reason",this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage("Reason",error,"error");
            }
          });
      },

      onDataBound: function(){        
         if (this.flag) { 
            this.$refs.ScheduleObj.scrollTo(this.$refs.ScheduleObj.ej2Instances.workHours.properties.start);
            this.flag = false; 
          } 
      },

      onCreated: async function(){
        this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission); 
        this.getWorkHours()
        await this.getTimeZone()
      },

      getWorkHours(){
       this.$refs.ScheduleObj.ej2Instances.workHours.properties.start = '06:00'
       this.$refs.ScheduleObj.ej2Instances.workHours.properties.end = '20:00'
       
       if(this.permission.length > 0 && this.permission[0].roleCanView){
       SchedulerOptionsService.getAll()
       .then((response) => {
       if(response.status == '200' && response.data && response.data.length > 0){
        let schedulerOptions = response.data;            
        if(schedulerOptions[0].dayBegins && schedulerOptions[0].dayEnds) {
          this.$refs.ScheduleObj.ej2Instances.workHours.properties.start = schedulerOptions[0].dayBegins
          this.$refs.ScheduleObj.ej2Instances.workHours.properties.end = schedulerOptions[0].dayEnds
        }
       }
      })
      .catch(error => {     
        if(!error.response) {
          this.showMessage("Scheduler Options",this.$t('Msg.NotAbleToInsert'),"error");
        }                      
        else if(error.response.status === 400 ){
          this.showMessage("Scheduler Options",error.response.data.errorMessage,"error");
        }else if(error.response.status === 500){
          this.showMessage("Scheduler Options",this.$t('Msg.ThereWereSomeError'),"error");
        } else{
          this.showMessage("Scheduler Options",error.response.data.errorMessage,"error");
        } 
        this.$emit('load', false);
      });
      }
      },

      setUserAssignmentToCalRes(employees){
        let employeeList = []
        employees.forEach(element => {
          employeeList.push(element.employeecode)
        });
        this.changeResources(employeeList)
      },

      getUserAssignmentToCalRes(){
       this.paramsUserAssignment.UserID = StorageService.getUserId();
       UserAssignmentToCalResService.getAll(this.paramsUserAssignment)
       .then((response) => {
       if(response.status == '200' && response.data && response.data.length > 0){
         this.setUserAssignmentToCalRes(response.data)
         this.isUserAssignmentToCalRes = true
       }
      })
      .catch(error => {
        if (!error.response) {
            // network error
            this.showMessage("User Assignment Calendar Resource",this.$t('Msg.RetrieveInformation'),"error");
        } else {
            this.showMessage("User Assignment Calendar Resource",error,"error");
        }
      });
      },

      showReport() {
        this.isReportVisible = true
      },

      closeParamRepWindow() {
        this.isReportVisible = false
      },

      async getTimeZone() { 
         await GlobalService.getTimeZone()
            .then((response) => {      
               if (response.data) {
                this.timezone = response.data.id
                this.tzDisplayName = response.data.displayName
               }  
            })
            .catch(error => {
             if(error.response.status === 400 ){
                 this.showMessage('Scheduler',error.response.data.errorMessage,"error");
              }else{
                this.showMessage('Scheduler',error,"error");
              }
            });   
      },
  },

  created(){
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission); 
  },

  async mounted(){    
    this.OptionsRightMenu.ItemsList = [];
    this.OptionsRightMenu.Mode = false;
    this.OptionsRightMenu.visible = false; 
    this.OptionsRightMenu.btnvisible = false;
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.$emit('getBtnRightMenu', this.OptionsRightMenu);

    if(this.permission.length == 0){
        this.$router.push({name: "Main"});    
          setTimeout(() => 
            this.showPermissionMessage()
          , 50); 
        this.$emit('load', false); 
    }
      this.getCalendarResources()
      this.getFacilities()
      this.getReason()
      this.getEmployeeTypes()
      this.getUserAssignmentToCalRes()
   

    
  },
});
</script>
<style>
    @import '../../../assets/css/syncfusion/bootstrap4.css';
    .schedule-vue-sample .block-events.e-schedule .template-wrap {
        width: 100%;
    }

    .schedule-vue-sample .block-events.e-schedule .e-vertical-view .e-resource-cells {
        height: 58px;
    }

    .schedule-vue-sample .block-events.e-schedule .e-timeline-view .e-resource-left-td,
    .schedule-vue-sample .block-events.e-schedule .e-timeline-month-view .e-resource-left-td {
        width: 170px;
    }

    .schedule-vue-sample .block-events.e-schedule .e-resource-cells.e-child-node .employee-category,
    .schedule-vue-sample .block-events.e-schedule .e-resource-cells.e-child-node .employee-name {
        padding: 5px
    }

    .schedule-vue-sample .block-events.e-schedule .employee-image {
        width: 45px;
        height: 40px;
        float: left;
        border-radius: 50%;
        margin-right: 10px;
    }

    .schedule-vue-sample .block-events.e-schedule .employee-name {
        font-size: 13px;
    }

    .schedule-vue-sample .block-events.e-schedule .employee-designation {
        font-size: 10px;
    }

    .schedule-vue-sample .content-wrapper{
      padding: 0 !important;
    }    

    .schedule-vue-sample .e-day-view .e-date-header-wrap table col, .e-schedule .e-day-view .e-content-wrap table col { 
      width: 200px; 
    }
    .eventHintHeader{
      padding-bottom: 3px;
      border-bottom: 1px solid silver;
    }
    .eventHint{
      text-align: left;
      padding: 0 6px 6px 6px;
      color: seashell;
      vertical-align: bottom;
    }
    .eventHint svg{
      color: white;
      margin: 10px 4px 0 0;
    }
    .e-footer-content button{
      text-transform: uppercase !important;
    }
    .e-schedule .e-vertical-view .e-time-cells-wrap table td, .e-schedule .e-vertical-view .e-work-cells {
      height: 30px !important;
    }

    .e-refresh-icon::before {
      content: '\e716';
      font-weight:bold
    }

    .e-print-icon::before {
      content: '\e743';
      font-weight:bold
    }

    @media screen and (max-width: 600px) {
      .HideFilter{
        display:none;
      }
    }

    .panel-scheduler {
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background-image: -webkit-linear-gradient(268deg, #FAFAFA, #FAFAFAEE);
      border-radius: 0 0 14px 14px;
      border-top: 1px solid #EEE;
      padding: 10px 10px 10px 15px;
      text-align: left;
      z-index: 2;
    }
</style>