<template>
  <div id="wrapper__right" class="d-lg-block d-md-block" style="min-Width:245px;"> 
      <div class="rm-header">
              <div id="rm-info" class="textlabel" :data-label="this.$t('RigthBar.patInfo')">
                    <p id="rm-name" class=" text-truncate"><span>{{this.DataHeader.title}}</span> {{this.DataHeader.firstName}}</p>
                    <p id="rm-surname" class=" text-truncate">{{this.DataHeader.lastName}}</p>
              </div>



              <div :id="targetId" oncontextmenu="return false;" tabindex="0" v-on:focus="OnFocusPhoto">
                <div id="rm-frame">
                  <ejs-contextmenu :target="menuTargetId" :id="targetId" :items='menuItems' :select='onSelect'></ejs-contextmenu>
                  <input type="file" ref="input" hidden value="" @change="addImage($event)"/>
                  <img v-on:click="OnCamera" v-if="this.Photo.contents !== null && this.Photo.contents !== ''" id="rm-photo" v-bind:src="'data:image/'+this.Photo.extension+';base64,'+this.Photo.contents" data-toggle="modal" data-target="#exampleModalScrollable">
                  <img v-on:click="OnCamera" v-if="this.Photo.contents === null || this.Photo.contents === ''" id="rm-photo" src="@/assets/images/faces/nopic-male.jpg" data-toggle="modal" data-target="#exampleModalScrollable">             
                  <div v-on:click="GoToPatientSummary" class="rm-more">{{ $t('Shared.Details') }}</div>
                </div>
              </div>



                    <p v-if="this.DataHeader.patientCode" id="rm-summary" class="text-truncate pull-left">ID {{this.DataHeader.patientCode}}</p>
                    <div class="bottomShadow"></div>
                    <i v-if="this.DataHeader.allergies" id="rm-allergic" class="mdi mdi-alert text-warning allergies" v-b-tooltip.hover.bottomleft="this.$t('RigthBar.pPhoto')+' ' + this.DataHeader.allergies" variant="primary"><div>{{$t('RigthBar.ALLERGIC')}}</div></i>
              </div>
                 <marquee v-on:click="GoToPatientSummary" data-toggle="modal" data-target="#exampleModalScrollable" id="rm-marquee" scrollamount="4" truespeed="true" >
                  <small v-if="this.DataHeader.dob">{{ $t('Shared.Dob') }}:</small> {{this.DataHeader.dob}}<span v-if="this.DataHeader.dob">///</span>
                  <small v-if="this.DataHeader.ageFormatted">{{ $t('Shared.Age') }}:</small> {{this.DataHeader.ageFormatted}}<span v-if="this.DataHeader.ageFormatted">///</span>
                  <small v-if="this.DataHeader.gender">{{ $t('Patient.Sex') }}:</small> {{this.DataHeader.gender}}<span v-if="this.DataHeader.gender">///</span>            
                  <small v-if="this.DataHeader.cellPhone">{{ $t('Shared.CellPhone') }}:</small> {{this.DataHeader.cellPhone}}<span v-if="this.DataHeader.cellPhone">///</span>             
                  <small v-if="this.DataHeader.allergies">{{$t('MdPatientAllergies.Allergies')}}:</small>{{this.DataHeader.allergies}}<span v-if="this.DataHeader.allergies">///</span>
                  <small v-if="this.DataHeader.email">{{ $t('Shared.Email') }}:</small> {{this.DataHeader.email}}
                </marquee>
                <div class="rm-options">
                    <div id="rm-items">
                         <item v-bind:ItemList="this.$attrs.ItemList" 
                         v-bind:itemdisabled="this.$attrs.itemdisabled"
                         v-bind:PatientCode="this.$attrs.PatientCode"                         
                         />
                         <small>{{ $t('GroupNotesRightBar.OptionMenu') }}</small>
                    </div>
                </div>

                <ModalCamera
                v-bind:TitleWindow="$t('GroupNotesRightBar.TakePic')"
                v-bind:ModalId="this.modalIdCamera"                
                >
              </ModalCamera>
  </div>



</template>

<script>
import Item from '@/components/template/items/Item'
import DBFunctions from '@/common/DBFunctions.js'
import Utils from '../../../common/utils'
import { EventBus } from '../../../event-bus.js';
import DocumentManagementService from '@/services/documentmanagement.service' 
import Vue from 'vue';
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
Vue.use(ContextMenuPlugin);

export default {  
  name: 'RigthBar',
  mixins: [DBFunctions],
  components:{Item},
  data(){
    return{
      DataHeader:{},
      modalIdCamera:"modalIdCamera",
      menuItems:[],
      menuTargetId:"#target-menu-righmenu",
      targetId:"target-menu-righmenu",
      NotPhoto:true,
      Photo:{      
        contents:"",
        extension:"", 
        fileName:"",   
      },
      msg: this.$t('Msg.SavedSuccessfully')
    }
  },
 
  methods: {
    loadMenuItems(from) {
      const indexToRemove = this.menuItems.findIndex(item => item.id === 'RemoveUserPicture');
      if(!from){
        this.menuItems.push({text: this.$t('GroupNotesRightBar.LoadPic'),id:"LoadPicture"})
        this.menuItems.push({text: this.$t('GroupNotesRightBar.TakePic'),id:"TakePicture"})
      }
      if(this.Photo.contents){
        if( indexToRemove == -1){
          this.menuItems.push({text: this.$t('GroupNotesRightBar.RemovePic'), id: "RemoveUserPicture"})
        } 
      } else {
          if (indexToRemove !== -1) {
              this.menuItems.splice(indexToRemove, 1);
          }
          
      }
    },

    formatterDateToString(date){
            return Utils.formatterDateToString(date);
		},

   async GetDataHeader(){
    this.DataHeader = await this.getData(this.$route.params.id);
   // console.log(this.DataHeader);
  //  this.DataHeader.dob = this.DataHeader.dob ? this.formatterDateToString(this.DataHeader.dob) : this.DataHeader.dob;
    this.DataHeader.age = this.DataHeader.age === 0 ? "0" : this.DataHeader.age;
  

    },

    /*loadMenuItems() {
      this.menuItems = [
        {
          text: this.$t('Shared.GetLast10'),
          id: "gettenlastans"
        }
      ]
    },*/

   OnCamera(){ 
      this.$bvModal.show(this.modalIdCamera);   
    },

    SyncFileReader(file) {
    let self = this;
    let ready = false;
    let result = '';

    const sleep = function (ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    self.readAsDataURL = async function() {
        while (ready === false) {
          await sleep(100);
        }
        return result;
    }    

    const reader = new FileReader();
    reader.onloadend = function(evt) {
        result = evt.target.result;
        ready = true;
    };
    reader.readAsDataURL(file);
  },

    async addImage(e){
      this.msg = this.$t('Msg.LoadedSuccessfully')
      let droppedFiles = e.type === 'drop'?e.dataTransfer.files:e.target.files;     
     //var extension = "";
      if(!droppedFiles) return;     
      if(droppedFiles.length === 1){
        var filename = droppedFiles[0].name;
        this.Photo.fileName = filename;
        const fileReader = new this.SyncFileReader(droppedFiles[0]);
        const arrayBuffer = await fileReader.readAsDataURL();
        if(arrayBuffer){
          const img = document.getElementById("rm-photo");
          img.src = arrayBuffer;
        }    

        if(arrayBuffer.indexOf(',') !== -1){
           var b = arrayBuffer.split(',');    
           this.Photo.contents = b[1];  
           //this.files.push({"name":fileNameNoExtension,"Image":Image,"extension":extension,"base64":b[1],"description":"","code":code,"type":type,"new":true,"isreadonly":true});
       
        }
        
        var pos = filename.lastIndexOf('.');
        if(pos !== -1){          
          this.Photo.extension = filename.substring(pos).toUpperCase();
        }
        this.SavePhoto();        
      }
        /* for (var i = 0; i < droppedFiles.length; i++) {
       var filename = droppedFiles[i].name;
        var size = droppedFiles[i].size;
        if(size !== 0){
          if(!this.ExistFile(filename)){
        
        var fileNameNoExtension;
        var pos = filename.lastIndexOf('.');
        if(pos !== -1){
          fileNameNoExtension = filename.substring(0, pos);
          extension = filename.substring(pos).toUpperCase();
        }
        var Image = Utils.getType(extension);
        var type = droppedFiles[i].type;
        var code = this.getUniqueID();
        const fileReader = new this.SyncFileReader(droppedFiles[i]);
        const arrayBuffer = fileReader.readAsDataURL();
        if(arrayBuffer.indexOf(',') !== -1){
           var b = arrayBuffer.split(',');         
           this.files.push({"name":fileNameNoExtension,"Image":Image,"extension":extension,"base64":b[1],"description":"","code":code,"type":type,"new":true,"isreadonly":true});
           this.FileChanged();
        }
        }else{
           this.showMessage(this.$t('MdNotesInFiles.AddFile'),this.$t('MdNotesInFiles.DuplicateFieldMsg'),"error");
        }
        }else{
           this.showMessage(this.$t('MdNotesInFiles.AddFile'),this.$t('MdNotesInFiles.FileNoBytesMsg'),"error");
        }  
      }*/
      
    },

    
   /* OnCamera : async function() {
      this.$bvModal.show(this.modalIdCamera);      
    },*/

    LoadPicture(){       
        this.$refs.input.click();
        this.$refs.input.value = "";       
      },

    onSelect:function(args) {    
       if(args.item.id === 'LoadPicture') {
        this.LoadPicture();
       }
       if(args.item.id === 'TakePicture') {
        this.OnCamera();
       // alert("Take Picture");
       }
       if(args.item.id === 'RemoveUserPicture') {
          this.RemovePicture();
       }
    },

    SavePhoto(){  
      if(!this.NotPhoto){
        this.UpdatePhoto();
      }else{
        this.InsertPhoto();
      }
      this.loadMenuItems(true); 
    },

    OnFocusPhoto(){
      
    },

    InsertPhoto(){
      var data = {
        Entitycode:this.$route.params.id,
        contents:this.Photo.contents,
        extension:this.Photo.extension, 
        fileName:this.Photo.fileName  
      };
      DocumentManagementService.AddPhoto(data)
          .then((response) => {
           if(response.status == '200'){
            this.Photo = response.data;
            this.NotPhoto = false;
            setTimeout(() => 
                        this.$bvToast.toast(this.msg, {
                         title: this.$t('RigthBar.pPhoto'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
           
            }                           
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('RigthBar.pPhoto'),error,"error");
                    }
                });  
    },
    

    UpdatePhoto(){
      var data = {
        Entitycode:this.$route.params.id,
        contents:this.Photo.contents,
        extension:this.Photo.extension, 
        fileName:this.Photo.fileName,   
      };

      DocumentManagementService.UpdatePatientPhoto(this.Photo.seqno,data)
          .then((response) => {
           if(response.status == '200'){
            setTimeout(() => 
                        this.$bvToast.toast(this.msg, {
                         title: this.$t('RigthBar.pPhoto'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
            }                            
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('RigthBar.pPhoto'),error,"error");
                    }
                });  
    },
    

  async GetPhoto(){
      await DocumentManagementService.GetPatientPhoto(this.$route.params.id)
          .then((response) => {
            if(response.status == '200' && response.data){
              this.Photo = response.data;          
              if(this.Photo.contents !== null && this.Photo.contents !== ""){
                this.NotPhoto = false;
              }
            }                           
          })
          .catch((error) => {
              if (error.response) {
                  this.showMessage(this.$t('RigthBar.pPhoto'),error,"error");
              }
          });  
    },


    GoToPatientSummary(){
     if(this.$store.state.isUsePatientInfo) {
      if(this.$route.name.includes('patient') || this.$route.name.includes('contact') || this.$route.name.includes('allergy')){
        if( this.$router.currentRoute.name == "patient-details") {
            this.$bvToast.toast(this.$t('Layout.displayedScreen'), {
                title: this.$t('MdPatientInfoBox.PatientInfo'),
                autoHideDelay: 4000,
                variant: "success"
              });
        } else {
            this.$router.push({ name: "patient-details", params: { id: this.$route.params.id } })
              .catch(()=>{});
        }
        
      } else if(this.$route.name.includes('Clinical') || this.$route.name.includes('vs')){
          if( this.$router.currentRoute.name == "Clinical-PatientInfo") {
              this.$bvToast.toast(this.$t('Layout.displayedScreen'), {
                  title: this.$t('MdPatientInfoBox.PatientInfo'),
                  autoHideDelay: 4000,
                  variant: "success"
                });
          } else {
              this.$router.push({ name: "Clinical-PatientInfo", params: { id: this.$route.params.id,recordid:this.$route.params.recordid } })
                .catch(()=>{});
          }
      }
     }
    },
    RemovePicture(){
      if(this.NotPhoto)return;      
      this.Photo.contents = '',
      this.Photo.extension = '', 
      this.Photo.fileName = '', 
      this.msg = this.$t('Msg.RemoveSuccessfully')
      this.SavePhoto() 
    },
  },
  created(){
    EventBus.$on("PhotoWasTaken", function (data) {
      const img = document.getElementById("rm-photo");
      img.src = data;
      if(data.indexOf(',') !== -1){
           var b = data.split(',');              
           if(b[0].indexOf(';') !== -1){
            var c = b[0].split(';');  
            if(c[0].indexOf('/') !== -1){
              var d = c[0].split('/');
              this.Photo.extension = "."+d[1]; 
              this.Photo.contents = b[1]; 
              this.Photo.fileName = ""; 
              this.msg = this.$t('Msg.SavedSuccessfully')            
              this.SavePhoto();
            }
           } 
          
      }
    }.bind(this));
  },
  mounted(){  
   
   this.GetDataHeader();
   this.GetPhoto()
        .then(() => {
          // First function has finished, call the second function
          this.loadMenuItems();     
      });
   document.getElementById("target-menu-righmenu").focus();
  //window.location.hash = '#target-menu-righmenu';
 
    }
  };
  
</script>

<style>

</style>


