<template>
  <div id="m-layout" :class="layoutStatus" ref="mlayout">
    <ChangePassWord v-if="this.changePassDialogCmp" />
    <WhatsNew v-if="!this.Loading" />
    <Preferences v-if="!this.Loading" />
    <MdTextSpeech v-if="!this.Loading"/>
    <div id="m-container" class="m-layout m-container" @click="closeMenuClick()">
      <!-- CONTAINER START -->
      <div class="m-pages">
        <div class="m-pagecontent">
          <router-view
            @getVisibleMenu="onVisibleRightMenu($event)"
            @onMenuRightBarGroupNotes="onMenuRightBarGroupNotes($event)"
            @onMenuRightBarIncidentWorkflow="onMenuRightBarIncidentWorkflow($event)"
            @load="OnLoading($event)"
            @getInfoPatient="OnInfoPatient($event)"
          />
        </div>
      </div>
      <!-- CONTAINER END -->
    </div>
    <div id="m-rightmenu" class="m-layout m-rightmenu" @click="closeMenuClick()">
      <!-- RIGHT MENU START -->
      <RigthBar
        v-if="this.btnvisible"
        v-bind:ItemList="this.ItemList"
        v-bind:InfoPatient="this.InfoPatient"
        v-bind:itemdisabled="this.itemdisabled"
        v-bind:PatientCode="this.PatientCode"
      />
    <GroupNotesRigthBar
      v-if="this.btnGroupNotesvisible"
      v-bind:ItemList="this.ItemListGroupNotes"
      v-bind:InfoPatient="this.InfoPatient"
      v-bind:itemdisabled="this.itemdisabled"
      v-bind:PatientCode="this.PatientCode"
    />
    <IncidentWorkflowRightBar
      v-if="this.btnIncidentWorkflowvisible"
      v-bind:ItemList="this.ItemListIncidentWorkflow"
      v-bind:InfoPatient="this.InfoPatient"
      v-bind:itemdisabled="this.itemdisabled"
      v-bind:PatientCode="this.PatientCode"
    />

      <!-- RIGHT MENU END -->
    </div>
    <div id="m-leftmenu" class="m-layout m-leftmenu" @click="closeMenuClick()">
      <!-- LEFT MENU START -->
      <div id="leftLoader">
        <span><div class="ll01 "></div></span>
        <span><div class="ll02"></div></span>
        <span><div class="ll03"></div></span>
      </div>
      <div id="searchMenu"><HeaderLeftMenu /></div>
      <div id="leftMenu" class="w-100 h-100">
        <MdMenu @getActionFromItems="actionFromMenuItems($event)" />
      </div>
      <!-- LEFT MENU END -->
    </div>
    <div id="m-navbar" class="m-layout m-navbar" @click="closeMenuClick()">
      <!-- BOTTOM NAVBAR START -->
      <b-row class="w-100">
        <b-col cols="6">
           <b-dropdown
            id="startMenuButton"
            :html="trademark()"
            variant="primary"
            align-h="start"
            size="sm"
            no-caret
            class="mr-3"
            @hide="beforeClose"
            @toggle="openMenuClick()"
          >
          <b-dropdown-header id="dropdown-header-label" align-v="center">
              <span class="text-primary"
                ><strong>{{ Name }}</strong></span
              >
          </b-dropdown-header>

          <ejs-menu style="box-shadow: none !important;" ref="menu" id="menu" :items='menuItems' orientation='Vertical' :beforeOpen='onBeforeOpen' :select='select'></ejs-menu>
             
          </b-dropdown>
            
          <b-button-group v-for="Sub in optionsButton" :key="Sub.id" id="favMenuItems" size="sm" class="hideMobile">
            <b-button
              variant="outline-dark"
              v-b-tooltip.hover
              :title= Sub.title
              v-on:click="launchPage(Sub.title, Sub.url, Sub.routerName)"
            >
              <i :class="Sub.icon" class="center-icons" aria-hidden="true"></i>
            </b-button>
            <!-- <b-button
              variant="outline-dark"
              v-b-tooltip.hover
              title="Clinical Dashboard"
              v-on:click="launchPage('Clinical Dashboard', '/app/clinical', 'Clinical')"
            >
              <i class="fa fa-th" aria-hidden="true"></i>
            </b-button> -->
          </b-button-group>
        </b-col>
        <b-col cols="6" class="text-right">
          <b-button-group id="toogleMenuButtons" size="sm">
            <b-button
              v-b-tooltip.hover
              :title="leftButtonTitle"
              id="leftMenuButton"
              variant="outline-dark"
              v-on:click="updateLayoutLeft()"
              ><b-icon
                id="leftMenuIcon"
                :icon="leftIconName"
                aria-hidden="true"
              ></b-icon
            ></b-button>
            <b-button
              v-if="btnvisible || btnGroupNotesvisible || btnIncidentWorkflowvisible"
              id="bothMenuButton"
              variant="outline-dark"
              v-on:click="updateLayoutBoth()"
              ><b-icon
                id="bothMenuIcon"
                :icon="middleIconName"
                aria-hidden="true"
              ></b-icon
            ></b-button>
            <b-button
              v-if="btnvisible || btnGroupNotesvisible || btnIncidentWorkflowvisible"
              id="rightMenuButton"
              variant="outline-dark"
              v-on:click="updateLayoutRight()"
              ><b-icon
                id="rightMenuIcon"
                :icon="rightIconName"
                aria-hidden="true"
              ></b-icon
            ></b-button>

            <!-- <b-tooltip variant="outline-dark" target="leftMenuButton" triggers="hover" :title="leftButtonTitle"></b-tooltip> -->
            <b-tooltip
              v-if="btnvisible || btnGroupNotesvisible || btnIncidentWorkflowvisible"
              variant="outline-dark"
              target="bothMenuButton"
              triggers="hover"
              :title="bothButtonTitle"
              style="z-index:0"
            ></b-tooltip>
            <b-tooltip
              v-if="btnvisible || btnGroupNotesvisible || btnIncidentWorkflowvisible"
              variant="outline-dark"
              target="rightMenuButton"
              triggers="hover"
              :title="rightButtonTitle"
              style="z-index:0"
            ></b-tooltip>
          </b-button-group>
          <!-- :title="mDateNow" -->
          <b-badge
            id="mClock"
            variant="dark"
            v-b-tooltip.hover
            class="ml-3 hideMobile d-none"
            v-on:click="changeTimeFormat()"
            >{{ mTimeNow }}</b-badge
          >
          <b-badge
            id="mLogOut"
            variant="success"
            v-b-tooltip.hover
            :title="logoutbtn"
            class="ml-1 hideMobile d-none"
            v-on:click="logout()"
            ><i class="ikon med-logout"></i></b-badge>
        </b-col>
      </b-row>
      <!-- SPEECH BAR -->
      <div id="speechBar" class="speechBar">
        <button id="stopSpeech" v-on:click="stopSpeechOrSpeechToText()">
          <i class="ikon med-stop"></i> <span id="speechBTNtitle">{{ speechBarLabel }}</span>
        </button>
      </div>
      <!-- SPEECH BAR END -->
      <!-- VERTICAL WARNING -->
      <!--
      <div class="verticalWarning">
        <i class="ikon med-isologo"></i> best in landscape mode. please, rotate your device.
      </div>
      -->
      <!-- VERTICAL WARNING END -->
      <!-- BOTTOM NAVBAR END -->
    </div>
    <Loading v-bind:IsLoading="this.Loading" />
    <!-- SCREENSAVER START -->
    <!-- https://codepen.io/sjrh/pen/NNBjKy -->
    <div id="screensaver" v-bind:class="this.screensaver_active ? 'background' : 'background hidden'">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <div class="mainlogo sfx"></div>
      <div class="vignette"></div>
    </div>
    <!-- SCREENSAVER END -->
    <!-- REVIEW BANNER START -->
    <a href="https://search.google.com/local/writereview?placeid=ChIJNUsu_EKo2YgRWh4LKOzQWF4" target="_blank">
      <div class="googleReviewBanner" id="googleReviewBanner">
        <div id="gRMessage">{{ Name }}, {{$t('Layout.InfoText')}}</div>
      </div>
    </a>
    <!-- REVIEW BANNER END -->
  </div>
</template>

<script>
import RigthBar from "@/components/template/rigthbar/RigthBar";
import GroupNotesRigthBar from "@/components/pages/groupnotes/GroupNotesRightBar";
import Loading from "@/components/template/loading/Loading";
import Quickhelp from "@/services/quickhelp.service";
import Utils from "../../../common/utils";
import ChangePassWord from "@/components/pages/profile/ChangePassWord";
import UserService from "@/services/user.service";
import ProviderService from "@/services/provider.service";
import WhatsNew from "@/components/pages/whatsnew/whatsNew";
import StorageService from "@/services/storage.service";
import ShowMessage from "@/components/messages/ShowMessage.js";
import Preferences from "@/components/pages/preferences/userPreferences";
import UsersPreferences from '@/services/userpreferences.service'
import { EventBus } from '@/event-bus.js';
import $ from "jquery";
import Vue from 'vue';
import { MenuPlugin } from "@syncfusion/ej2-vue-navigations";
import { enableRipple, closest } from '@syncfusion/ej2-base';

enableRipple(true);
Vue.use(MenuPlugin);
export default {
  name: "Layout",
  mixins: [ShowMessage],
  components: {
    RigthBar,
    GroupNotesRigthBar,
    ChangePassWord,
    Loading,
    WhatsNew,
    Preferences
  },
  data() {
    return {
      mousetimeout:null,
      screensaver_active:false,
      idletime:600,
      layoutStatus: "",
      mDateNow: this.$t('Layout.TodayIs'),
      mTimeNow: this.$t('Layout.WELCOME'),
      mHdiv: ":",
      m24Format: true,
      mDateFormat: 1,
      logoutbtn: this.$t('SelectProvider.LogOutTitle'),
      middleIconName: "fullscreen",
      leftIconName: "arrow-bar-left",
      rightIconName: "arrow-bar-right",

      leftButtonTitle: this.$t('Layout.HideLeftMenu'),
      rightButtonTitle: "",
      bothButtonTitle: "",
      favPatient: false,
      btnvisible: false,
      btnGroupNotesvisible:false,
      btnIncidentWorkflowvisible:false,
      Loading: false,
      ItemList: [],
      ItemListGroupNotes:[],
      ItemListIncidentWorkflow:[],
      itemdisabled: false,
      PatientCode: "",
      IsModeEdit: false,
      InfoPatient: {},
      Infolayout: {
        L0R0: "L0R0",
        L1R0: "L1R0",
        L0R1: "L0R1",
        L1R1: "L1R1",
      },
      Name: "",
      providerList: [],
      resizeObserver: null,
      changePassDialogCmp: false,
      qHelpReferenceList: [],
      optionsButton: [],
      dataArray:[],
      TimeFormatPreference: {'TimeFormat':false},
      DateFormatPreference: {'DateFormat':''},
      closeMenu: true,
      menuItems:[],
      speechBarLabel: ''
    };
  },
  created() {
    setInterval(this.getNow, 1000);
    window.addEventListener('mousemove', this.MouseMove);
    window.addEventListener("keypress", this.OnKeyPress);
    window.addEventListener("touchmove",this.MouseMove);  
    window.addEventListener("click",this.MouseMove); 
    window.addEventListener("wheel",this.MouseMove);  
    window.addEventListener("keyup",this.MouseMove);   
    window.addEventListener("keydown",this.MouseMove); 
    EventBus.$on("showTimeTaskbar", function (valid) {
      valid ? 
        $("#mClock").removeClass("d-none") : 
        $("#mClock").addClass("d-none")
     }.bind(this));

     EventBus.$on("m24Format", function (valid) {
        this.m24Format = valid;
        this.getNow();
        this.$store.commit("setClockTime", this.m24Format);
     }.bind(this));

    EventBus.$on("dateFormat", function (value) {
        this.mDateFormat = value;
        this.getNow();
        this.$store.commit("setClockDate", this.mDateFormat);
        
     }.bind(this));

     EventBus.$on("UpdateBtn", function (hide) {   
          if(!hide){           
            this.btnvisible = false;
          }
     }.bind(this));

    EventBus.$on("speechBarLabel", function (value) {   
            this.speechBarLabel = value;
     }.bind(this));
     
  },
 
  methods: {
    setProviderInMenu() {
      if (this.providerList && this.providerList.length > 1) {
        this.menuItems.splice(7,0, { id: 'provider', text: this.$t('Main.ChangeProvTitle'), iconCss: 'ikon med-change-item' })
      }
    },
    
    beforeClose(event) {
      if (this.closeMenu == false) {
        event.preventDefault()
      }
    },

    closeMenuClick() {
      this.closeMenu = true
    },

    openMenuClick() {
      this.closeMenu = !this.closeMenu
    },

    select: function(args) {
      let menuId = args.item && args.item.id ? args.item.id : null
      
      if (menuId == 'preferences')
        this.UserPreferences()
      else if (menuId == 'changepassword')
        this.OpenChangePassWord()
      else if (menuId == 'provider')
        this.GoToProvidersList()
      else if (menuId == 'homepage')
        this.launchPage('Homepage', '/app/main', 'Main')
      else if (menuId == 'logout')
        this.logout()
      else if (menuId == 'knowledgecenter' || menuId == 'knowledgecenter2')
        this.Help()
      else if (menuId == 'quickhelp' || menuId == 'quickhelp2')
        this.QuickHelp()
      
      if (menuId != 'assistance')
        this.closeMenu = true
    },

    onBeforeOpen(args) {
      let relativeOffset = closest(args.event.target, '.e-menu-item').getBoundingClientRect();
      let subMenuEle = closest(args.element, '.e-menu-wrapper');
      subMenuEle.style.display = 'block';
      args.top = (relativeOffset.top - subMenuEle.getBoundingClientRect().height) + relativeOffset.height //pageYOffset;
      args.left = relativeOffset.left + relativeOffset.width //pageXOffset;
      subMenuEle.style.display = '';
    },

    getFavData(data) {
    //  wait for implementation 
     console.log(data);
    },

    getName() {
      this.Name = Utils.strToTitleCase(StorageService.getName());
    },

    OnKeyPress(){
      document.getElementById("screensaver").focus();
      this.MouseMove();
    },

  MouseMove() {
      clearTimeout(this.mousetimeout);
      if (this.screensaver_active) {
        this.stop_screensaver();
      }
      this.mousetimeout = setTimeout(this.show_screensaver, 1000 * this.idletime);
		},

  stop_screensaver(){   
    this.screensaver_active = false;
  },

  show_screensaver(){  
    this.screensaver_active = true;   
  },  

    trademark() {
      //return "MedEZ<sup>®</sup>";
      return "<i class='ikon med-isologo'></i> "+this.$t('Layout.Start');
    },

    syncButtons(syncStore) {
      switch (syncStore) {
        case this.Infolayout.L0R0:
          this.leftIconName = "arrow-bar-right";
          this.leftButtonTitle = this.$t('Layout.ShowLeftMenu');

          this.rightIconName = "arrow-bar-left";
          this.rightButtonTitle = this.$t('Layout.ShowRightMenu');

          this.middleIconName = "fullscreen-exit";
          this.bothButtonTitle = this.$t('Layout.ShowBothMenu');

          break;
        case this.Infolayout.L1R0:
          this.leftIconName = "arrow-bar-left";
          this.leftButtonTitle = this.$t('Layout.HideLeftMenu');

          this.rightIconName = "arrow-bar-left";
          this.rightButtonTitle = this.$t('Layout.ShowRightMenu');

          this.bothButtonTitle = this.$t('Layout.HideBothMenu');
          this.middleIconName = "fullscreen";

          break;
        case this.Infolayout.L0R1:
          this.leftIconName = "arrow-bar-right";
          this.leftButtonTitle = this.$t('Layout.ShowLeftMenu');

          this.rightIconName = "arrow-bar-right";
          this.rightButtonTitle = this.$t('Layout.HideRightMenu');

          this.middleIconName = "fullscreen";
          this.bothButtonTitle = this.$t('Layout.HideBothMenu');

          break;
        case this.Infolayout.L1R1:
          this.leftIconName = "arrow-bar-left";
          this.leftButtonTitle = this.$t('Layout.HideLeftMenu');

          this.rightIconName = "arrow-bar-right";
          this.rightButtonTitle = this.$t('Layout.HideRightMenu');

          this.middleIconName = "fullscreen";
          this.bothButtonTitle = this.$t('Layout.HideBothMenu');

          break;
      }
    },

    updateLayoutLeft() {
      switch (this.layoutStatus) {
        case this.Infolayout.L0R0:
          this.layoutStatus = this.Infolayout.L1R0;
          this.syncButtons(this.layoutStatus);
          this.$store.commit("setlayoutStatus", this.layoutStatus);
          break;
        case this.Infolayout.L1R0:
          this.layoutStatus = this.Infolayout.L0R0;
          this.syncButtons(this.layoutStatus);
          this.$store.commit("setlayoutStatus", this.layoutStatus);
          break;
        case this.Infolayout.L0R1:
          this.layoutStatus = this.Infolayout.L1R1;
          this.syncButtons(this.layoutStatus);
          this.$store.commit("setlayoutStatus", this.layoutStatus);
          break;
        case this.Infolayout.L1R1:
          this.layoutStatus = this.Infolayout.L0R1;
          this.syncButtons(this.layoutStatus);
          this.$store.commit("setlayoutStatus", this.layoutStatus);
          break;
        default:
          this.layoutStatus = this.Infolayout.L1R0;
          this.$store.commit("setlayoutStatus", this.layoutStatus);
      }
    },

    updateLayoutRight() {
      switch (this.layoutStatus) {
        case this.Infolayout.L0R1:
          this.layoutStatus = this.Infolayout.L0R0;
          this.syncButtons(this.layoutStatus);
          this.$store.commit("setlayoutStatus", this.layoutStatus);
          break;
        case this.Infolayout.L0R0:
          this.layoutStatus = this.Infolayout.L0R1;
          this.syncButtons(this.layoutStatus);
          this.$store.commit("setlayoutStatus", this.layoutStatus);
          break;
        case this.Infolayout.L1R0:
          this.layoutStatus = this.Infolayout.L1R1;
          this.syncButtons(this.layoutStatus);
          this.$store.commit("setlayoutStatus", this.layoutStatus);
          break;
        case this.Infolayout.L1R1:
          this.layoutStatus = this.Infolayout.L1R0;
          this.syncButtons(this.layoutStatus);
          this.$store.commit("setlayoutStatus", this.layoutStatus);
          break;
        default:
        //  this.layoutStatus = this.Infolayout.L1R0;
      }
    },

    updateLayoutBoth() {
      switch (this.layoutStatus) {
        case this.Infolayout.L0R1:
        case this.Infolayout.L1R0:
        case this.Infolayout.L1R1:
          this.layoutStatus = this.Infolayout.L0R0;
          this.syncButtons(this.layoutStatus);
          this.$store.commit("setlayoutStatus", this.layoutStatus);
          break;
        case this.Infolayout.L0R0:
          this.layoutStatus = this.Infolayout.L1R1;
          this.syncButtons(this.layoutStatus);
          this.$store.commit("setlayoutStatus", this.layoutStatus);
          break;
        default:
        //  this.layoutStatus = this.Infolayout.L1R0;
      }
    },

    getNow() {
      const today = new Date();
      const dDay = new Array(
        this.$t('Layout.dDay.Sunday'),
        this.$t('Layout.dDay.Monday'),
        this.$t('Layout.dDay.Tuesday'),
        this.$t('Layout.dDay.Wednesday'),
        this.$t('Layout.dDay.Thursday'),
        this.$t('Layout.dDay.Friday'),
        this.$t('Layout.dDay.Saturday')
      );
      const dMonth = new Array(
        this.$t('Layout.dMonth.January'),
        this.$t('Layout.dMonth.February'),
        this.$t('Layout.dMonth.March'),
        this.$t('Layout.dMonth.April'),
        this.$t('Layout.dMonth.May'),
        this.$t('Layout.dMonth.June'),
        this.$t('Layout.dMonth.July'),
        this.$t('Layout.dMonth.August'),
        this.$t('Layout.dMonth.September'),
        this.$t('Layout.dMonth.October'),
        this.$t('Layout.dMonth.November'),
        this.$t('Layout.dMonth.December')
      );
      let Hr = today.getHours();
      let Mn = today.getMinutes();
      let Dw = today.getDay();
      let My = today.getMonth();
      let Th = "th";
      let Tt = "";
      switch (today.getDate()) {
        case 1:
        case 21:
        case 31:
          Th = "st";
          break;
        case 2:
        case 22:
          Th = "nd";
          break;
        case 3:
        case 23:
          Th = "rd";
          break;
      }
      if (this.mHdiv == " ") {
        this.mHdiv = ":";
      } else {
        this.mHdiv = " ";
      }
      if (Hr > 12) {
        Hr = Hr - 12;
      }
      if (Hr == 0) {
        Hr = 12;
      }
      if (today.getHours() <= 11) {
        Tt = "AM";
      } else {
        Tt = "PM";
      }
      if (Mn <= 9) {
        Mn = "0" + Mn;
      }
      if (this.m24Format) {
        Hr = today.getHours();
        if (Hr <= 9) {
          Hr = "0" + Hr;
        }
        this.mTimeNow = Hr + this.mHdiv + Mn + " h";
      } else {
        this.mTimeNow = Hr + this.mHdiv + Mn + " " + Tt;
      }
      switch (parseInt(this.mDateFormat)) {
        case 1 /* Thu. Dec. 25th, 2021 */:
          this.mDateNow =
            dDay[Dw].substr(0, 3) +
            ". " +
            dMonth[My].substr(0, 3) +
            ". " +
            today.getDate() +
            Th +
            ", " +
            today.getFullYear();
          break;
        case 2 /* Thursday. Dec. 25th, 2021 */:
          this.mDateNow =
            dDay[Dw] +
            ". " +
            dMonth[My].substr(0, 3) +
            ". " +
            today.getDate() +
            Th +
            ", " +
            today.getFullYear();
          break;
        case 3 /* Thursday. December 25th, 2021 */:
          this.mDateNow =
            dDay[Dw] +
            ". " +
            dMonth[My] +
            " " +
            today.getDate() +
            Th +
            ", " +
            today.getFullYear();
          break;
        case 4 /* Thu. 12/25/2021 */:
          this.mDateNow =
            dDay[Dw].substr(0, 3) +
            ". " +
            (My + 1) +
            "/" +
            today.getDate() +
            "/" +
            today.getFullYear();
          break;
        case 5 /* Thursday. 12/25/2021 */:
          this.mDateNow =
            dDay[Dw] +
            ". " +
            (My + 1) +
            "/" +
            today.getDate() +
            "/" +
            today.getFullYear();
          break;
        case 6 /* Thursday. Dec/25/2021 */:
          this.mDateNow =
            dDay[Dw] +
            ". " +
            dMonth[My].substr(0, 3) +
            "/" +
            today.getDate() +
            "/" +
            today.getFullYear();
          break;
        case 7 /* Thursday. 2021/12/25 */:
          My++;
          if (My <= 9) {
            My = "0" + My;
          }
          this.mDateNow =
            dDay[Dw] +
            ". " +
            today.getFullYear() +
            "/" +
            My +
            "/" +
            today.getDate();
          break;
        default:
          this.mDateNow = ''
          break
      }
    },

    changeTimeFormat() {
      this.m24Format = !this.m24Format;
      this.$store.commit("setClockTime", this.m24Format);

      /** Add for sync with user preferences */
      const userid = StorageService.getUserId();
      this.TimeFormatPreference.TimeFormat = this.m24Format;
      UsersPreferences.ChangeTimeFormat(userid, this.TimeFormatPreference)
      /** End */
      this.getNow();
    },

    changeDateFormat() {
      if (this.mDateFormat <= 7) {
        this.mDateFormat = this.mDateFormat + 1;
      } else {
        this.mDateFormat = 1;
      }
      this.$store.commit("setClockDate", this.mDateFormat);

      /** Add for sync with user preferences */
      const userid = StorageService.getUserId();
      this.DateFormatPreference.DateFormat = this.mDateFormat.toString;
      UsersPreferences.ChangeDateFormat(userid, this.TimeFormatPreference)
      /** End*/

      this.getNow();
    },

    OnLoading(load) {
      this.Loading = load;
    },

    onVisibleRightMenu(RightMenu) {
      this.btnvisible = RightMenu.btnvisible;     
      this.btnGroupNotesvisible = RightMenu.btnGroupNotesvisible;
      this.btnIncidentWorkflowvisible = RightMenu.btnIncidentWorkflowvisible;
      this.ItemList = RightMenu.ItemsList; 
      this.itemdisabled = RightMenu.itemdisabled;
      this.PatientCode = RightMenu.PatientCode;
      this.favButtonAtion = RightMenu.favButtonAtion;

      this.$nextTick(() => {
        this.loadLayoutStore();
      });
    },

    onMenuRightBarGroupNotes(RightMenu){
     // this.btnvisible = RightMenu.btnvisible;
     this.$store.commit('setItemListGroupNotes',RightMenu.ItemsList);
      this.btnGroupNotesvisible = RightMenu.btnGroupNotesvisible;      
     // this.ItemListGroupNotes = RightMenu.ItemsList;
      this.ItemListGroupNotes = this.$store.state.itemListGroupNotes; 
      this.itemdisabled = RightMenu.itemdisabled;
      this.PatientCode = RightMenu.PatientCode;
      this.favButtonAtion = RightMenu.favButtonAtion;     
      this.$nextTick(() => {
        this.loadLayoutStore();
      });
    },

    onMenuRightBarIncidentWorkflow(RightMenu) {
      this.btnGroupNotesvisible = RightMenu.btnGroupNotesvisible;
      this.btnIncidentWorkflowvisible = RightMenu.btnIncidentWorkflowvisible;
      this.ItemListIncidentWorkflow = RightMenu.ItemsList; 
      this.itemdisabled = RightMenu.itemdisabled;
      this.PatientCode = RightMenu.PatientCode;
      this.favButtonAtion = RightMenu.favButtonAtion;

      this.$nextTick(() => {
        this.loadLayoutStore();
      });
    },

    actionFromMenuItems(item) {
      if (item && item.child.length == 0) {
        this.$store.commit("setlayoutStatus", "L0R0");
        this.layoutStatus = this.$store.state.layoutStatus;
        this.syncButtons(this.$store.state.layoutStatus);
      }
    },

    OnInfoPatient(Info) {
      this.InfoPatient = Info;
    },

    loadLayoutStore() {
      const isMovile = this.$store.state.isMovile;
      if (isMovile || this.favButtonAtion) {
        this.$store.commit("setlayoutStatus", "L0R0");
        this.layoutStatus = this.$store.state.layoutStatus;
      } else if (this.$store.state.layoutStatus && this.btnvisible) {
        this.layoutStatus = this.$store.state.layoutStatus;
      }else if (this.$store.state.layoutStatus && this.btnGroupNotesvisible) {
        this.layoutStatus = this.$store.state.layoutStatus;
      }else if (this.$store.state.layoutStatus && this.btnIncidentWorkflowvisible) {
        this.layoutStatus = this.$store.state.layoutStatus;
      }
       else {
        this.$store.commit("setlayoutStatus", this.Infolayout.L1R0);
        this.layoutStatus = this.$store.state.layoutStatus;
      }
      this.syncButtons(this.$store.state.layoutStatus);
    },

    launchPage(title, url, page) {
        if (this.$router.currentRoute.name == page ) {
          this.$bvToast.toast(
            this.$t('Layout.displayedScreen'),
            {
              title: title,
              autoHideDelay: 4000,
              variant: "success",
            }
          );
        } else {
          this.$router.push(url).catch(() => {});
        }
        EventBus.$emit('onUpdatedMenu', page);
    },

    OpenChangePassWord() {
      this.changePassDialogCmp = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        setTimeout(() => this.$bvModal.show("bv-modal-ChangePassWord"), 500);
      });
    },

    logout() {
      this.$bvModal
        .msgBoxConfirm(this.$t('SelectProvider.CloseSessionMsg'), {
          id: "modal_logout",
          title: this.$t('SelectProvider.LogOutTitle'),
          okTitle: this.$t('Login.LogOut'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: "p-2",
          titleClass: "modal-title modal-type-warning",
        })
        .then((value) => {
          if (value) {
            UserService.logout()
              .then((response) => {
                if (response.status == "200") {
                  this.$store.commit("setAccessToken", "");
                  localStorage.removeItem("vuex");
                  localStorage.removeItem("_secure__ls__metadata");
                  this.$router.push("/login");
                }
              })
              .catch((error) => {
                  this.$store.commit("setAccessToken", "");
                  localStorage.removeItem("vuex");
                  localStorage.removeItem("_secure__ls__metadata");
                  this.$router.push("/login");
                if (!error.response) {
                  this.showMessage(
                    this.$t('Layout.LoggingOut'),
                    this.$t('SelectProvider.NoAbleToLogOutMsg'),
                    "error"
                  );
                } else {
                  this.showMessage(this.$t('Layout.LoggingOut'), error, "error");
                }
              });
          }
        });
    },

    GoToProvidersList() {
      this.$bvModal
        .msgBoxConfirm(this.$t('Main.ChangeProvMsg'), {
          title: this.$t('Main.ChangeProvTitle'),
          okTitle: this.$t('Main.CHANGE'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: "p-2",
          titleClass: "modal-title modal-type-warning",
        })
        .then((value) => {
          if (value) {
            this.$router.push("/provider").catch(() => {});
          }
        });
    },

    async getProviders() {
      const userid = StorageService.getUserId();
      await ProviderService.getAll(userid)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.providerList = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$t('SelectProvider.Providers'), error, "error");
          }
        });
    },

    getUserQhReference() {
      const userid = StorageService.getUserId();
      Quickhelp.getQhUserPreference(userid)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const doNotShowQuickHelp = response.data[0].doNotShowQuickHelp;
            if (!doNotShowQuickHelp) {
              this.$nextTick(() => {
                setTimeout(() => this.$bvModal.show("bv-modal-whatsnew"), 3000);
              });
            }
          } else {
            this.$nextTick(() => {
              setTimeout(() => this.$bvModal.show("bv-modal-whatsnew"), 3000);
            });
          }
        })
        .catch((error) => {
          if (error.errorMessage) {
            this.showMessage(this.$t('UserPreferences.UPTitle'), error, "error");
          }
        });
    },
    QuickHelp() {
      this.$bvModal.show("bv-modal-whatsnew");
    },

    Help(){
      window.open("/docs", '_blank');
    },

    UserPreferences() {
      this.$bvModal.show("bv-modal-preferences");
    },
    onResize() {
      if (this.$refs.mlayout != undefined) {
        this.$emit("resize", this.$refs.mlayout.offsetWidth);
        this.$store.commit("setMovile", false);
        if (this.$refs.mlayout.offsetWidth <= 767) {
          this.$store.commit("setMovile", true);
        }
      }
    },

    getUserPreference() {
      const userid = StorageService.getUserId();
      UsersPreferences.getUserPreference(userid)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            response.data[0].showTimeTaskbar ?  
              $("#mClock").removeClass("d-none") : 
              $("#mClock").addClass("d-none")
          }
        })
        .catch((error) => {
          if (error.errorMessage) {
            this.showMessage(this.$t('UserPreferences.UPTitle'), error, "error");
          }
        });
    },

    /* REVIEW BANNER START */
    // --8<--------remove this comment------------
    // PLEASE, CHECK THIS OUT!!!
    /*  The idea was to generate a random number
        and show the banner only if a number was hit.
        Obviously, you can change this to something
        less random. And take it from JS to Vue :)
        At the end, all you need is to set the
        animate class to the div to show and hide it.
    */
   // --8<--------remove this comment------------
    showReview(){
        const randomNumber = Math.floor(Math.random() * 501);
        if (randomNumber == 100) {
          let gRB = document.getElementById('googleReviewBanner');
          gRB.classList.add('animate');
        }
    },

    returnReactiveStartMenu(){
      this.menuItems = [
        { id: 'quickhelp', text: this.$t('Layout.QuickHelp'), iconCss: 'ikon med-help' },
        //{ id: 'knowledgecenter', text: this.$t('Layout.KnowCenter'), iconCss: 'ikon med-help' },
        //{ id: 'customersupport', text: this.$t('Layout.CusSupport'), iconCss: 'ikon med-help' },
        { id: 'assistance',text: this.$t('Layout.Assistance'), iconCss: 'ikon med-help',
              items: [
                { id: 'quickhelp2', text: this.$t('Layout.QuickHelp'), iconCss: 'ikon med-help' },
                //{ id: 'knowledgecenter2', text: this.$t('Layout.KnowCenter'), iconCss: 'ikon med-help' },
                //{ id: 'customersupport2', text: this.$t('Layout.CusSupport'), iconCss: 'ikon med-help' },
              ]
        },
        { separator: true },
        { id: 'homepage', text: this.$t('Layout.Homepage'), iconCss: 'ikon med-homepage' },
        { separator: true },
        { id: 'changepassword', text: this.$t('Layout.ChangePassword'), iconCss: 'ikon med-change-pass' },
        //{ separator: true },
        //{ id: 'preferences', text: this.$t('UserPreferences.PrefTitle'), iconCss: 'ikon med-preferences' },
        { separator: true },
        { id: 'logout', text: this.$t('SelectProvider.LogOutTitle'), iconCss: 'ikon med-logout' },
      ]
    },
    returnReactiveShortCutItems(){
      this.optionsButton = [
        {id: 1, title: this.$t('menu.Patient Dashboard'), url: "/app/patients", routerName:"patients-list", icon:'ikon med-dashboard' },
        {id: 2, title: this.$t('Clinical.ClinicalDashboard'), url: "/app/clinical", routerName:"Clinical", icon:'ikon med-dashboard' },
        //{id: 3, title: this.$t('schedulerDB.ScheDashboard'), url: "/app/appointments", routerName:"Scheduler", icon:'ikon med-dashboard' },
      ]
    },
    stopSpeechOrSpeechToText() {
      this.speechBarLabel == 'stop recording'
              ? this.$root.$refs.MdTextSpeech.stopSpeechToText()
              : this.$root.$refs.MdTextSpeech.stopSpeech();
    },
  },
   
  async mounted() {
    // this.showReview();
    /* REVIEW BANNER END */
    this.ItemListGroupNotes = this.$store.state.itemListGroupNotes;
    this.ItemListIncidentWorkflow = [] 
    await this.getProviders();
    this.getName();
    this.loadLayoutStore();

    this.resizeObserver = new ResizeObserver(this.onResize).observe(
      this.$refs.mlayout
    );    
    this.m24Format = this.$store.state.m24Format;
    this.mDateFormat = this.$store.state.mDateFormat;

    this.getUserQhReference();
    this.getUserPreference();
    this.returnReactiveStartMenu();
    this.returnReactiveShortCutItems();
    this.$nextTick(() => {
        this.setProviderInMenu();
    });
    
  },

  updated() {
    this.getName();
   },
};
/*.
   <link rel="stylesheet" href="@/assets/medez/demo-files/demo.css">
   <link rel="stylesheet" href="@/assets/medez/style.css">

*/
</script>

<style>

@import "../../../assets/medez/ikons.css";
@import "../../../assets/css/mdi/css/materialdesignicons.min.css";
@import "../../../assets/css/font-awesome/css/font-awesome.min.css";
@import "../../../assets/css/simple-line-icons/css/simple-line-icons.css";
@import "../../../assets/css/style.css";
@import "../../../assets/css/layout.css";

</style>
