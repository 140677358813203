<template>
    <div>
        <MdTitle v-bind:Text="'Department of Children Services Export'"/>
      <vue-good-wizard 
        :steps="steps"
        :finalStepLabel="'Export'"
        :onNext="nextClicked" 
        :onBack="backClicked">
        <div slot="filter">
       <b-row>
        <b-col lg="3" md="3" sm="12">         
            <MdSelect
            v-bind:Label="'Month'"
            v-bind:Options="MonthList"
            v-bind:Model="MonthModel"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Multiple="false"             
            :Input="ChangeDropDownMonth"/>
          </b-col>

          <b-col lg="3" md="3" sm="12">
            <MdEdit 
                v-bind:Label="'Year'"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'Year'"
                v-model="YearModel"               
            >                                     
            </MdEdit>
          </b-col>

          <b-col lg="3" md="3" sm="12">         
            <MdSelect
            v-bind:Label="'Mode'"
            v-bind:Options="ModeList"
            v-bind:Model="ModeModel"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:AllowEmpty="false"
            v-bind:Multiple="false"             
            :Input="ChangeDropDownMode"/>
          </b-col>

          <b-col lg="3" md="3" sm="12">         
            <MdSelect
            v-bind:Label="'Sort Options'"
            v-bind:Options="SortList"
            v-bind:Model="SortModel"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Multiple="false"             
            :Input="ChangeDropDownSort"/>
          </b-col>
       </b-row>
        </div>
        <div slot="general">
         <b-row>
            <b-col lg="6" md="6" sm="12"> 
                <MdTitle v-bind:Text="'Notes'" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                <MdCheckListBox2 
                  v-model="NotesModel"
                  v-bind:Options="NotesList"
                  v-bind:OnChangeProp="OnChangeNotes"
                  v-bind:Height="'300px'"
                  v-bind:columnCount="1"
                  :textField="'name'"
                  :valueField="'code'"/> 
              </b-col>    
              <b-col lg="6" md="6" sm="12"> 
                <MdTitle v-bind:Text="'Tables'" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                <MdCheckListBox2 
                  v-model="TablesModel"
                  v-bind:Options="TablesList"
                  v-bind:OnChangeProp="OnChangeTable"
                  v-bind:Height="'300px'"
                  v-bind:columnCount="1"
                  :textField="'name'"
                  :valueField="'code'"/> 
              </b-col>   
         </b-row>
        </div>
        <div slot="production">
            <MdTablePaginationClient
            v-bind:fields="fields"           
            v-bind:items="items"
            v-bind:totalRows="totalRows"  
            v-bind:refTable="'refTableProduction'"           
            v-bind:showMessageIsEmpty="true"
            v-bind:currentPage="currentPage"
            v-bind:perPage="perPage"
            v-bind:SelectAll="false"
            v-bind:ShowDetails="true"          
            v-bind:pageOptions="pageOptions"           
            v-bind:Error="Error" 
            :viewIconEvent="ViewIcon"       
            :onRowSelected="onRowSelected" 
            :onFiltered="onFiltered"
            :pageChanged="pageChanged"
            :RowClick="RowClicked"
            :perPageFunction="PerPageFunction">
          </MdTablePaginationClient>
        </div>
        <div slot="review">
           <MdTitle v-bind:Text="'REVIEW ERRORS'" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>

            <MdTablePaginationClient
            v-bind:fields="fields"           
            v-bind:items="items"
            v-bind:totalRows="totalRows"  
            v-bind:refTable="'refTableReview'"           
            v-bind:showMessageIsEmpty="true"
            v-bind:currentPage="currentPage"
            v-bind:perPage="perPage"
            v-bind:SelectAll="false"
            v-bind:ShowDetails="true"          
            v-bind:pageOptions="pageOptions"           
            v-bind:Error="Error" 
            :viewIconEvent="ViewIcon"       
            :onRowSelected="onRowSelected" 
            :onFiltered="onFiltered"
            :pageChanged="pageChanged"
            :RowClick="RowClicked"
            :perPageFunction="PerPageFunction">
          </MdTablePaginationClient>
        </div>
        <div slot="summary">
          <b-row>
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card activeCard">
                    <div class="card-body">
                        <div class="d-flex align-items-start justify-content-between">
                        <h6 class="card-title flex-grow lower-line text-truncate">Export Summary</h6>
                        </div>                       
                        <div class="flex-container">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="float-left col text-muted p-item">
                                        <small>Month</small><br>
                                        <b>December</b>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="float-left col text-muted p-item">
                                        <small>Year</small><br>
                                        <b>2023</b>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="float-left col text-muted p-item"> 
                                        <small>Version</small><br>
                                        <b>1</b>
                                        </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="float-left col text-muted p-item"> 
                                        <small>Total Charges</small><br>
                                        <b>$450.00</b>
                                        </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="float-left col text-muted p-item">
                                        <small>Transactions</small><br>
                                        <b>100</b>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="float-left col text-muted p-item">
                                        <small>Group Creation ID</small><br>
                                        <b>433345675132895 </b>
                                    </div>
                                </div>                                                             						
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
          </b-row>
          <MdButton
          v-bind:Label="'Download DCS File'"
          v-bind:ClassProp="'secondary mx-2 btn-login'"
          v-on:click="DownloadDCS"
      />
        </div>
      </vue-good-wizard>
      <div class="space-actions"></div>
      <div class="page-actions">
          <MdButton
          v-bind:Label="$t('Shared.Back')"
          v-bind:ClassProp="'secondary mx-2 btn-login'"
          v-on:click="GotoDcsList"
      />
     
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DCS',
    data(){
      return {
        MonthModel:"",
        ModeModel:{name:"Review",code:1},
        SortModel:"",
        YearModel:"",
        NotesModel:[],
        TablesModel:[],
        perPage: 10,
        currentPage: 1,
        items:[
            {
                patientName:"Test Patient",
                patientcode:"1800003",
                insurance:"DCS",
                noteName:"DCS Monthly Report HBC",
                notetable:"DCS Service Details",
                therapyInfo:"10521.331 - HB Casework per hour – face to face",
                authorizationInfo:"",
                authorizationStatus:"",

                errorList:[{name:"test",code:1}]
            },
            {
                patientName:"Test Patient",
                patientcode:"1800003",
                insurance:"DCS",
                noteName:"DCS Monthly Report HBC",
                notetable:"DCS Service Details",
                therapyInfo:"10948.11793 - CMHI-Counseling-Individual Per Hour",
                authorizationInfo:"",
                authorizationStatus:"",

                errorList:[{name:"test",code:1}]
            }
    ],
        fields:[
            { key: 'patientName', sortable: true, label: 'Patient Name', class: 'text-rigth' },  
            { key: 'patientcode', sortable: true, label: 'Patient Code', class: 'text-rigth' },  
            { key: 'insurance', sortable: true, label: 'Insurance', class: 'text-rigth' },  
            { key: 'noteName', sortable: true, label: 'Note', class: 'text-rigth' },  
            { key: 'notetable', sortable: true, label: 'Note Table', class: 'text-rigth' },  
            { key: 'therapyInfo', sortable: true, label: 'Therapy Info', class: 'text-rigth' },
            { key: 'authorizationinfo', sortable: true, label: 'Authorization Info', class: 'text-rigth' },  
            { key: 'authorizationstatus', sortable: true, label: 'Authorization Status', class: 'text-rigth' }  
        ],
        SelectedPage : 0,
        totalRows: 2,
        MonthList:[
            {name:"January",code:1},
            {name:"February",code:2},
            {name:"March",code:3},
            {name:"April",code:4},
            {name:"May",code:5},
            {name:"June",code:6},
            {name:"July",code:7},
            {name:"August",code:8},
            {name:"September",code:9},
            {name:"October",code:10},
            {name:"November",code:11},
            {name:"December",code:12},
        ],
        ModeList:[
            {name:"Review",code:1},
            {name:"Production",code:2}
        ],
        SortList:[
            {name:"TherapyCode, PatientCode, ServiceDate",code:1},
            {name:"PatientCode, ServiceDate, TherapyCode",code:2}
        ],
        NotesList:[{name:"DCS Monthly Report HBC",code:1},
                   {name:"DSC Monthly Report HBT",code:2}
        ],
        TablesList:[
            {name:"47462 - DCS Service Details", code:1},
            {name:"47535 - DCS Service Details", code:2}
        ],
        pageOptions: [5, 10, 15 ],
        steps: [
          {
            label: 'Filters',
            slot: 'filter',
          },
          {
            label: 'DCS Items',
            slot: 'general',
          },
          {
            label: 'Review Errors',
            slot: 'review',
          },
          {
            label: 'Export Summary',
            slot: 'summary',
          }
          /*,
          {
            label: 'Production Errors',
            slot: 'production',
          },*/
          
        ],
      };
    },
    methods: {
      nextClicked(currentPage) {
        console.log('next clicked', currentPage)
        return true; //return false if you want to prevent moving to next page
      },
      backClicked(currentPage) {
        console.log('back clicked', currentPage);
        return true; //return false if you want to prevent moving to previous page
      },
      ChangeDropDownMonth(){

      },
      onFiltered(){

      },

      OnChangeNotes(){

      },

      OnChangeTable(){

      },

      onRowSelected(){

      },

      pageChanged(){

      },

      Error(){

      },

      RowClicked(){

      },

      PerPageFunction(){

      },

      ViewIcon(){

      },

      DownloadDCS(){
        alert("Downloading File");
      },

      ChangeDropDownMode(mode){
        if(mode){
           this.ModeModel = mode;
        }

        if(this.ModeModel.code === 2){
           // this.steps.pop();
           this.steps.splice(2, 1);
           this.steps.splice(2, 0, {label:"Production Errors",slot:"production"})
           // this.steps.push({label:"Production Errors",slot:"production"});
        }
        if(this.ModeModel.code === 1){
            //this.steps.pop();
            this.steps.splice(2, 1);
           // this.steps.push({label:"Review Errors",slot:"review"});
            this.steps.splice(2, 0, {label:"Review Errors",slot:"review"})
        }
      },
      ChangeDropDownSort(){

      },
      GotoDcsList(){
        this.$router.push({name: "dcs-list"});
      }
    },
  };
  </script>