<template>
   <sidebar-menu 
   :relative=false 
   :theme="menuTheme" 
   :hideToggle=true 
   :collapsed="collapsedProp" 
   width="250px" 
   :menu="menu"  
   @toggle-collapse="onToggleCollapse"
   :showOneChild=true
   @item-click="onItemClick"
   />       
</template>

<script>
import MenuService from '@/services/menu.service'
import { EventBus } from '../../../event-bus.js';
import DBFunctions from '@/common/DBFunctions.js'
import $ from "jquery";

//var s;
//EventBus.$on('onFilterMenu', s);



    export default {
      props: {
        Collapsed: Boolean,      
      },     
      name:'MdMenu',
        mixins: [EventBus,DBFunctions],
      computed: {
      collapsedProp: {
        get() { return this.Collapsed}
        }
      
      },
      data() {
          return {  
              OptionsRightMenu:{
                btnvisible:true,
              },         
              menuTheme:'white-theme',
              collap: this.Collapsed,
              menu: [],
              filter:'',
              CopyMenu:[],
              countPD:0,
              countCD:0,
              countSD:0,
              countCL:0,
              countRP:0,
              countIS:0,
              countDS:0,
              countUS:0,
              countRL:0, // Rol List
              countCR:0,// Calendar Resources
              countAT:0,// Appoinment Type
              countBL:0,// Building List
              countRoomL:0,//Room List
              countEL:0,// Employee List
              countEC:0,// Employee Credentials
              countET:0,// Employee List
              countBC:0,// Setup Billing CPT & HCPCS & Revenue - hcpcscpt
              countBM:0,// Setup Billing Modifiers
              countBP:0,// Setup Billing Place of Service
              countBV:0,// Setup Billing Value Code
              countCN:0,// Report Clinical Notes
              countBCreateC:0, // Billing Create Clearing
              countBManageC:0, // Billing Manage Claim
              countDro:0, // Drug
              countIR:0,
              countIWFD:0,
              countGN:0,
              countRBi:0,
              countRNO:0,
              countRPA:0,
              countRPBI:0,

              prevRoute: null,
              message: '',
              arr:[
    {
        "type": "header",
        "code": "442373854011698",
        "header": false,
        "hiddenOnCollapse": false,
        "title": "Dashboards",
        "href": null,
        "icon": "med-isologo",
        "child": [
            {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Patient Dashboard",
                "href": "/app/patients",
                "icon": null,
                "child": []
            },
            {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Clinical Dashboard",
                "href": "/app/clinical",
                "icon": null,
                "child": []
            }            
        ]
    } , 
    {
        "type": "header",
        "code": "442373854011698",
        "header": false,
        "hiddenOnCollapse": false,
        "title": "Reports",
        "href": null,
        "icon": "mdi mdi-arrow-right-drop-circle",
        "child": [
            {
                "type": "category",
                "code": "442373856283458",
                "header": true,
                "hiddenOnCollapse": false,
                "title": "Financial",
                "href": null,
                "icon": null,
                "child": null
            },
            {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Patient",
                "href": null,
                "icon": null,
                "child": [
                              {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Patient Census",
                                "href": null,
                                "icon": null,
                                "child": null
                              },
                             {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Patient Admissions",
                                "href": null,
                                "icon": null,
                                "child": null
                              },                              
                ]
            },
            {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Employee",
                "href": null,
                "icon": null,
                "child": [
                              {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Employee Census",
                                "href": null,
                                "icon": null,
                                "child": null
                              },
                             {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Employee Admissions",
                                "href": null,
                                "icon": null,
                                "child": null
                              },                              
                ]
            },
           {
                "type": "category",
                "code": "442373856283458",
                "header": true,
                "hiddenOnCollapse": false,
                "title": "Statisticals",
                "href": null,
                "icon": null,
                "child": null
            },
            {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Patient Stats",
                "href": null,
                "icon": null,
                "child": [
                              {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Patient Census Stats",
                                "href": null,
                                "icon": null,
                                "child": null
                              },
                             {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Patient Admissions Stats",
                                "href": null,
                                "icon": null,
                                "child": null
                              },                              
                ]
            },
            {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Employee Stats",
                "href": null,
                "icon": null,
                "child": [
                              {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Employee Census Stats",
                                "href": null,
                                "icon": null,
                                "child": null
                              },
                             {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Employee Admissions Stats",
                                "href": null,
                                "icon": null,
                                "child": null
                              },                              
                ]
            }, 
            {
                "type": "category",
                "code": "442373856283458",
                "header": true,
                "hiddenOnCollapse": false,
                "title": "OTHERS",
                "href": null,
                "icon": null,
                "child": null
            },                                   
             {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Discharge Report",
                "href": null,
                "icon": null,
                "child": []
            },
             {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Scheduler Report",
                "href": null,
                "icon": null,
                "child": []
            },            
        ]
    }    
]
          }
      },
      methods:{
        onToggleCollapse (collapsed) { 
          this.Collapsed = collapsed
        },

       FilterMenu(e){
         let result = [];
          if(e !== ''){
            this.doFilter(this.CopyMenu, e, result);
            this.menu = result;
          } else {
            this.menu = this.CopyMenu;
            this.CleanIcon(this.CopyMenu, e, result);
          }          
        },       

      RemoveChilds(array){
        for(var i = 0; i<array.length; i++){
          if(array[i].child.length === 0){
            delete array[i].child;
          }
        }
      },

   doFilter(array, filter, result, force=false) {
          if(!array) return;
          for (let i = 0; i < array.length; i++) {
            let matches = array[i].title.toUpperCase().includes(filter.toUpperCase());
            if (array[i].type == "category") {
              force = matches;
            }
            if(array[i].icon === null){
              array[i].icon = 'ikon med-zoom';
            }
            if (force || matches) {
              let clone = {...array[i]};
              if (!force) {
                delete clone.child;
              }
              result.push(clone);
            }
            if (!force) {
              this.doFilter(array[i].child, filter, result, force);
            }
          }
       },

        CleanIcon(array, filter, result, force=false) {
          if(!array) return;
          for (let i = 0; i < array.length; i++) {
            let matches = array[i].title.toUpperCase().includes(filter.toUpperCase());
            if (array[i].type == "category") {
              force = matches;
            }            
            if(array[i].icon === 'ikon med-zoom'){
              array[i].icon = null;
            }
            if (force || matches) {
              let clone = {...array[i]};
              if (!force) {
                delete clone.child;
              }
              result.push(clone);
            }
            if (!force) {
              this.CleanIcon(array[i].child, filter, result, force);
            }
          }
       },
       AddLangMenuElem(json) {
          json.forEach((item) => {
              item.title = this.$t('menu.' + item.title);
              if (item.child && item.child.length > 0) {
                  this.AddLangMenuElem(item.child);
              }
          });
      },
        getMenu() {            
          MenuService.getMenu()
            .then((response) => { 
                  if(response.data && response.data.length > 0){                  
                    /*this.menu = this.arr; /* enable for testing menu */                   
                    this.menu = response.data; /* disable for testing menu */
                    this.AddLangMenuElem(this.menu)
                    
                    this.$store.commit('setLeftMenu',this.menu);
                     /*response.data.forEach(element => {
                      this.$store.commit('setLeftMenu',element);
                      });*/  
                    this.CopyMenu = this.menu;
                    //this.PupulateSelects();
                  }
                   document.getElementById('leftLoader').style.display = 'none';
                })
                .catch((error) => {
                   document.getElementById('leftLoader').style.display = 'none';
                  if (error.response) {
                  this.showMessage("Error loading menu",error,"error");
                  }
                });
        },
        _updated(param, pcount) {
          this.countPD = param !=='countPD' ? 0 : pcount;
          this.countCD = param !=='countCD' ? 0 : pcount;
          this.countSD = param !=='countSD' ? 0 : pcount;
          this.countCL = param !=='countCL' ? 0 : pcount;
          this.countRB = param !=='countRB' ? 0 : pcount;
          this.countIS = param !=='countIS' ? 0 : pcount;
          this.countDS = param !=='countDS' ? 0 : pcount;
          this.countRL = param !=='countRL' ? 0 : pcount;
          this.countUS = param !=='countUS' ? 0 : pcount;
          this.countCR = param !=='countCR' ? 0 : pcount;
          this.countAT = param !=='countAT' ? 0 : pcount;
          this.countBL = param !=='countBL' ? 0 : pcount;
          this.countRoomL = param !=='countRoomL' ? 0 : pcount;
          this.countEL = param !=='countEL' ? 0 : pcount;
          this.countEC = param !=='countEC' ? 0 : pcount;
          this.countET = param !=='countET' ? 0 : pcount;
          this.countBC = param !=='countBC' ? 0 : pcount;
          this.countBM = param !=='countBM' ? 0 : pcount;
          this.countBP = param !=='countBP' ? 0 : pcount;
          this.countBV = param !=='countBV' ? 0 : pcount;  
          this.countCN = param !=='countCN' ? 0 : pcount;
          this.countBCreateC = param !=='countBCreateC' ? 0 : pcount;
          this.countBManageC = param !=='countBManageC' ? 0 : pcount;
          this.countDro = param !=='countDro' ? 0 : pcount;    
          this.countIR =  param !=='countIR' ? 0 : pcount;   
          this.countIWFD = param !=='countIWFD' ? 0 : pcount;   
          this.countGN = param !=='countGN' ? 0 : pcount; 
          this.countRBi = param !=='countRBi' ? 0 : pcount; 
          this.countRPA = param !=='countRPA' ? 0 : pcount; 
          this.countRNO = param !=='countRNO' ? 0 : pcount; 
          this.countRPBI = param !=='countRPBI' ? 0 : pcount;
        },

        _checkRepeat() {
          if( this.countPD > 1 && this.$router.currentRoute.name == "patients-list") {
                this.$bvToast.toast(this.message, {
                    title: this.$t('PatientsList.dashboard'),
                    autoHideDelay: 4000,
                    variant: "success"
                  });
              } else if(  this.countCD > 1 && this.$router.currentRoute.name == "Clinical") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('Clinical.ClinicalDashboard'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              } 
              else if(  this.countSD > 1 && this.$router.currentRoute.name == "scheduler") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Scheduler'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }             
              else if(  this.countRP > 1 && this.$router.currentRoute.name == "reportbuilder") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Report Builder'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }

              else if(  this.countIR > 1 && this.$router.currentRoute.name == "incident-report") {
                  this.$bvToast.toast(this.message, {
                      title:this.$t('menu.IncidentReport'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countIWFD > 1 && this.$router.currentRoute.name == "IncidentWorkflow") {
                  this.$bvToast.toast(this.message, {
                      title:this.$t('menu.IncidentWorkflow'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if( this.countGN > 1 && this.$router.currentRoute.name == "groupnotes") {
                  this.$bvToast.toast(this.message, {
                      title:this.$t('menu.GroupNotes'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if( this.countRBi > 1 && this.$router.currentRoute.name == "reportListBilling") {
                  this.$bvToast.toast(this.message, {
                      title:this.$t('menu.ReportBilling'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }

              else if( this.countRNO > 1 && this.$router.currentRoute.name == "reportListNotes") {
                  this.$bvToast.toast(this.message, {
                      title:this.$t('menu.ReportNotes'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }

              else if( this.countRPA > 1 && this.$router.currentRoute.name == "reportListPatient") {
                  this.$bvToast.toast(this.message, {
                      title:this.$t('menu.ReportPatients'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }

              else if(this.countRPBI > 1 && this.$router.currentRoute.name == "reportListPowerBI") {
                this.$bvToast.toast(this.message, {
                      title:this.$t('menu.ReportPowerBI'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
        },

        onItemClick( event, item ) {
          this.message = this.$t('Layout.displayedScreen');
          const isMovile = this.$store.state.isMovile;
          if ( isMovile ) {
            this.$store.commit('setlayoutStatus', 'L0R0');
            this.$emit('getActionFromItems', item); 
          }
          switch (this.$router.currentRoute.name) {            
                case "patients-list":
                  this.countPD +=1;
                  this._updated('countPD', this.countPD);
                break;
                case "Clinical":
                  this.countCD +=1;
                  this._updated('countCD', this.countCD);
                break;
                case "scheduler":
                  this.countSD +=1;
                  this._updated('countSD', this.countSD);
                break;
                case "claimbatch-list":
                  this.countCL +=1;
                  this._updated('countCL', this.countCL);
                break;
                case "reportbuilder":
                  this.countRP +=1;
                  this._updated('countRP', this.countRP);
                break;
                 case "insuranceSetupList":
                  this.countIS +=1;
                  this._updated('countIS', this.countIS);
                break;
                case "calendarresourcesList":
                  this.countCR +=1;
                  this._updated('countCR', this.countCR);
                break;
                case "appointmenttypesList":
                  this.countAT +=1;
                  this._updated('countAT', this.countAT);
                break;
                case "buildingsList":
                  this.countBL +=1;
                  this._updated('countBL', this.countBL);
                break;
                case "roomsList":
                  this.countRoomL +=1;
                  this._updated('countRoomL', this.countRoomL);
                break;
                case "employeeList":
                  this.countEL +=1;
                  this._updated('countEL', this.countEL);
                break;
                case "employeecredentialsList":
                  this.countEC +=1;
                  this._updated('countEC', this.countEC);
                break;
                case "employeeTypeList":
                  this.countET +=1;
                  this._updated('countET', this.countET);
                break;
                case "hcpcscpt":
                  this.countBC +=1;
                  this._updated('countBC', this.countBC);
                break;
                case "modifier-list":
                  this.countBM +=1;
                  this._updated('countBM', this.countBM);
                break;
                case "placeofservice-List":
                  this.countBP +=1;
                  this._updated('countBP', this.countBP);
                break;
                case "valuecodesList":
                  this.countBV +=1;
                  this._updated('countBV', this.countBV);
                break;               
                case "reportClinicalNotes":
                  this.countCN +=1;
                  this._updated('countCN', this.countCN);
                break;                   
                case "createclearinghousefile-list":
                  this.countBCreateC +=1;
                  this._updated('countBCreateC', this.countBCreateC);
                break;
                case "manageclaimbatches-list":
                  this.countBManageC +=1;
                  this._updated('countBManageC', this.countBManageC);
                break;      
                case "doctorSetup":
                  this.countDS +=1;
                  this._updated('countDS', this.countDS);
                break
                case "roles":
                  this.countRL +=1;
                  this._updated('countRL', this.countRL);
                break
                case "users":
                  this.countUS +=1;
                  this._updated('countUS', this.countUS);
                break
                case "DrugList":
                  this.countDro +=1;
                  this._updated('countDro', this.countDro);
                break
                case "incident-report":
                  this.countIR +=1;
                  this._updated('countIR', this.countIR);
                break 
                case "IncidentWorkflow":
                  this.countIWFD +=1;
                  this._updated('countIWFD', this.countIWFD);
                break  
                case "groupnotes":
                  this.countGN +=1;
                  this._updated('countGN', this.countGN);
                break  
                case "reportListBilling":
                  this.countRBi +=1;
                  this._updated('countRBi', this.countRBi);
                break  
                case "reportListNotes":
                  this.countRNO +=1;
                  this._updated('countRNO', this.countRNO);
                break 
                case "reportListPatient":
                  this.countRPA +=1;
                  this._updated('countRPA', this.countRPA);
                break
                case "reportListPowerBI":
                  this.countRPBI += 1;
                  this._updated('countRPBI', this.countRPBI);
                break
                default:
                  this._updated();
            }
            if(item.type !== 'header' && item?.child !== null && item?.child?.length === 0){
              this._checkRepeat();
            }
            else if(item.child == null){
              if(  this.countIS > 1 && this.$router.currentRoute.name == "insuranceSetupList") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Insurance Companies'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              } else if(  this.countDS > 1 && this.$router.currentRoute.name == "doctorSetup") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('Shared.Doctors'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              } else if(  this.countRL > 1 && this.$router.currentRoute.name == "roles") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Roles'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              } else if(  this.countUS > 1 && this.$router.currentRoute.name == "users") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Users'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countCR > 1 && this.$router.currentRoute.name == "calendarresourcesList") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Calendar Resources'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countAT > 1 && this.$router.currentRoute.name == "appointmenttypesList") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('Shared.AppointmentType'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countBL > 1 && this.$router.currentRoute.name == "buildingsList") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Buildings'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countRoomL > 1 && this.$router.currentRoute.name == "roomsList") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('Shared.Rooms'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countEL > 1 && this.$router.currentRoute.name == "employeeList") {
                  this.$bvToast.toast(this.message, {
                      title: this.$tc('Shared.Employee'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countEC > 1 && this.$router.currentRoute.name == "employeecredentialsList") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Employee Credentials'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countET > 1 && this.$router.currentRoute.name == "employeeTypeList") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('Incident.IncPartEmplType'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countBC > 1 && this.$router.currentRoute.name == "hcpcscpt") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.CPT & HCPCS & Revenue'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countBM > 1 && this.$router.currentRoute.name == "modifier-list") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Modifiers'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countBP > 1 && this.$router.currentRoute.name == "placeofservice-List") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Place Of Services'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countBV > 1 && this.$router.currentRoute.name == "valuecodesList") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Value Codes'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }              
              else if(  this.countCN > 1 && this.$router.currentRoute.name == "reportClinicalNotes") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('rClinicalNotes.CliNotesReport'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countCL > 1 && this.$router.currentRoute.name == "claimbatch-list") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('claimbatchList.ClaimBatchSelection'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countBCreateC > 1 && this.$router.currentRoute.name == "createclearinghousefile-list") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Create Clearing House File'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countBManageC > 1 && this.$router.currentRoute.name == "manageclaimbatches-list") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('menu.Manage Claim Batches'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              else if(  this.countDro > 1 && this.$router.currentRoute.name == "DrugList") {
                  this.$bvToast.toast(this.message, {
                      title: this.$t('drug.Drug'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              }
              /**cuando se realiza la busqueda en los elementos del menu
               * Nadie ningun menu contiene child por lo tanto hay que incluir
               * los elementos que pusimos anteriormente */ 
              this._checkRepeat();
            }
        }
      },
      created: function () {
          EventBus.$on("onFilterMenu", function (e) {
              $('.vsm--item').css('color','red');
            this.FilterMenu(e);
          }.bind(this));

          /** Add buttons from taskbar */
          EventBus.$on("onUpdatedMenu", function (e) {
             switch (e) {            
                case "patients-list":
                  this.countPD +=1;
                  this._updated('countPD', this.countPD);
                break;
                case "Clinical":
                  this.countCD +=1;
                  this._updated('countCD', this.countCD);
                break;
              }
          }.bind(this));
      },
      mounted() {
        this.getMenu();
      }
    }
</script>

<style>
  @import '../../../assets/css/all.css';
 

</style>
