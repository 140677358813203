<template>
   <div id="schedulerdb">
        <div class="row">
            <div class="col-12">
              <h4 class="page-title text-truncate"> {{ $t('Appointments.Appo') }}   - 
                    <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
                    <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
                    <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
                    <span v-if="this.PatientTitle.ageFormatted"> {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
                    <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
                    <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
                    <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
                    <span v-else v-show="IsClinical">- {{ $t('Shared.Active') }}  </span>
              </h4>  
            </div> 
        </div>

        <b-row>
            <b-col md="3" lg="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('Shared.datefrom')"
                    v-bind:TypeProp="'date'" 
                    v-bind:Name="'DateFrom'"
                    v-model="DateFrom"
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-on:blur="DateFromChange($event)"
                    v-bind:Id="'input_schedulerdb_date_from'">                                     
                </MdEdit>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('Shared.dateTo')"
                    v-bind:TypeProp="'date'" 
                    v-bind:Name="'DateTo'"
                    v-model="DateTo"
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-on:blur="DateToChange($event)"
                    v-bind:Id="'input_schedulerdb_date_to'">                                     
                </MdEdit>
            </b-col>  
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                  v-bind:Label="$tc('Shared.Therapist')"
                  v-bind:Options="TherapistList"
                  v-bind:Model="TherapistVmodel"
                  v-bind:FieldDescription="'employeeName'"
                  v-bind:FieldCode="'employeeCode'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="true"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownTherapist"/>
            </b-col>
            <b-col md="3" lg="3" sm="12">
              <MdSelect
                v-bind:Label="$t('Shared.AppointmentType')"
                v-bind:Options="AppoinmentTypeList"
                v-bind:Model="AppoinmentTypeVmodel"
                v-bind:FieldDescription="'description'"
                v-bind:FieldCode="'code'"
                v-bind:Placeholder="selectPlaceholder"
                v-bind:Multiple="true"
                v-bind:RequireEvent="true"
                v-bind:IfUsevalidation="false"
                :Input="ChangeDropDownAppoinmentType"/>
            </b-col>
        </b-row>

       <!--  <b-row>
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                  v-bind:Label="$t('Shared.Show')"
                  v-bind:Options="ShowList"
                  v-bind:Model="ShowVmodel"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="true"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownShow"/>
            </b-col>
           
        </b-row> -->
        <b-row>
									<b-col md="12">
										<div class="text-center text-md-right mt-3">
											<button 
												v-on:click="SearchNotes"
												type="button"
												class="btn btn-primary btn-login mx-2 pull-right"												
												id="btnsearchinput">
												{{ $t('Shared.Search') }}
											</button>
										</div>
									</b-col>
								</b-row>
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'" class="mt-2"/>
        <MdTable
          v-bind:refTable="'AppntTable'"
          ref="AppntTable"
					v-bind:fields="fields"
					v-bind:items="items"
					v-bind:totalRows="totalRows"
					v-bind:useAddTime="true"
					v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
					v-bind:isReadOnly="false"
          v-bind:IsAppointments="true"
					v-bind:selectMode="selectMode"
					v-bind:currentPage="currentPage"
					v-bind:perPage="perPage"
					v-bind:pageOptions="pageOptions"
					v-bind:FieldsDate="FieldsDateArr"
					:onRowSelected="onRowSelected"
					:onFiltered="onFiltered"
					:pageChanged="pageChanged"
					v-bind:ShowDetails="false"
					:perPageFunction="PerPageFunction"
          :ondblClick="ondblClick"
          v-bind:EnabledRowClicked="true"
          :onRowClicked="onRowClicked">
				</MdTable>

        <div class="space-actions"></div>
        <div class="page-actions">
          <b-dropdown id="dropdown-dropup" :disabled="isReadOnly || !isSelected" dropup :text="$tc('Shared.ActionsTxt',2)" variant="primary" class="m-2">
                <b-dropdown-item href="#" v-on:click="ConfirmAppt()" >{{labelConfirm}}</b-dropdown-item>
                <b-dropdown-item href="#" v-on:click="ShowUpAppt()">{{labelShowUp}}</b-dropdown-item>
                <b-dropdown-item href="#" v-on:click="OpenAppointmentReason()">{{labelCancel}}</b-dropdown-item>             
          </b-dropdown>
        </div>

        <ModalAppointmentReason 
          v-bind:TitleWindow="$t('AppointmentDashboard.ReasonForNoShow')"  
          v-bind:ModalId="this.modalReasonId"
          v-bind:ClickedText="this.ClickedText" 
        />  
    </div>
</template>

<script>

import moment from 'moment'
import CalendarResourcesService from '@/services/calendarresources.service'
import AppoinmentTypeService from '@/services/appointmenttypes.service'
import AppointmentService from '@/services/appointment.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import Utils from '@/common/utils'
import { EventBus } from '@/event-bus.js';
import EnumService from '@/services/enum.service' 
import ChartReview from '@/services/chartreview.service.js';
import ApptCheckinsService from '@/services/appointmentcheckins.service'

export default {
  mixins: [ShowMessage, DBFunctions],
  name: 'Appointments',
  data(){
    return{
      modalReasonId:"modalIdAppointmentReason",
      ClickedText:"",
      Appointment:{},
      labelConfirm: this.Appointment && this.Appointment.isConfirmed ? 'Undo Confirm' : 'Confirm',
      labelShowUp: this.Appointment && this.Appointment.private ? 'Undo Show Up' : 'Show Up',
      labelCancel: this.Appointment && this.Appointment.reason !== null &&  this.Appointment.reason !== "" ? 'Undo Cancel': 'Cancel',
      isSelected: false,
      CheckInsAppt:{
            timeCheckIn:"",
      },
      selected: [],

      patient:{},
        parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
        Status: '',
        Programs:'',    
      },
      selectMode: "single",
      IsClinical:false,
      OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
      }, 
      fullParam: {
            PageSize:0,
            PageNumber:0
        },
    PatientTitle:{      
      programName:'',
      fullName:'',
      admitDate:'',
      dischargeDate:'',
      dob:''
    },
    RegistrationInfo:{},
    TherapistList:[],
    AppoinmentTypeList:[],
    ShowList:EnumService.AppointmentsStatus,    
    items:[],
    FieldsDateArr:['dob','startDate','endDate'],
    ShowVmodel:"",
    TherapistVmodel:"",
    AppoinmentTypeVmodel:"",
    EmployeeSelected:null,
    ApptTypeSelected:null,
    ShowSelected:null,
    ShowMessageIsEmpty:false,
    perPage: 5,
    SelectedPage : 0,
    totalRows: 0,      
    currentPage: 1,
    pageOptions: [5, 10, 20, 30, 40, 50 ],
    selectPlaceholder: this.$t('ClinicalNotes.SelectOptions'),
    DateFrom: moment(this.NewDate).format("YYYY-MM-DD"),
    DateTo: moment(this.NewDate).format("YYYY-MM-DD"),
    fields:[        
        { key: 'startDate', sortable: true, label: this.$t('Medication.StartDate'), class: 'text-rigth' }, 
        { key: 'endDate', sortable: true, label: this.$t('Medication.EndDate'), class: 'text-rigth' },
        { key: 'appointmentType', sortable: true, label: this.$t('Shared.AppointmentType'), class: 'text-rigth' },         
        { key: 'therapistName', sortable: true, label:  this.$tc('Shared.Therapist'), class: 'text-rigth' },       
    ],
    isReadOnly: false,
    Header: {},
    indexSelected: 0
    }
  },

  methods:{

    onRowClicked(item,index) { 
      this.indexSelected = index
    },
    
    ondblClick() {

    },

    SearchNotes() {
      this.SearchAppoinments();
    },

    getCheckinsByActivityId(actId){
        var param = {
            Code:actId
        }
        ApptCheckinsService.getAll(param)
              .then((response) => {
                if(response.status == '200' && response.data.length > 0){
                    this.CheckInsAppt = response.data[0];                   
                }
              })
              .catch(error => {
                if (!error.response) {               
                  this.showMessage("Appointment",this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage("Appointment",error,"error");
                }
              });
    },

    async ExecuteShowUp(param,text){
        await AppointmentService.ShowUp(param)
              .then( async (response) => {
                if(response.status == '200'){
                  this.GetPermission(this.selected[0].activityId);
                   if(text === "Undo Show Up"){ 
                        this.$bvToast.toast('The patient is not present.', {
                         title: 'Appointment',
                          autoHideDelay: 5000,
                          variant: "success"
                        });
                    }else{
                        this.$bvToast.toast('confirmed the presence of the patient.', {
                         title: 'Appointment',
                          autoHideDelay: 5000,
                          variant: "success"
                        });
                    }
                    await this.SearchAppoinments();
                    this.$refs.AppntTable.selectRow(this.indexSelected)
                }
              })
              .catch(error => {
                if (!error.response) {               
                  this.showMessage("Appointment",this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage("Appointment",error,"error");
                }
              });      
    },

    ShowUpAppt(){
        var param = {
            PatientCode : this.$route.params.id,
            ActivityId : this.selected[0].activityId,
            Action:this.labelShowUp,
            DoctorCode:this.Appointment.therapistcode,
        };

        if(this.labelShowUp === "Undo Show Up"){           
            param.State = "F";
            if( (this.CheckInsAppt.timeCheckIn === "") || (this.CheckInsAppt.timeCheckIn === null)){
                this.ExecuteShowUp(param,this.labelShowUp);
            }else{
                this.$bvToast.toast('The patient is already in the room.', {
                    title: 'Appointment',
                    autoHideDelay: 5000,
                    variant: "danger"
                });
            }            
        }
        else{
            param.State = "T";
            if( (this.Appointment.reason === "") || (this.Appointment.reason === null) ){
                this.ExecuteShowUp(param,this.labelShowUp);
            }else{
                this.$bvToast.toast('The appointment is canceled.', {
                    title: 'Appointment',
                    autoHideDelay: 5000,
                    variant: "danger"
                });
            }
        }
    },

    ConfirmAppt(){
        var param = {           
            ActivityIdList: this.selected[0].activityId         
        };

        if(this.labelConfirm === "Undo Confirm"){           
            param.IsConfirmed = "F";
        }
        else{
            param.IsConfirmed = "T";
        }

        AppointmentService.ConfirmAppt(param)
              .then(async (response) => {
                if(response.status == '200'){
                    this.GetPermission(this.selected[0].activityId);

                    if(this.labelConfirm === "Undo Confirm"){ 
                        this.$bvToast.toast('The patient has not confirmed.', {
                         title: 'Appointment',
                          autoHideDelay: 5000,
                          variant: "success"
                        });
                    }else{
                        this.$bvToast.toast('The patient has confirmed.', {
                         title: 'Appointment',
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                    }

                    await this.SearchAppoinments();
                    this.$refs.AppntTable.selectRow(this.indexSelected)
                }
              })
              .catch(error => {
                if (!error.response) {               
                  this.showMessage("Appointment",this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage("Appointment",error,"error");
                }
              });
    },

    OpenAppointmentReason(){
   
        if(this.labelCancel === "Cancel"){
            if(!this.Appointment.private){
                EventBus.$emit('onAppointmentReasonModal', this.labelCancel);   
            }else{               
                this.$bvToast.toast('The patient is present at the appointment and it cannot be canceled.', {
                    title: this.$t('MdNotesByAppt.Appointment'),
                    autoHideDelay: 5000,
                    variant: "warning"
                });
            }
           
        }else{
            this.CancelAppt(this.labelCancel,'');
        }
    },

    CancelAppt(text,reason){
        var param = {           
            ActivityIdList: this.selected[0].activityId     
        };

        if(text === "Cancel"){           
            param.Reason = reason;
        }
        else{
            param.Reason = "";
        }
        if (param.ActivityIdList) {
          AppointmentService.CancelAppt(param)
              .then(async (response) => {
                if(response.status == '200'){
                    this.GetPermission(this.selected[0].activityId);

                    if(text === "Undo Cancel"){ 
                        this.$bvToast.toast('The cancellation of the appointment was undone.', {
                         title: 'Appointment',
                          autoHideDelay: 5000,
                          variant: "success"
                        });
                    }else{
                        this.$bvToast.toast('The appointment has been canceled.', {
                         title: 'Appointment',
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                    }

                    await this.SearchAppoinments();
                    this.$refs.AppntTable.selectRow(this.indexSelected)
                }
              })
              .catch(error => {
                if (!error.response) {               
                  this.showMessage("Appointment",this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage("Appointment",error,"error");
                }
              });
        }
    },

    async getDefaultDateFromAndDateTo(){		
      await ChartReview.getPatientRegistrationsDefaultDates(this.$route.params.recordid)
            .then((response) => {
              if(response.data){
                let defaultDate = response.data;
                this.DateFrom = moment(defaultDate.dateFrom).format('YYYY-MM-DD');
                this.DateTo = moment(defaultDate.dateTo).format('YYYY-MM-DD');				
              }             
            })
            .catch((error) => {
              if (error.response) {
               this.showMessage(this.$t('ClinicalNotes.GetNoteTypes'),error,"error");
              }
            });
    },

    async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.age = infoList[0].age;
          this.PatientTitle.ageFormatted = infoList[0].ageFormatted;
          if(infoList[0].dob){
              this.PatientTitle.dob = moment(infoList[0].dob).format('MM/DD/YYYY');
          }   
          this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
      }
    },

    ChangeDropDownTherapist(therapists){
      this.TherapistVmodel = therapists;
      this.EmployeeSelected = [];
      var temp = [];
      therapists.forEach(function(e) {   
        temp.push(e.employeeCode.trim());
      })
      this.EmployeeSelected = temp;    
      this.SearchAppoinments();   
    },

    ChangeDropDownAppoinmentType(appttypes){
      this.AppoinmentTypeVmodel = appttypes;
      this.ApptTypeSelected = [];
      var temp = [];
      appttypes.forEach(function(e) {   
        temp.push(e.code.trim());
      })
      this.ApptTypeSelected = temp;    
      this.SearchAppoinments();   
    },

    ChangeDropDownShow(options){
      this.ShowVmodel = options;
      this.ShowSelected = "";
      var temp = [];
      options.forEach(function(e) {   
        temp.push(e.value.trim());
      })
      this.ShowSelected = temp;
      this.SearchAppoinments();   
    },

    DateFromChange(){
      this.SearchAppoinments();
    },

    DateToChange(){
      this.SearchAppoinments();
    },

    ViewIcon(row){
      //appointment-Dashboard
      this.OptionsRightMenu.visible = true;
        this.OptionsRightMenu.btnvisible = true;
        this.OptionsRightMenu.ItemsList = EnumService.AppointmentItems
        
        let layoutStatus = this.$store.state.layoutStatus;
      
        switch(layoutStatus) {
          case 'L0R0':
              this.$store.commit('setlayoutStatus', 'L0R1');
          break;
          default:
              this.$store.commit('setlayoutStatus', 'L1R1');
        }
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        
      this.$router.push({ name: "appointment-Dashboard", params: { id: row.patientCode,actId:row.activityId } });   
    },

    async GetPermission(activityId) {
      this.getCheckinsByActivityId(activityId)
      this.Appointment = await this.getAppointmentByActId(activityId);
      this.updateLabels()
    },

    async onRowSelected(item){
      this.selected = item
      this.isSelected = item.length > 0 ? true : false
      if (item.length > 0) {
        await this.GetPermission(item[0].activityId)
      }
    },

    updateLabels() {
      this.labelConfirm = this.Appointment && this.Appointment.isConfirmed ? 'Undo Confirm' : 'Confirm'
      this.labelShowUp = this.Appointment && this.Appointment.private ? 'Undo Show Up' : 'Show Up'
      this.labelCancel = this.Appointment && this.Appointment.reason !== null &&  this.Appointment.reason !== "" ? 'Undo Cancel': 'Cancel'
    },

    onFiltered(filteredItems){
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    pageChanged(page){
      this.currentPage = page;    
      this.SearchAppoinments();  
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.SearchAppoinments(); 
    },

    getEmployees() { 
        CalendarResourcesService.getAll(this.fullParam)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.TherapistList = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
            },

    getAppointmentTypes() { 
        AppoinmentTypeService.getListing()
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.AppoinmentTypeList = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
    },

    PopulatePatientTitle(){
        if(this.RegistrationInfo.dateFrom)
            this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
        if(this.RegistrationInfo.dateTo)
            this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
      if(this.$route.name.includes('clinical-appoint')){ 
          this.PatientTitle.programName = this.RegistrationInfo.programName;
          this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
          this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
          this.PatientTitle.fullName = this.RegistrationInfo.patientName;
          this.PatientTitle.dob = moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');
          this.PatientTitle.age = this.RegistrationInfo.age;
          this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('patient-appoint')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          this.PatientTitle.dob = this.patient.dob;             
          this.PatientTitle.fullName = this.patient.firstname + ' '+ this.patient.lastname;
      }
    },

    async SearchAppoinments(){
      let selectedPatients = [];
      selectedPatients.push(this.$route.params.id);               
      let param = {
        PageNumber          : this.currentPage,
        PageSize            : this.perPage,
        DateFrom            : this.DateFrom,
        DateTo              : this.DateTo,
        PatientCodeList     : selectedPatients,
        EmployeeCodeList    : this.EmployeeSelected,
        AppointmentTypeList : this.ApptTypeSelected,
        Show                : this.ShowSelected
      }
      this.$emit('load', true);
      await AppointmentService.getAppointmentList(param)
        .then((response) => { 
          if(response.data){   
            this.items = response.data;
            let headerString = response.headers["x-pagination"];
            this.Header = JSON.parse(headerString);
            this.totalRows = this.Header.TotalCount;
            this.items.forEach(function(element) {
              //element.selected = false;
                  if (element.dob)	
                      element.dob = Utils.formatterDateToString(element.dob);
                  if (element.startDate)	
                      element.startDate = Utils.formatterDateTimeToString(element.startDate);        
                  if (element.endDate)
                      element.endDate =  Utils.formatterDateTimeToString(element.endDate);        
            });
            if (this.items.length === 0) {
							this.ShowMessageIsEmpty = true;
						}
          }
          this.$emit('load', false);
        })
        .catch(error => {
          this.$emit('load', false);
            if (!error.response) {
            // network error
              this.showMessage(this.$t('schedulerDB.Appointments'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('schedulerDB.Appointments'),error,"error");
            }
        });  
    }

  },

  created(){
    this.getEmployees();
    this.getAppointmentTypes();

    EventBus.$on("onAppointmentReasonModal", function (reason) { 
			this.ClickedText  = reason;     
			this.$bvModal.show(this.modalReasonId);    
		}.bind(this));

    EventBus.$on("onSelectAppointmentReason", function (param) { 
      this.CancelAppt(param.Text,param.Reason);
    }.bind(this));
  },

  async mounted(){
    this.OptionsRightMenu.visible = true;  
    console.log("Montado"); 
    console.log(this.$route.name);
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('clinical-appoint') ? EnumService.ClinicalItems : EnumService.PatientItems;
    this.$emit("getVisibleMenu", this.OptionsRightMenu);


    this.isReadOnly = this.$store.state.isAppointmentsEHRReadOnly; 
    if(this.$route.name.includes('patient-appoint')){  
        this.GetPatientByCode();
    } 

    if(this.$route.name.includes('clinical-appoint')){      
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
        await this.getDefaultDateFromAndDateTo();
        this.IsClinical = this.RegistrationInfo.dateTo?false:true;
        if(this.RegistrationInfo.dob)
          this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');
        this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
    }
    this.PopulatePatientTitle();

    this.SearchAppoinments();   
  },

  destroyed(){
    EventBus.$off('onAppointmentReasonModal');
    EventBus.$off('onSelectAppointmentReason');

    this.OptionsRightMenu.ItemsList = [];
    this.OptionsRightMenu.Mode = false;
    this.OptionsRightMenu.visible = false; 
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
  }
   
};
</script>


