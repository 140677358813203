<template> 
      <div :id="targetId" oncontextmenu="return false;">
        <div v-if="!this.$attrs.control.isReadOnly && !NoteIsVoid && !this.$attrs.IsChartReview && !this.isReadOnly">
          <ejs-contextmenu :id="targetId" :target="menuItems.length > 0 ? menuTargetId : null" :items='menuItems' :select='onSelect'></ejs-contextmenu>
        </div>
        <b-form-input
            v-b-tooltip.hover :title="Hint"
            :type="Type"
            :maxlength="this.$attrs.control.controlMaxLenght"          
            :readonly="this.$attrs.control.isReadOnly || NoteIsVoid || this.$attrs.control.IsChartReview || this.isReadOnly"
            :min="Min"
            :max="Max"           
            :step="StepProp"
            :ref="RefProp"          
            :class="classValue" 
            v-bind:value="Value"
            v-on:blur="OnBlur($event)"
            v-on:change="OnChange"
            v-on:keyup.enter="$emit('keyup', $event)"
            :aria-describedby="this.$attrs.control.controlID"           
            v-on:keypress="$emit('keypress', $event)"
            v-on:input="Input"
            v-on:focus="$emit('focus', $event.target.value)"
            v-bind:style="style"
            :id="this.$attrs.HeaderId+'-'+this.$attrs.control.controlID"
            ></b-form-input>
            <!-- <VueContext ref="menu">
                <a class="dropdown-item" @click="showModalGetLastAnswers()">
                  Get 10 Last Answers
                </a>
            </VueContext> -->
            <MdGetLastAnswers v-if="showGetLastAnswers"
              v-bind:ShowPopup="showGetLastAnswers"
              @getCancel="onCancelGetLastAnswers($event)"
              @getOK="onOKGetLastAnswers($event)"
              v-bind:ControlId="controlID"
              v-bind:PatientCode="PatientCode"
              v-bind:TransDate="TransDate"
            />
      </div>         
</template>

<script>
import { EventBus } from '../../event-bus.js';
import Vue from 'vue';
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";

Vue.use(ContextMenuPlugin);
export default {
  name: 'MdEdit',
  mixins: [EventBus],
   props: {   
     StepProp:String,    
     isReadOnly:Boolean,
     Formatter:Function,
     Min:String,
     Max:String,
     Change:Function,
     RefProp:String,
     KeyEnter:Function,
     KeyPress:Function,
     FeedBackText:String,
     Name:String,
     value:[String, Number],
     TransDate: String,
     NoteId: String,
     id: [String, Number],
     PatientCode: String,
     IsCommonPart: Boolean
   },

   data(){
     return{
      classValue:"form-control cursor-context-menu",
      NoteIsVoid:false,
      style:{
        position:'absolute',
        top: this.$attrs.control.controlTop+'px',
        width:this.$attrs.control.controlWidth+'px',
        height:this.$attrs.control.controlHeight+'px',
        left:this.$attrs.control.controlLeft+'px',
        fontSize:'',
        fontWeight: '',
        fontFamily:'',
        color:'',
        backgroundColor:'',
        textDecorationLine:'',
        fontStyle:'',
        textTransform:'',
        borderColor: '',
        textAlign: 'left'
      },
      Hint: this.$attrs.control.controlHint,
      Type: 'text',
      isRequired: this.$attrs.control.isRequired,
      showGetLastAnswers: false,
      controlID:String(this.$attrs.control.controlID),
      //PatientCode: this.$route.params.id,
      Value: this.value,
      menuItems:[],
      targetId: "target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
      menuTargetId:"#target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
      idControl:this.id.toString(),
      cmpLoaded: false
     }
   },


methods:{
     OnChange(value){
      //  this.$emit('change',evt.target.value)
       this.style.borderColor = !value && this.isRequired ? '#dc3545 !important': ''
       this.$emit('input',value)
     },

     OnBlur(evt){
       this.$emit('blur',evt.target.value)
       this.style.borderColor = evt.target.value.length == 0 && this.isRequired ? '#dc3545 !important': ''
     },

     setControlType() {
       let controlDataType = this.$attrs.control.controlDataType;
       if(controlDataType === 'Integer' || controlDataType === 'Money')
        this.Type = 'number'
       if(controlDataType === 'String')
        this.Type = 'text'
     },

     setControlCharCase() {
       let controlCharCase = this.$attrs.control.controlCharCase;
       if(controlCharCase === 'ecUpperCase')
        this.style.textTransform = 'uppercase'
       if(controlCharCase === 'ecLowerCase')
        this.style.textTransform = 'lowercase'
     },

     setAlignment() {
       if(this.$attrs.control.alignment) {
          if(this.$attrs.control.alignment === 'taCenter')
            this.style.textAlign = 'center'
          if(this.$attrs.control.alignment === 'taLeftJustify')
            this.style.textAlign = 'left'
          if(this.$attrs.control.alignment === 'taRightJustify')
            this.style.textAlign = 'right'
       }
     },

     InitializeVariables(){
       if(this.$attrs.control.controlColorForHtml)
          this.style.backgroundColor = this.$attrs.control.controlColorForHtml;
       else if(this.$attrs.control.isReadOnly || this.isReadOnly)
          this.style.backgroundColor = '#e9ecef !important'
       if(this.$attrs.control.controlFontForHtml){         
          this.controlFont = this.$attrs.control.controlFontForHtml.split(',');
          var size = parseInt(this.controlFont[1]) + 2;
          this.style.fontFamily = this.controlFont[0];
          this.style.fontSize = size+'px !important';
          this.style.color = this.controlFont[3];
          var Decorations = this.controlFont[2];
          this.style.fontWeight = Decorations.charAt(0) === '1'?'bold':'';
          this.style.fontStyle = Decorations.charAt(1) === '1'?'italic':'';
          this.style.textDecorationLine = Decorations.charAt(2) === '1'?'underline':'';
          this.style.textDecorationLine = Decorations.charAt(3) === '1'?this.style.textDecorationLine+ ' line-through':this.style.textDecorationLine;
       }
       this.setControlType();
       this.setControlCharCase();
       this.setAlignment()
       this.cmpLoaded = 'MdTextSpeech' in Vue.options.components
    },
    showModalGetLastAnswers() {
      this.showGetLastAnswers = true
    },

    onCancelGetLastAnswers(){
      this.showGetLastAnswers = false
    },

    Input(value){
      this.Value = value;
      this.setChangedStatus()
    },

    onOKGetLastAnswers(value) {
      this.Value = value;
      this.$emit('input',value)
      this.setChangedStatus()
      this.showGetLastAnswers = false
    },

    handlerClick(evt){
      if(!this.$attrs.control.isReadOnly && !this.isReadOnly) {
        this.$refs.menu.open(evt)
      }
    },

    setValid(state){
        this.style.borderColor = state ? '#dc3545 !important': ''
    },

    onSelect: function(args ) {
       if(args.item.id === 'gettenlastans') {
         this.showModalGetLastAnswers()
       }
       if(args.item.id === 'recording') {
          EventBus.$emit('speechBarLabel', 'stop recording');
          this.$root.$refs.MdTextSpeech.toggleSpeechBar()//Utils.toggleSpeechBar();
          this.$root.$refs.MdTextSpeech.startSpeechToText(this.$attrs.control.controlID)
       }
       if(args.item.id === 'speech') {
          EventBus.$emit('speechBarLabel', 'stop speech');
          this.$root.$refs.MdTextSpeech.toggleSpeechBar();
          this.$root.$refs.MdTextSpeech.startSpeech(this.Value)
       }
    },

    loadMenuItems() {
      this.menuItems = []
      if (!this.IsCommonPart) {
        this.menuItems.push({text: this.$t('Shared.GetLast10'), id: "gettenlastans"})
      }
      if (this.cmpLoaded) {
        if(this.$root.$refs.MdTextSpeech.isSpeechRecognitionSupported) {
          this.menuItems.push({text: 'Start/Stop Recording', id: "recording"})
        }
        this.menuItems.push({text: 'Start/Stop Speech', id: "speech"})
      }
    },

    setChangedStatus(){
      var changed = {
        "ControlId":this.$attrs.control.controlID,
        "Changed":true,
        "NoteId":this.NoteId
      }
      EventBus.$emit('onControlWasChanged', changed);
    },
    setValueS2T( sp ){
      let sp2t = ''
      if(this.value) {
        sp2t += Array.from (this.value + ""+sp).join("");
      } else {
        sp2t = Array.from (sp).join("");
      }
      this.Value = sp2t
      this.$emit('input', sp2t);
      this.setChangedStatus()
    }
},

created(){

    EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
      }.bind(this));

  if(this.$attrs.NoteIsVoid){
    this.NoteIsVoid = this.$attrs.NoteIsVoid;
  }
  EventBus.$on("receivedS2T", function (event) {
    if( event.ControlId == this.$attrs.control.controlID) {
      this.setValueS2T( event.finalSentence )
    }
  }.bind(this))

},
mounted(){
  this.InitializeVariables();
  this.loadMenuItems()
}
};

</script>

<style>
 @import '../../assets/css/Patients/Patient.css';
 .scroll{
	overflow: hidden !important;
}
</style>