<template>	
  <div id="divCamera">
  <b-modal :id="ModalId" size="md" centered scrollable  :title="TitleWindow" 
  @ok="this.handleOk" 
  @cancel="this.emitEventCancel"
  @show="resetModal"
  @close="CloseModalCamera"
  @hidden="resetModal"    
  > 



      <div id="appCamera" class="web-camera-container">
        <!--
          <div class="camera-button">
              <button type="button" :class="!isCameraOpen ? 'btn btn-primary my-3 btn-block' : 'btn btn-danger my-3 btn-block btn-danger'"  @click="toggleCamera">
                <span v-if="!isCameraOpen">Open Camera</span>
                <span v-else>Close Camera</span>
            </button>
          </div>
          -->
          <div v-show="isCameraOpen && isLoading" class="camera-loading">
            <ul class="loader-circle">
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>

          <div v-if="!isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
              
            <video v-show="!isPhotoTaken" ref="camera1" :width="440" :height="248" autoplay></video>
           <div class="camera-sample" v-show="!isPhotoTaken">
                <img src="@/assets/images/faces/camera-sample.png" width="440" height="248">
            </div>
          </div>
          
          
          <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
              
            <video v-show="!isPhotoTaken" ref="camera" :width="440" :height="248" autoplay></video>
           <div class="camera-sample" v-show="!isPhotoTaken">
                <img src="@/assets/images/faces/camera-sample.png" width="440" height="248">
            </div>
            
            <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="440" :height="248"></canvas>
          </div>
          
         <!-- <div v-if="isCameraOpen && !isLoading" class="camera-shoot">
            <button type="button" class="button" @click="takePhoto">
              <img src="@/assets/images/camera/camera--v2.png">
            </button>
          </div>-->
          
        
      </div>

      
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()">
          Cancel
        </b-button>
        <b-button v-if="isCameraOpen" variant="primary" @click="takePhoto">
          <span v-if="isPhotoTaken">{{$t('GroupNotesRightBar.RetakePic')}}</span>
           <span v-else>{{$t('GroupNotesRightBar.TakePic')}}</span>
         </b-button>
         <b-button v-if="!isCameraOpen" :variant="'primary'" @click="toggleCamera">
           <span>Open Camera</span>          
       </b-button>
        <b-button v-if="isPhotoTaken" variant="primary" @click="ok">
          Save Picture
        </b-button>
       
        <!-- Button with custom close trigger value -->
      
      </template>



  </b-modal>   
  </div>   
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
import ShowMessage from '@/components/messages/ShowMessage.js'
import { EventBus } from '../../../event-bus.js';

export default {
name: 'ModalCamera',
props: {     
  TitleWindow:String,
  ModalId:String
 },
  mixins: [DBFunctions,EventBus,ShowMessage],
  data() {
  return {
          isCameraOpen: false,
    isPhotoTaken: false,
    isShotPhoto: false,
    isLoading: false,
    link: '#',
    Count:0,

  }
},

mounted() {
 // this.getProviders(); 

    
    /*this.$root.$on('bv::modal::hide', (/*bvEvent, modalId) => {
      this.isCameraOpen = false;
      if(!this.isCameraOpen){
        this.stopCameraStream();
      }else{
        this.isCameraOpen = false;
        this.stopCameraStream();
      }
     
    //  this.stopCameraStream();
    }); */

    /*EventBus.$on("onGetProviders", function () {           
        this.getProviders(); 
    }.bind(this));*/
   
  },

created(){ 
 
},

beforeDestroy () {     
    EventBus.$off('PhotoWasTaken');
    EventBus.$off('UserPhotoWasTaken');  
  },

methods:{

  resetModal(){
   
  },


  toggleCamera() {
    if(this.isCameraOpen) {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
    } else {
      this.isCameraOpen = true;
      this.createCameraElement();
    }
  },


  CloseModalCamera(){
      if(this.isCameraOpen){
          this.isCameraOpen = false;
          this.stopCameraStream();
      }

      this.isPhotoTaken = false;
     
  },

  createCameraElement() {
   
    this.isLoading = true;
    const constraints = (window.constraints = {
      audio: false,
      video: true
    });


    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        this.isLoading = false;
        this.$refs.camera.srcObject = stream;
        this.Count++;

        /*let {width, height} = stream.getTracks()[0].getSettings();
      console.log(`${width}x${height}`); // 640x480*/
      })
      .catch(error => {
              this.isLoading = false;
              this.showMessage("Camera",error + "","error");
      });
   
  },
  
  stopCameraStream() {
   
    let tracks = this.$refs.camera.srcObject.getTracks();
    tracks.forEach(track => {
      track.stop();
    });
  
  
  },
  
  takePhoto() {
    if(!this.isPhotoTaken) {
      this.isShotPhoto = true;

      const FLASH_TIMEOUT = 50;

      setTimeout(() => {
        this.isShotPhoto = false;
      }, FLASH_TIMEOUT);
    }
    
    this.isPhotoTaken = !this.isPhotoTaken;
    
    const context = this.$refs.canvas.getContext('2d');
    context.drawImage(this.$refs.camera, 0, 0, 440, 248);
  },
  
  downloadImage() {
    const download = document.getElementById("downloadPhoto");
    const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg");
  //.replace("image/jpeg", "image/octet-stream");
    download.setAttribute("href", canvas);
  },



  handleOk(bvModalEvt) {   
    
    if(this.isPhotoTaken){
        var can = document.getElementById("photoTaken");
        var ctx = can.getContext('2d');
      
        var imageData = ctx.getImageData(96, 0, 344, 248);
        var newCan = document.createElement('canvas');
        newCan.width = 248;
        newCan.height = 248;
        var newCtx = newCan.getContext('2d');
        newCtx.putImageData(imageData, 0, 0);
        var test = newCan.toDataURL("image/jpeg");
        this.CloseModalCamera();

        if(bvModalEvt.componentId == 'userModalIdCamera'){
          EventBus.$emit('UserPhotoWasTaken', test);
        } else {
          EventBus.$emit('PhotoWasTaken', test);
        }   
        
    }
  },




  emitEventCancel(){
    this.CloseModalCamera();
    }, 

    handleSubmit() {   
      /*if (!this.validate_required()) {
        this.showMessage("Provider Selector", "Check red-labeled fields, please.", "errorAutoHide");
        return;
      }
      if(this.TemplateA && this.TypeOfBill){
        EventBus.$emit("onSelectProvider", this.ProviderModel.providerCode);
         EventBus.$emit("onGetTypeOfBill", true)
        this.$bvModal.show(this.modalIdTypeofBill);
      }else{
        EventBus.$emit("onCreateFile", this.ProviderModel.providerCode);
      }
      this.$nextTick(() => {
        this.$bvModal.hide('modalIdClaimProviderSelector');
      }) */
    },

   emitEventOK(){  
    /*if(this.validate_required()){
       if(this.TemplateA){
        this.$bvModal.show(this.modalIdTypeofBill);
      }
     
    }else{
      this.showMessage("Provider Selector", "Check red-labeled fields, please.", "errorAutoHide");
    } */
     
    },
},

}


</script>




<style scoped>

body {
  display: flex;
  justify-content: center;
}
.camera-sample{
  width: 440px;
  height: 248px;
  top:-253px;
  left:0;
  position: relative !important;

}

.web-camera-container {
 /* margin-top: 2rem;*/
  margin-bottom: 2rem;
 /* padding: 2rem;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 /* border: 1px solid #ccc;*/
  border-radius: 4px;
 /*width: 440px;*/
}
.web-camera-container .camera-button {
  margin-bottom: 2rem;
}
.camera-box{
  border-radius: 10px;
  height: 248px;
  background-color:black !important;
}
.web-camera-container .camera-box .camera-shutter {
  opacity: 0;
  width: 440px;
  height: 248px; 
  position: absolute;
}
.web-camera-container .camera-box .camera-shutter.flash {
  opacity: 1;
}
.web-camera-container .camera-shoot {
  margin: 1rem 0;
}
.web-camera-container .camera-shoot button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.web-camera-container .camera-shoot button img {
  height: 35px;
  object-fit: cover;
}
.web-camera-container .camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}
.web-camera-container .camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}
.web-camera-container .camera-loading .loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}
.web-camera-container .camera-loading .loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}
.web-camera-container .camera-loading .loader-circle li:nth-child(2) {
  animation-delay: 0.2s;
}
.web-camera-container .camera-loading .loader-circle li:nth-child(3) {
  animation-delay: 0.4s;
}
@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

</style>