<template>
    <div id="wrapper__right" class="d-lg-block d-md-block" style="min-Width:245px;"> 
        <div class="rm-header">
            <div id="rm-info" class="textlabel" data-label="Patient's info">
                <p id="rm-name" class=" text-truncate"><span>{{this.DataHeader.title}}</span> {{this.DataHeader.firstName}}</p>
                <p id="rm-surname" class=" text-truncate">{{this.DataHeader.lastName}}</p>
            </div>

            <div :id="targetId" oncontextmenu="return false;" tabindex="0" v-on:focus="OnFocusPhoto">
              <div id="rm-frame">
                <div  v-if="this.DataHeader.patientCode"><ejs-contextmenu  :target="menuTargetId" :id="targetId" :items='menuItems' :select='onSelect'></ejs-contextmenu></div>
                <input type="file" ref="input" hidden value="" @change="addImage($event)"/>
                <img v-on:click="OnCamera" v-if="this.Photo.contents !== null && this.Photo.contents !== ''" id="rm-photo" v-bind:src="'data:image/'+this.Photo.extension+';base64,'+this.Photo.contents" data-toggle="modal" data-target="#exampleModalScrollable">
                <img v-on:click="OnCamera" v-if="this.Photo.contents === null || this.Photo.contents === ''" id="rm-photo" src="@/assets/images/faces/nopic-male.jpg" data-toggle="modal" data-target="#exampleModalScrollable">             
              </div>
            </div>

                <p v-if="this.DataHeader.patientCode" id="rm-summary" class="text-truncate pull-left">ID {{this.DataHeader.patientCode}}</p>
                    <div class="bottomShadow"></div>
                      <i v-if="this.DataHeader.allergies" id="rm-allergic" class="mdi mdi-alert text-warning allergies" v-b-tooltip.hover.bottomleft="this.$t('RigthBar.aTo')+' ' + this.DataHeader.allergies" variant="primary"><div>{{$t('RigthBar.ALLERGIC')}}</div></i>
                </div>
                   <marquee v-on:click="GoToPatientSummary" data-toggle="modal" data-target="#exampleModalScrollable" id="rm-marquee" scrollamount="4" truespeed="true" >
                    <small v-if="this.DataHeader.dob">{{ $t('Shared.Dob') }}:</small> {{this.DataHeader.dob}}<span v-if="this.DataHeader.dob">///</span>
                    <small v-if="this.DataHeader.ageFormatted">{{ $t('Shared.Age') }}:</small> {{this.DataHeader.ageFormatted}}<span v-if="this.DataHeader.ageFormatted">///</span>
                    <small v-if="this.DataHeader.gender">{{$t('Patient.Sex')}}:</small> {{this.DataHeader.gender}}<span v-if="this.DataHeader.gender">///</span>            
                    <small v-if="this.DataHeader.cellPhone">{{ $t('Shared.CellPhone')}}:</small> {{this.DataHeader.cellPhone}}<span v-if="this.DataHeader.cellPhone">///</span>             
                    <small v-if="this.DataHeader.allergies">{{$t('MdPatientAllergies.Allergies')}}:</small>{{this.DataHeader.allergies}}<span v-if="this.DataHeader.allergies">///</span>
                    <small v-if="this.DataHeader.email">{{ $t('Shared.Email') }}:</small> {{this.DataHeader.email}}
                    <small v-if="this.DataHeader.extraInfo"></small> {{this.DataHeader.extraInfo}}
                   </marquee>
                  <div class="rm-options rm-optionswf">
                    <div id="rm-items">
                        <a :class="{ 'isDisabled': itemdisabled, rmActiveItem : active_el == it.actionFormID}" 
                          v-for="it in this.ItemsList" :key="it.processRecID" 
                            @click="triggerClick(it)">
                            <span :class="{ 'ikon' : true,  'med-status-pending text-danger': it.processStatus === 'U', 'med-status-complete text-success' : it.processStatus === 'C' }"></span>
                            {{it.processDescription}}
                        </a>
                        <small>Incident notes</small>
                    </div>
                  </div>

      <ModalCamera
        v-bind:TitleWindow="$t('GroupNotesRightBar.TakePic')"
        v-bind:ModalId="this.modalIdCamera">
      </ModalCamera>

      <IncidentWorkflowNotesModal v-if="showNote"
        v-bind:Header="Header"
        v-bind:TitleWindow="NoteTitle"
        v-bind:IncidentID="IncidentID"
        v-bind:IncidentDate="IncidentDate"
        v-bind:ProgramCode="ProgramCode"
        v-bind:CategoryCode="CategoryCode"
        v-bind:NotePatientInfo="NotePatientInfo">
      </IncidentWorkflowNotesModal>
    </div>
</template>
  
<script>
  import DBFunctions from '@/common/DBFunctions.js'
  import { EventBus } from '../../../event-bus.js';  
  import ClinicalService from '@/services/clinical.service'
  import DocumentManagementService from '@/services/documentmanagement.service'

  export default {  
    name: 'IncidentWorkflowRightBar',
    mixins: [DBFunctions],
    data(){
      return{
        DataHeader:{
          title: 'Please, select',
          extraInfo: 'Select one item from the table to show information on this menu, please...'
        },
        modalIdCamera:"modalIdCamera",
        menuTargetId:"#target-menu-righmenu",
        targetId:"target-menu-righmenu",
        NotPhoto:true,
        OptionsRightMenu:{
          visible:false,
          btnIncidentWorkflowvisible:true,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:''         
        },
        menuItems:[],
        Photo:{      
          contents:"",
          extension:"", 
          fileName:"",   
        },
        ItemsList: this.$attrs.ItemList,
        itemdisabled : false,
        active_el:'',
        showNote: false,
        Header: {},
        NoteTitle: '',
        IncidentID: '',
        IncidentDate: '',
        PatientCode:'',
        ProgramCode:  '',
        CategoryCode: '',
        NotePatientInfo: {},
        params:{      
          NotesID: "",
          HeaderId: "", 
          PatientCode: "",
          TherapyCode: "", 
          TransDate : "",
          AdmissionRecordID: "",
          IsVoid: ""
        },
      }
    },
   
    methods: {

      loadMenuItems() {
        this.menuItems = []
        this.menuItems.push({text: this.$t('GroupNotesRightBar.LoadPic'),id:"LoadPicture"})
        this.menuItems.push({text: this.$t('GroupNotesRightBar.TakePic'),id:"TakePicture"})

        if(this.Photo.contents){
          this.menuItems.push({text: this.$t('GroupNotesRightBar.RemovePic'), id: "RemoveUserPicture"})
        }
      },
      
      getClassByStatus(item) {
        if (item.status === 'C') {
          return 'finished'
        } 
        else if (item.status === 'U') {
          return 'pending'
        }
        else return ''
      },

      OnFocusPhoto(){
      },

      SavePhoto(){  
        if(!this.NotPhoto){
          this.UpdatePhoto();
        }else{
          this.InsertPhoto();
        }
        this.loadMenuItems();
      },

      InsertPhoto(){
      var data = {
        Entitycode:this.PatientCode,
        contents:this.Photo.contents,
        extension:this.Photo.extension, 
        fileName:this.Photo.fileName  
      };
      DocumentManagementService.AddPhoto(data)
          .then((response) => {
           if(response.status == '200'){
            this.Photo = response.data;
            this.NotPhoto = false;
            setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('RigthBar.pPhoto'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
           
            }                           
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('RigthBar.pPhoto'),error,"error");
                    }
                });  
    },
    

      UpdatePhoto(){
        var data = {
          Entitycode:this.PatientCode,
          contents:this.Photo.contents,
          extension:this.Photo.extension, 
          fileName:this.Photo.fileName,   
        };

        DocumentManagementService.UpdatePatientPhoto(this.Photo.seqno,data)
            .then((response) => {
            if(response.status == '200'){
              setTimeout(() => 
                          this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                          title: this.$t('RigthBar.pPhoto'),
                            autoHideDelay: 5000,
                            variant: "success"
                          })
                          , 50); 
              }                            
                  })
                  .catch((error) => {
                      if (error.response) {
                          this.showMessage(this.$t('RigthBar.pPhoto'),error,"error");
                      }
                  });  
      },

      triggerClick: async function (item){
        this.active_el = item.formid;
        
        await EventBus.$emit('CloseMdNote');
        
        this.params.NotesID = item.actionFormID
        this.params.HeaderId = item.workFlowNoteHeaderID 
        this.params.PatientCode = item.patientCode
        this.params.TransDate = this.IncidentDate
        this.params.AdmissionRecordID = item.admissionRecordId
        
        await this.getClinicalNoteInfo()

        this.Header.recordid = item.admissionRecordId
        this.Header.title = item.processDescription
        this.Header.workflowRecID = item.workflowRecID
        this.Header.processRecID = item.processRecID
        this.Header.actionRecID = item.actionRecID
        this.Header.Id = item.workFlowNoteHeaderID 
        this.Header.notesId = item.actionFormID

        this.ProgramCode = this.Header.programCode
        this.CategoryCode = this.Header.transCode
        
        let patientInfo = {
          firstName:this.Header.patientFirstName, 
          lastName:this.Header.patientLastName, 
          patientCode:this.Header.patientCode
        }
        
        this.NotePatientInfo = patientInfo
        
        this.NoteTitle = this.Header.description
        this.showNote = true
      },

      GoToPatientSummary(){
        if(this.$store.state.isUsePatientInfo) {
          if(this.$route.name.includes('patient') || this.$route.name.includes('contact') || this.$route.name.includes('allergy')){
            if( this.$router.currentRoute.name == "patient-details") {
                this.$bvToast.toast(this.$t('Layout.displayedScreen'), {
                    title: this.$t('MdPatientInfoBox.PatientInfo'),
                    autoHideDelay: 4000,
                    variant: "success"
                  });
            } else {
                this.$router.push({ name: "patient-details", params: { id: this.$route.params.id } })
                  .catch(()=>{});
            }
            
          } else if(this.$route.name.includes('Clinical') || this.$route.name.includes('vs')){
              if( this.$router.currentRoute.name == "Clinical-PatientInfo") {
                  this.$bvToast.toast(this.$t('Layout.displayedScreen'), {
                      title: this.$t('MdPatientInfoBox.PatientInfo'),
                      autoHideDelay: 4000,
                      variant: "success"
                    });
              } else {
                  this.$router.push({ name: "Clinical-PatientInfo", params: { id: this.$route.params.id,recordid:this.$route.params.recordid } })
                    .catch(()=>{});
              }
          }
        }
      },

    async GetDataHeader(patientCode) {
      this.DataHeader = await this.getData(this.$route.params.id ? this.$route.params.id : patientCode);
      this.DataHeader.age = this.DataHeader.age === 0 ? "0" : this.DataHeader.age;
    },

    OnCamera(){ 
      if (this.DataHeader.firstName)
        this.$bvModal.show(this.modalIdCamera);   
    },

    LoadPicture(){
      if (this.DataHeader.firstName) {
        this.$refs.input.click();
        this.$refs.input.value = "";
      }       
    },

    async GetPhoto(patientCode){
      await DocumentManagementService.GetPatientPhoto(this.$route.params.id ? this.$route.params.id : patientCode)
        .then((response) => {
          if(response.status == '200' && response.data){
            this.Photo = response.data;
            if(this.Photo.contents === null || this.Photo.contents === ''){
              this.NotPhoto = true;
            } else {
              this.NotPhoto = false;
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$t('HeaderLeftMenu.uPhoto'),error,"error");
          }
        });  
    },

    onSelect:function(args) {    
       if(args.item.id === 'LoadPicture') {
        this.LoadPicture();
       }
       if(args.item.id === 'TakePicture') {
        this.OnCamera();
       // alert("Take Picture");
       }
       if(args.item.id === 'RemoveUserPicture') {
          this.RemovePicture();
       }
    },

    async addImage(e){
      if (this.DataHeader.firstName) {
        let droppedFiles = e.type === 'drop'?e.dataTransfer.files:e.target.files;     
      if(!droppedFiles) return;     
      if(droppedFiles.length === 1){
        var filename = droppedFiles[0].name;
        this.Photo.fileName = filename;
        const fileReader = new this.SyncFileReader(droppedFiles[0]);
        const arrayBuffer = await fileReader.readAsDataURL();
        if(arrayBuffer){
          const img = document.getElementById("rm-photo");
          img.src = arrayBuffer;
        }    

        if(arrayBuffer.indexOf(',') !== -1){
           var b = arrayBuffer.split(',');    
           this.Photo.contents = b[1];  
           //this.files.push({"name":fileNameNoExtension,"Image":Image,"extension":extension,"base64":b[1],"description":"","code":code,"type":type,"new":true,"isreadonly":true});
       
        }
        
        var pos = filename.lastIndexOf('.');
        if(pos !== -1){          
          this.Photo.extension = filename.substring(pos).toUpperCase();
        }
        this.SavePhoto();        
      }
    }
    },

    SyncFileReader(file) {
      let self = this;
      let ready = false;
      let result = '';

      const sleep = function (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      self.readAsDataURL = async function() {
          while (ready === false) {
            await sleep(100);
          }
          return result;
      }    

      const reader = new FileReader();
      reader.onloadend = function(evt) {
          result = evt.target.result;
          ready = true;
      };
      reader.readAsDataURL(file);
    },

    cleanPatientInfo() {
      this.DataHeader = {
        age : '',
        allergies : '',
        attendingDoctor : '',
        cellPhone: '',
        dob: '',
        email: '',
        firstName: '',
        gender: '',
        lastName: '',
        patientAddress : '',
        patientCode: '',
        primaryInsurance : '',
        title: 'Please, select',
        extraInfo: 'Select one item from the table to show information on this menu, please...'
      }
      this.Photo.contents = null
    },

    async getClinicalNoteInfo(){
      await ClinicalService.getClinicalNoteInfo(this.params)
          .then((response) => {              
            if(response.data){
              this.Header = response.data;               
            }
            this.Load = false;
          })
          .catch(error => {
            if (error.response) {
              this.Load = false;
              this.showMessage('Get Cinical Note info',error,"error");
            } 
          });  
    },
    RemovePicture(){
      if(this.NotPhoto)return;      
      this.Photo.contents = '',
      this.Photo.extension = '', 
      this.Photo.fileName = '', 
      this.msg = this.$t('Msg.RemoveSuccessfully')
      this.SavePhoto() 
    }
  },

    async created(){
        EventBus.$on("loadRightMenu", function () {       
          document.getElementById('rightLoader').style.display = 'none';
        }.bind(this));

        EventBus.$on("loadMenu", function (ItemsList, patientCode, IncidentID, IncidentDate) {       
          this.ItemsList = ItemsList
          this.PatientCode = patientCode
          if (patientCode) {
            this.GetDataHeader(patientCode)
            this.GetPhoto(patientCode)
            .then(() => {
              this.loadMenuItems();     
            });
            this.IncidentID = IncidentID
            this.IncidentDate = IncidentDate
            EventBus.$emit('stopWheel');
          } else {
            this.cleanPatientInfo()
          }
        }.bind(this));

        EventBus.$on("CloseMdNote", await function () {
          this.showNote = false
        }.bind(this)); 
        
        EventBus.$on("cleanPatientInfo", await function () {
          this.cleanPatientInfo()
        }.bind(this)); 

        EventBus.$on("PhotoWasTaken", function (data) {
          const img = document.getElementById("rm-photo");
          img.src = data;
          if(data.indexOf(',') !== -1){
              var b = data.split(',');              
              if(b[0].indexOf(';') !== -1){
                var c = b[0].split(';');  
                if(c[0].indexOf('/') !== -1){
                  var d = c[0].split('/');
                  this.Photo.extension = "."+d[1]; 
                  this.Photo.contents = b[1]; 
                  this.Photo.fileName = "";            
                  this.SavePhoto();
                }
              } 
          }
        }.bind(this));
    },

    destroyed(){
      EventBus.$off('loadMenu');
      EventBus.$off('cleanPatientInfo');
      
    },

    mounted(){
        let layoutStatus = this.$store.state.layoutStatus;
        this.OptionsRightMenu.btnIncidentWorkflowvisible = true;
        this.OptionsRightMenu.visible = false;     
        switch(layoutStatus) {
          case 'L0R0':
              this.$store.commit('setlayoutStatus', 'L0R1');
          break;
          default:
              this.$store.commit('setlayoutStatus', 'L1R1');
        }
        this.$emit('onMenuRightBarIncidentWorkflow', this.OptionsRightMenu);
    }
  };
    
  </script>
  
  <style>
  .m-rightmenu {
    color: white;
    left: 0;
    width: 250px;
    /* iOS height fix */
    height: 95%;
    height: calc(100vh - 40px);
    overflow: hidden;
    background-color: transparent;
    transition-duration: 0.3s;
    transition-easing: cubic-bezier(0.215, 0.61, 0.355, 1);
    box-shadow: 0 0 15px #cccccc44 !important;
  }
  #rightLoader {
    position: absolute;
    top: 125px;
    width: 100%;
    height: 20px;
    z-index: 1000;
    display: flex;
    justify-content: center;
  }
  #rightLoader span div {
    background-color: #19bee8;
    border: 1px solid rgba(255, 255, 255, 0.7);
    width: 6px;
    height: 6px;
    margin: 5px;
    border-radius: 4px;
    animation: loadNow 1s linear infinite;
  }
  #rightLoader .ll01{
      animation-delay: 0.1s;
  }
  #rightLoader .ll02{
      animation-delay: 0.2s;
  }
  #rightLoader .ll03{
      animation-delay: 0.3s;
  }
  </style>
  
  
  