<template>
    <div id="ReportPatientAllergies">
      <div v-if="this.filterView">
        <MdTitle v-bind:Text="$t('PatientAllergyRep.RepTitle')" />
        <b-row>
          <b-col lg="3" md="3" sm="12">         
            <MdSelect
              v-bind:Label="$tc('Shared.Patient',2)"
              v-bind:Options="PatientsList"
              v-bind:Model="PatientCodeModel"
              v-bind:FieldDescription="'patientName'"
              v-bind:FieldCode="'patientcode'"
              v-bind:Multiple="true"             
              :Input="ChangeDropDownPatients"
              :CustomLabel="customLabelPatients"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
           <MdSelect
              v-bind:Label="$tc('Allergy.PatientStatus')"
              v-bind:Options="showOptions"
              v-bind:Model="showPatientIsActiveModel"
              v-bind:FieldDescription="'text'"
              v-bind:FieldCode="'value'" 
              v-bind:Multiple="false"                      
              :Input="ChangeIsActive"
              v-bind:AllowEmpty="false"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
           <MdSelect
              v-bind:Label="$tc('Allergy.AdmissionStatus')"
              v-bind:Options="showOptions"
              v-bind:Model="showIsActiveAdmissionModel"
              v-bind:FieldDescription="'text'"
              v-bind:FieldCode="'value'" 
              v-bind:Multiple="false"                      
              :Input="ChangeIsActiveAdmission"
              v-bind:AllowEmpty="false"/>
          </b-col> 
          <b-col lg="3" md="3" sm="12">
           <MdSelect
              v-bind:Label="$t('Allergy.AllergyStatus')"
              v-bind:Options="showOptions"
              v-bind:Model="showModel"
              v-bind:FieldDescription="'text'"
              v-bind:FieldCode="'value'" 
              v-bind:Multiple="false"                      
              :Input="ChangeShow"
              v-bind:AllowEmpty="false"/>
          </b-col>                   
          <b-col lg="3" md="3" sm="12">         
            <MdSelect
              v-bind:Label="$t('MdPatientAllergies.Allergies')"
              v-bind:Options="AllergiesList"
              v-bind:Model="AllergiesModel"
              v-bind:FieldDescription="'allergy'"
              v-bind:FieldCode="'allergyCode'"
              v-bind:Multiple="true"             
              :Input="ChangeDropDownAllergies"
              :CustomLabel="customLabelAllergy"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Allergy.Severity')"
              data-field="Additional_EducationStatus"
              v-bind:Options="AllergySeverity"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:Model="Allergy.problemSeverity"                       
              v-bind:Multiple="false"
              :Input="ChangeDropDownAllergySeverity"
            />
          </b-col>
        </b-row>
        <b-row>
          
          </b-row>
        <div class="page-actions">
          <MdButton
            v-bind:Label="$t('Shared.Back')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="reportList"
            v-bind:Name="'btn_ReportList'"/>
          <MdButton
            v-bind:Label="$t('Shared.Report')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="onClickShowReport"
            v-bind:Name="'btn_Print'"/>   
        </div>
      </div>
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="$t('PatientAllergyRep.RepTitle')"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="PatientAllergy"
          :ReportName="'PatientAllergy'"
          :ReportTitle="$t('PatientAllergyRep.ReportTitle')"
          :IsAnAPIReport="true"
        />
    </DxPopup>
  </div>
</template>

<script>
    import Vue from 'vue'
    import { DxPopup } from 'devextreme-vue/popup';
    import ShowMessage from '@/components/messages/ShowMessage.js'
    import PatientService from '@/services/patient.service';
    import Utils from '../../../common/utils'
    import AllergyService from '@/services/allergies.service'
    import AllergyProblemSeveritiesService from '@/services/allergiesproblemseverities.service'

export default Vue.extend({
    name: "PatientAllergyRep",
    mixins: [ ShowMessage ],
    components: { DxPopup },
    props: {
      patientCode: String,
      closeWindow: Function,
      recNo: Number,
      patientName: String,
      filterView: Boolean
    },
    data() {
        return {
          OptionsRightMenu:{
              visible:false,
              ItemsList:[],
              btnvisible:false,
              itemdisabled: false,
              PatientCode:'',
              Mode:this.edit_mode,
              favButtonAtion:false          
            },
            isParamVisible: true,
            isReportVisible: false,
            dateFrom: Utils.formatterDate(Date()),
            dateTo: Utils.formatterDate(Date()),
            reportParams: [],
            PatientsList:[],
            AllergiesList:[],
            params: {
              "PatientCodeList": [],
              "AllergyCodeList": [],
              "Show": 'all',
              "IsActive": true,
              "IsActiveAdmission": true
            },
            PatientCodeModel:'',
            AllergiesModel: '',
            parameter:{
              Status: true,
            },
            AllergySeverity: [],
            Allergy:{            
              problemSeverity: '',
              allergy: ''
            },
            showModel: {
              "value":"all",
              "text":this.$t('Shared.All')
            },
            showOptions: [
              { value: "all", text: this.$t('Shared.All') },
              { value: "true", text:this.$t('Shared.Active') },
              { value: "false", text: this.$t('Shared.Inactive') },
            ],
            showPatientIsActiveModel: {
              "value":"true",
              "text":this.$t('Shared.Active')
            },
            showIsActiveAdmissionModel: {
              "value":"true",
              "text":this.$t('Shared.Active')
            }               
        }
    },
    methods: {

        onShowing(e) {
          let contentElement = e.component.content();
          let titleElement = e.component._$title[0]
          contentElement.style.padding = '0px';
          titleElement.style.padding = '0px 20px'
        },

        onHiddenReport() {
          this.isReportVisible = false
          if(!this.filterView)
            this.closeWindow();
        },

        onClickClose() {
          this.closeWindow()
          this.isParamVisible = false;
        },

        onClickShowReport() {
          this.isParamVisible = false

          this.reportParams = []
          this.reportParams.push({ paramName: 'pStartDate', paramValue: this.dateFrom})
          this.reportParams.push({ paramName: 'pEndDate', paramValue: this.dateTo})
          this.reportParams.push({ paramName: 'pPatientCode', paramValue: this.patientCode })
          this.reportParams.push({ paramName: 'pRecNo', paramValue: this.recNo})
          this.reportParams.push({ paramName: 'pSeverity', paramValue: this.Allergy.problemSeverity})
          this.reportParams.push({ paramName: 'pPatientCodeList',paramValue: (this.params.PatientCodeList.length == 0) ? this.patientCode :this.params.PatientCodeList })
          this.reportParams.push({ paramName: 'pAllergyCodeList', paramValue:this.params.AllergyCodeList })
          this.reportParams.push({ paramName: 'pShow', paramValue: this.params.Show})
          this.reportParams.push({ paramName: 'pPatientName', paramValue: this.paramName})
          this.reportParams.push({ paramName: 'pIsActive', paramValue: this.params.IsActive})
          this.reportParams.push({ paramName: 'pIsActiveAdmission', paramValue: this.params.IsActiveAdmission})

          this.isReportVisible = true
          this.$refs.PatientAllergy.buildDocument(this.reportParams)
        },
        ChangeDropDownPatients(values) {
          this.params.PatientCodeList = this.getItemsByComma(values, 'patientcode')
        },

        customLabelPatients ({ patientName, patientcode }) {
          return `${patientName} – ${patientcode}`
        },

        getPatients() {              
          PatientService.getPatientList(this.parameter)
            .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.PatientsList = response.data; 
              this.PatientsList.forEach(function(element) {
                  element.patientName = element.firstname + " " + element.lastname;   
                });
              }      
            })
        },
        reportList() {
            this.$router.push({ name: "reportListPatient" }).catch(()=>{});
        },
        getItemsByComma(values, field) {
          let len = values.length;
          let valuesSeparated = [];
          if (len > 0){
            var i = 0;
            values.forEach(function(e) {   
              if(i < len-1){
                valuesSeparated += e[field]+",";
              }else{
                valuesSeparated += e[field];
              }
              i++;                    
            });
            return valuesSeparated;
          } else {
            return [];
          }
      },

      getAllergiesProblemSeverities(){
        AllergyProblemSeveritiesService.getAll()
          .then((response) => {
              if(response.status == '200' && response.data){
                this.AllergySeverity = response.data;
              }
          })
      },

      getAllergies() { 
        AllergyService.getAll(this.parameter)
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.AllergiesList = response.data;
            }
          })
      },
      ChangeDropDownAllergySeverity(value){
          this.Allergy.problemSeverity = value ? value.description : '';
      },

      ChangeDropDownAllergies(values){
          this.params.AllergyCodeList = this.getItemsByComma(values, 'allergyCode')
      },
      customLabelAllergy ({ allergy }) {
          return `${allergy}`
      },
      ChangeShow(item) {
        this.params.Show = item.value
      },
      async loadContents() {
          await this.getAllergiesProblemSeverities();
          await this.getAllergies();
         
      },
      ChangeIsActive(item) {
        this.params.IsActive = item.value
      },
      ChangeIsActiveAdmission(item) {
        this.params.IsActiveAdmission = item.value
      }

    },

  created() {
    this.getPatients();
  },
  mounted () {
    this.loadContents()
    if(!this.filterView)
        // this.isReportVisible = true
        this.onClickShowReport();

      this.OptionsRightMenu.ItemsList = [];
      let layoutStatus = this.$store.state.layoutStatus;
      switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
  },
})
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
