<template>
   <div id="ContainerUser">
      <div class="row">
          <div class="col-12">
            <h4 class="page-title text-truncate"> {{$t('userHeader.uSetup')}}</h4>
            <div class="row">
                <div class="col-sm-12 col-md-3" v-if="permission.length > 0 && permission[0].roleCanAdd">                      
                    <MdButton
                      v-bind:Label="$t('userHeader.Add')" 
                      v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                      v-bind:IconClass="'ti-plus pr-1'"
                      v-on:click="AddUser"	
                      v-bind:Name="'btn_add_users'">
                    </MdButton>
                </div>
                <div v-bind:class="permission.length > 0 && permission[0].roleCanAdd ? 'col-sm-12 col-md-6 mb-4':'col-sm-12 col-md-9 mb-4'">
                    <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                        <div class="input-group">
                            <input 
                              type="text" 
                              class="form-control"
                              v-model="parameter.SearchCriteria"   
                              @keyup.enter="SearchUsers"                            
                            >
                            <div class="input-group-append">
                                <div class="input-group-append">
                                <MdButton 
                                  v-bind:Name="'btnsearchinput'"
                                  v-bind:VariantProp="'btn btn-sm search-attached'"
                                  v-bind:IconClass="'mdi mdi-magnify'"
                                  v-on:click="SearchUsers">
                                </MdButton>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3">                      
                  <MdSelect
                  v-bind:Label="$t('Shared.Status')"
                  v-bind:Options="ListStatus"
                  v-bind:ModelArray="status"
                  v-bind:FieldDescription="'text'"
                  v-bind:FieldCode="'value'"                      
                  :Input="ChangeStatus"/>
              </div>
            </div>              
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <div class="row">
            <div class="col-md-12">
              <MdTable 
                v-bind:fields="fields"
                v-bind:items="items"
                v-bind:totalRows="totalRows"
                v-bind:isReadOnly="isReadOnly"
                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                v-bind:currentPage="currentPage"
                v-bind:perPage="perPage"
                v-bind:pageOptions="pageOptions"
                v-bind:FieldsDate="FieldsDateArr"
                v-bind:VisibleEditIcon="this.permission.length && this.permission[0].roleCanEdit ? true : false"
                :editIconEvent="EditUser"               
                :onRowSelected="onRowSelected"
                :onFiltered="onFiltered"
                :pageChanged="pageChanged"
                :perPageFunction="PerPageFunction">
              </MdTable>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import UsersService from '@/services/users.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import EnumService from '@/services/enum.service'
import Utils from '@/common/utils'
//import { EventBus } from '@/event-bus.js';

export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },

  mixins: [ShowMessage, DBFunctions],
  name: 'Users',
  data() {
    return {
      patient:{},
      permission:[],
      Menu : this.$store.state.leftMenu,  
      userCode:"",
      ShowMessageIsEmpty:false,
      Loading:true,
      Mode:'',
      ListStatus:EnumService.PatientListStatus,
      status:{
        selected:true,
        value:true,
        text:this.$t('Shared.Active')
      },  
      FieldsDateArr : ['expirationDate'],
      OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },        
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0 ,
        PatientCode:''             
      },
      fields: [
        { key: 'userName', sortable: true, label: this.$t('doctor.UserName'), class: 'text-rigth' },       
        { key: 'userCode', sortable: true, label: this.$t('employee.UserID'), class: 'text-rigth' },
        { key: 'userRoleName', sortable: true, label: this.$t('userHeader.Role'), class: 'text-rigth' },
        { key: 'expirationDate', sortable: true, label: this.$t('userHeader.pExpDate'), class: 'text-rigth' },
        { key: 'isSupervisor', sortable: true, label: this.$t('userHeader.IsSupervisor'), class: 'text-rigth',formatter: value => {return value ? 'Y' : 'N'} },
        { key: 'isActive', sortable: true, label: this.$t('Shared.Status'), class: 'text-rigth',formatter: value => {return value ? 'Active' : 'Inactive'} },
        ],
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      isReadOnly: false
    }
  },

  computed: {
      sortOptions() {       
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      }    
  },
    
  methods: {

    
  CanView(){
      return this.permission[0].roleCanView;
  },

    AddUser(){
	if(this.permission[0].roleCanAdd){
		this.Mode = "Add";   
		this.$router.push({ name: "users-add", params: { id: this.$route.params.id } }).catch(()=>{});
	}else{
		this.showMessage(this.$t('ActList.User'), this.$t('userHeader.pAddMsg'), "errorAutoHide"); 
	}
    },

    SearchUsers(){
      this.$emit('load', true);
      this.items = [];
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getListing();
    },

     EditUser(row){
       this.userCode = row.userCode;
       this.Mode = "Edit";
       this.$router.push({ name: "users-details", params: { id: this.userCode } });       
    },


    /*RemoveUser(row){
      const Id = row.userCode
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
            title: this.$tc('Shared.DelRecord'),
            okTitle: this.$t('Shared.TitleDelete'),
            cancelTitle: this.$t('Shared.BtnCancel'),
            footerClass: 'p-2',
            titleClass: 'modal-title modal-type-warning',
            id:'modal_delete_user'
        })
        .then(value => {
            if(value){
                UsersService.delete(Id)
                .then(() => {
                    const index = this.items.findIndex(item => item.userCode === Id) // find the post index 
                    if (~index) {// if the post exists in array 
                    this.items.splice(index, 1) //delete the post and refresh view
                    this.getListing(); //refresh listing
                    }
                })
                .catch((error) => {
                    if (!error.response) {
                    // network error
                    this.showMessage("Doctor Setup",this.$t('Msg.NotAbleToDelete'),"error");
                    } else {
                    this.showMessage("Doctor Setup",error,"error");
                    }
                }); 
                }
            })
    },*/

    getListing(){
        this.$emit('load', true);
        this.parameter.PageNumber = this.currentPage;           
        this.parameter.PageSize = this.perPage;
        this.parameter.Status = this.status.value;      
        UsersService.getAll(this.parameter)
            .then((response) => {
                if(response.status == '200'){
                  this.$emit('load', false);
                  if(response.data.length > 0) {
                      this.items = response.data;
                      this.items.forEach(function(element) {
                        if (element.expirationDate)	
                          element.expirationDate = Utils.formatterDateToString(element.expirationDate); 
                      });
                       let headerString = response.headers["x-pagination"];
                       this.totalRows = JSON.parse(headerString).TotalCount;
                    } else {
                        this.ShowMessageIsEmpty = true;
                    }                        
                }else {
                  this.$emit('load', false);
                  this.ShowMessageIsEmpty = true;
                }
            })
            .catch((error) => {
            if (!error.response) {
                this.showMessage(this.$t('ActList.User'),this.$t('Msg.NotAbleToSave'),"error");
            }                      
            else if(error.response.status === 400 ){
              this.showMessage(this.$t('ActList.User'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 405 ){
                   this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50); 
            } 
            else if(error.response.status === 500){
              this.showMessage(this.$t('ActList.User'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
              this.showMessage(this.$t('ActList.User'),error.response.data.errorMessage,"error");
            } 
            this.$emit('load', false);
            });  
    },

    pageChanged (page) {            
      this.currentPage = page;    
      this.getListing();
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.getListing();
    },

    onRowSelected(items) {    
     this.selected = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    ChangeStatus(status){
     // this.status.value = status ? status.value : '';
      this.status = { value:''};
      if(status){
           this.status.value = status.value;
           this.status.text = status.text;
           this.status.selected = status.selected;
      }
      this.items = [];
      this.totalRows = 0;
      this.getListing();
    }
  },

  created(){
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);  
  },

 /* beforeCreate(){
    if(this.permission.length === 0){
          this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50); 
    }
  },*/

   mounted() {  
     this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }       
      this.getListing();   
  },
};

</script>