<template>
    <div>
     <b-modal size="xl" :id="ModalId" :visible="true" centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalEditIntervention" @close="this.emitEventCancel" @ok="handleOk" :ok-title="'Save'"  @cancel="this.emitEventCancel"> 
        <form ref="form_IntvEdit" @submit.stop.prevent="handleSubmit">
      <b-row>
        <b-col>
            <div class="textlabel" data-label="Description"></div>
            <MdMemo v-bind:Name="'intvdescription'" v-bind:Rows="'4'" v-bind:UseDinamicClass="true"
                v-bind:DynamicClass="'modalTextarea ml-2'" v-bind:MaxRows="'6'"
                v-model="IntEdit.intDesc" />
        </b-col>
    </b-row>

  <b-row> 
      <b-col lg="2">
          <MdEdit v-bind:Label="'Start Date'" v-bind:Name="'StartDate'" v-bind:TypeProp="'text'"
              v-bind:Mask="'##/##/####'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"
              v-model="IntEdit.startDate" />
      </b-col>
      <b-col lg="1">
          <MdEdit v-bind:Label="'No'" v-bind:TypeProp="'number'" v-bind:Name="'No'"
              v-model.number="IntEdit.noAmt">
          </MdEdit>
      </b-col>

      <b-col lg="2">
          <MdSelect v-bind:Label="'Amount'" v-bind:Options="AmountList"
              v-bind:FieldDescription="'description'" v-bind:FieldCode="'code'"
              v-bind:Model="IntEdit.amountObj" v-bind:Multiple="false" :Input="ChangeDropDownAmount"
              v-bind:Name="'status_amount_select'" :id="'status_amount_select'" />
      </b-col>
      <b-col lg="1">
          <MdEdit v-bind:Label="'No'" v-bind:TypeProp="'number'" v-bind:Name="'No'"
              v-model.number="IntEdit.noFrequency">
          </MdEdit>
      </b-col>

      <b-col lg="2">
          <MdSelect v-bind:Label="'Frequency'" v-bind:Options="FrequencyList"
              v-bind:FieldDescription="'description'" v-bind:FieldCode="'code'"
              v-bind:Model="IntEdit.frequencyObj" v-bind:Multiple="false" :Input="ChangeDropDownFrequency"
              v-bind:Name="'status_frequency_select'" :id="'status_frequency_select'" />
      </b-col>
  
      <b-col lg="1">
          <MdEdit v-bind:Label="'No'" v-bind:TypeProp="'number'" v-bind:Name="'No'"
              v-model.number="IntEdit.noDuration">
          </MdEdit>
      </b-col>

      <b-col lg="2">
          <MdSelect v-bind:Label="'Duration'" v-bind:Options="DurationList"
              v-bind:FieldDescription="'description'" v-bind:FieldCode="'code'"
              v-bind:Model="IntEdit.durationObj" v-bind:Multiple="false" :Input="ChangeDropDownDuration"
              v-bind:Name="'status_duration_select'" :id="'status_duration_select'" />
      </b-col>
  </b-row>

  <b-row>
    <b-col>
        <div class="textlabel" data-label="Modalities"></div>
        <div v-if="TherapyList.length === 0" class="empty-row-banner m-3"><div class="text-center my-2">There are no modalities to show</div></div>
        <MdCheckListBox2 v-if="TherapyList.length > 0" class="mx-1" v-model="IntEdit.therapyCodes" v-bind:Options="TherapyList"
            v-bind:OnChangeProp="OnChangeModalities" v-bind:columnCount="1" :textField="'name'" v-bind:Height="'155px'"
            :valueField="'code'" />
    </b-col>
</b-row>
        </form>
       
     </b-modal>  
    </div>
 </template>
 
 <script>
 import Utils from '@/common/utils'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import TherapyService from '@/services/therapy.service'
 import moment from 'moment'
 import TreatmentPlan from '@/services/treatmentplan.service'
 

 export default {
   name: 'ModalEditIntervention',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     ProblemRecId:String,
     groupId:Number,
     headerId:String,
     probCode:String,
     IntEditModal:Object
   },
   
   data(){
     return {  
        IntEdit:{...this.IntEditModal},    
        goaldescriptionBlank:"",
        therapyModel:[],
        TherapyList:[],
        description:"",
        startDate:"",
        frequency:"",
        duration:"",
        noDuration:"",
        noAmt:"",
        noFrequency:"",
        amount:"",
        StatusList: [
                { code: "INPROGRESS", description: "INPROGRESS" },
                { code: "MET", description: "MET" },
                { code: "UNMET", description: "UNMET" },
                { code: "REVISED", description: "REVISED" },
                { code: "EXTENDED", description: "EXTENDED" },
                { code: "DEFERRED", description: "DEFERRED" },
                { code: "REFERRED", description: "REFERRED" }
            ],
            AmountList: [
                { code: "SEC", description: "SEC" },
                { code: "MIN", description: "MIN" },
                { code: "HOUR", description: "HOUR" },
                { code: "DAY", description: "DAY" },
            ],
            DurationList: [
                { code: "SEC(S)", description: "SEC(S)" },
                { code: "MIN(S)", description: "MIN(S)" },
                { code: "HOUR(S)", description: "HOUR(S)" },
                { code: "DAY(S)", description: "DAY(S)" },
                { code: "WEEK(S)", description: "WEEK(S)" },
                { code: "MONTH(S)", description: "MONTH(S)" },
                { code: "YEAR(S)", description: "YEAR(S)" },
            ],
            FrequencyList: [
                { code: "HOUR", description: "HOUR" },
                { code: "DAY", description: "DAY" },
                { code: "WEEK", description: "WEEK" },
                { code: "BIWEEK", description: "BIWEEK" },
                { code: "MONTH", description: "MONTH" },
                { code: "QUARTER", description: "QUARTER" },
                { code: "YEAR", description: "YEAR" },
                { code: "AS ORDER", description: "AS ORDER" },
            ],

     }
   },
 
   methods:{
     emitEventOK(/*bvModalEvent*/){
        this.UpdIntervention();
     },

     handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault();      
        this.handleSubmit()
      },

    checkFormValidity() {    
        if(this.IntEdit.startDate !== "" && this.IntEdit.startDate !== null){
            return Utils.isValidDate(this.IntEdit.startDate) && Utils.IsValidDateAndRange(this.IntEdit.startDate);
        }else{
            return true;
        }    
      
      },

    handleSubmit() {       
        !this.checkFormValidity() ? this.showMessage('Update Intervention','Invalid start date',"error"):this.emitEventOK();
      },

     ChangeDropDownFrequency(value){
        this.IntEdit.frequencyObj = value ? value : {code:"",description:""};
     },

     ChangeDropDownAmount(value){
        this.IntEdit.amountObj = value ? value : {code:"",description:""};
     },

     ChangeDropDownDuration(value){
        this.IntEdit.durationObj = value ? value : {code:"",description:""};
     },

     UpdIntervention(){        
          let data = {          
            intDesc:this.IntEdit.intDesc,
            startDate:this.IntEdit.startDate ? moment(this.IntEdit.startDate).format():null,
            amount:this.IntEdit.amountObj.code,
            noAmt:this.IntEdit.noAmt,
            frequency:this.IntEdit.frequencyObj.code,
            noFrequency:this.IntEdit.noFrequency,
            duration: this.IntEdit.durationObj.code,
            noDuration: this.IntEdit.noDuration,
            therapyCodes:this.IntEdit.therapyCodes
         
          };
         TreatmentPlan.UpdateIntervention(this.IntEdit.intRecId,data)
            .then((response) => { 
               if(response.data){
                this.$emit('RefreshGetTP');
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 5); 
               }             
            })
            .catch((error) => {
                if (!error.response) {
                        this.showMessage('Update Intervention',this.$t('Msg.NotAbleToUpdate'),"error");
                }     
                   else if(error.response.status === 400 ){
                     this.showMessage('Update Intervention',error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage('Update Intervention',this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage('Update Intervention',error.response.data.errorMessage,"error");
                     }    
            });
       
    },

     async getTherapies() {
            this.$emit('load', true);
            await TherapyService.getTherapyTreatmentPlan(this.$route.params.recordid)
                .then((response) => {
                    if (response.status == '200' && response.data) {
                        this.TherapyList = response.data;    
                    }
                    this.$emit('load', false);
                })
                .catch(error => {
                    this.$emit('load', false);
                    if (!error.response) {
                        this.showMessage("Treatment Plan", this.$t('Msg.retrieveInfo'), "error");
                    }
                    else if (error.response.status === 400) {
                        this.showMessage("Treatment Plan", error.response.data.errorMessage, "error");
                    } else if (error.response.status === 500) {
                        this.showMessage("Treatment Plan", this.$t('Msg.ThereWereSomeError'), "error");
                    } else {
                        this.showMessage("Treatment Plan", error.response.data.errorMessage, "error");
                    }
                });
        },

     OnChangeGoals(){

     },

     OnChangeModalities(){

     },


     emitEventCancel(){
      this.$emit('ModalTPEditIntervention');
    },    
   },
 
   async mounted(){
    await this.getTherapies();
   },
 };
 </script>

