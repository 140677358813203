<template>
    <div id="ReportPatientInsurance">
      <div v-if="this.showModal">
        <MdTitle v-bind:Text="$t('PatientInsurancesRep.RepTitle')" />
        <b-row>
            <b-col lg="4" md="4" sm="12">         
              <MdSelect
                v-bind:Label="$tc('Shared.Patient',2)"
                v-bind:Options="PatientsList"
                v-bind:Model="PatientCodeModel"
                v-bind:FieldDescription="'patientName'"
                v-bind:FieldCode="'patientcode'"
                v-bind:Multiple="true"             
                :Input="ChangeDropDownPatients"
                :CustomLabel="customLabelPatients"/>
            </b-col>
            <b-col lg="4" md="4" sm="12">         
              <MdSelect
                v-bind:Label="$tc('Insurance.Insurance')"
                v-bind:Options="Insurances"
                v-bind:Model="insCode"
                v-bind:FieldDescription="'company'"
                v-bind:FieldCode="'insurancecode'"
                v-bind:Multiple="true"             
                :Input="ChangeDropDownInsurances"/>
            </b-col>
            <b-col lg="4" md="4" sm="12">         
              <MdSelect
                v-bind:Label="$t('Insurance.InsType')"
                v-bind:Options="InsuranceTypesList"
                v-bind:FieldDescription="'description'"
                v-bind:FieldCode="'code'"
                v-bind:Model="insType"
                v-bind:Multiple="true"
                :Input="ChangeDropDownInsType"
                v-bind:Name="'ins_type_select'"/>
            </b-col>
          </b-row>

        <div class="page-actions">
          <MdButton
            v-bind:Label="$t('Shared.Back')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="reportList"
            v-bind:Name="'btn_ReportList'"/>
          <MdButton
            v-bind:Label="$t('Shared.Report')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="onClickShowReport"
            v-bind:Name="'btn_Print'"/>   
        </div>
      </div>
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="$t('PatientInsurancesRep.RepTitle')"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="patientInsurances"
          :ReportName="'PatientInsurances'"
          :ReportTitle="$t('InsurancesList.PatientInsurance')"
          :IsAnAPIReport="true"
        />
    </DxPopup>
  </div>
</template>

<script>
import Vue from 'vue'
import { DxPopup } from 'devextreme-vue/popup';
import ShowMessage from '@/components/messages/ShowMessage.js';
import InsuranceService from "@/services/insurance.service";
import PatientService from '@/services/patient.service';
import Utils from '@/common/utils'

export default Vue.extend({
    name: "PatientInsurancesRep",
    mixins: [ ShowMessage, Utils ],
    components: { DxPopup },
    props: {
      PatientCode: String,
      PatientCodeList: String,
      closeWindow: Function,
      RecNo: Number,
      showModal: Boolean
    },
    data() {
        return {
          OptionsRightMenu:{
              visible:false,
              ItemsList:[],
              btnvisible:false,
              itemdisabled: false,
              PatientCode:'',
              Mode:this.edit_mode,
              favButtonAtion:false          
            },
            isParamVisible: true,
            isReportVisible: false,
            reportParams: [],
            PatientsList:[],
            params: {
              "PatientCodeList": [],
            },
            parameter:{
              SearchCriteria:'',
              PageNumber : null,
              PageSize:null,
              Status: true,
            },
            PatientCodeModel: null,
            InsuranceTypesList: [],
            insType: null,
            insCode: null,
            company: null,
            isInsCodeValid: true,
            Insurances: []
        }
    },
    methods: {

      onShowing(e) {
        let contentElement = e.component.content();
        let titleElement = e.component._$title[0]
        contentElement.style.padding = '0px';
        titleElement.style.padding = '0px 20px'
      },

      onHiddenReport() {
        this.isReportVisible = false
        if(!this.showModal)
          this.closeWindow();
      },

      onClickClose() {
        this.closeWindow()
        this.isParamVisible = false;
      },

      reportList() {
        this.$router.push({ name: "reportListPatient" }).catch(()=>{});
      },
      onClickShowReport() {
        this.isParamVisible = false

        this.reportParams = []
        this.reportParams.push({ paramName: 'PatientCode', paramValue: this.PatientCode})
        this.reportParams.push({ paramName: 'RecNo', paramValue: this.RecNo})
        this.reportParams.push({ paramName: 'ViewReports', paramValue: this.showModal})
        this.reportParams.push({ paramName: 'InsTypeList', paramValue: this.insType})
        this.reportParams.push({ paramName: 'InsuranceCodeList', paramValue: this.insCode})
        this.reportParams.push({ paramName: 'PatientCodeList', paramValue: this.PatientCodeModel })
        
        this.isReportVisible = true
        this.$refs.patientInsurances.buildDocument(this.reportParams)
      },
      getItemsByComma(values, field) {
        let len = values.length;
        let valuesSeparated = [];
        if (len > 0){
          var i = 0;
          values.forEach(function(e) {   
            if(i < len-1){
              valuesSeparated += e[field]+",";
            }else{
              valuesSeparated += e[field];
            }
            i++;                    
          });
          return valuesSeparated;
        } else {
          return [];
        }
      },

      ChangeDropDownPatients(values) {
        this.PatientCodeModel = values ? this.getItemsByComma(values, 'patientcode') : null
      },

      ChangeDropDownInsurances(values) {
        this.insCode = values ? this.getItemsByComma(values, 'insurancecode') : null
      },

      ChangeDropDownInsType(value) {
        this.insType = value ? this.getItemsByComma(value, 'code') : null
      },

      getInsuranceCodeValues(value) {
        this.insCode = value.code;
        this.company = value.description;
      },

      customLabelPatients ({ patientName, patientcode }) {
        return `${patientName} – ${patientcode}`
      },

      getPatients() {              
        this.parameter.PageSize = this.perPage;
        this.parameter.PageNumber = this.currentPage;        
          PatientService.getAll(this.parameter)
          .then((response) => {
          if(response.status == '200' && response.data && response.data.length > 0){
            this.PatientsList = response.data; 
            this.PatientsList.forEach(function(element) {
                element.patientName = element.firstname + " " + element.lastname;   
              });
            }      
            this.$emit('load', false);
          })
          .catch(error => {
            this.$emit('load', false);
            if (!error.response) {
                // network error
                this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.retrieveInfo'),"error");
            } else {
                this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
            }
          });
      },

      getInsuranceTypesList() {
        InsuranceService.getPatientInsuranceTypes()
        .then((response) => {
          if (response.data) {
            this.InsuranceTypesList = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$tc('Insurance.Insurance',1), error, "error");
          }
        });
      },

      getInsurances() {
        InsuranceService.getAllInsurance()
        .then((response) => {
          if (response.data) {
            this.Insurances = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$tc('Insurance.Insurance',1), error, "error");
          }
        });
      },
    },
    created() {
      this.getPatients();
      this.getInsuranceTypesList()
      this.getInsurances()
    },
    mounted () {
      if(!this.showModal)
        this.onClickShowReport();

      this.OptionsRightMenu.ItemsList = [];
      let layoutStatus = this.$store.state.layoutStatus;
      switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
    },

  })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
