<template>
  <div :id="targetId" oncontextmenu="return false;">
    <div v-if="!this.$attrs.control.isReadOnly && !NoteIsVoid && !this.$attrs.IsChartReview && !this.isReadOnly">
      <ejs-contextmenu :id="targetId" :target="menuItems.length > 0 ? menuTargetId : null" :items='menuItems' :select='onSelect'></ejs-contextmenu>
    </div>
          <ckeditor
            v-b-tooltip.hover :title="Hint"
            v-model="Value" 
            :config="editorConfig"
            :disabled="this.$attrs.control.isReadOnly || NoteIsVoid || this.isReadOnly"
            :read-only="this.$attrs.control.isReadOnly || NoteIsVoid || this.$attrs.control.IsChartReview || this.isReadOnly"
            @input="onEditorInput"
            @blur="onEditorBlur"
            :editor-url="editorUrl"
            v-bind:style="style"
            :class="componentClass"
            @ready="onEditorReady"
            :id="controlID">
          </ckeditor>
    <MdPatientDiagCodes v-if="activateModalDiagCodes"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      v-bind:DateFrom="this.DateFrom"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdPatientDiagCodes>
    <MdPatientInsurances v-if="activateModalInsurance"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdPatientInsurances>
    <MdPatientContacts v-if="activateModalContacts"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdPatientContacts>
    <MdPatientAllergiesOutCome v-if="activateModalAllergies"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdPatientAllergiesOutCome>
    <MdGenericOutCome v-if="activateModalGeneric"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      v-bind:outComeCode="outComeCode"
      v-bind:outComeDescription="outComeDescription"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdGenericOutCome>
    <MdPainAssessmentNotesOutCome v-if="activeModalPainAssessment"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      v-bind:outComeCode="outComeCode"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdPainAssessmentNotesOutCome>
    <MdPatientVitalSingOutCome v-if="activeModalVitalSign"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      v-bind:outComeCode="outComeCode"
      v-bind:ComponentKind="'RichEdit'"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdPatientVitalSingOutCome>
    <MdGetLastAnswers v-if="showGetLastAnswers"
      @getCancel="onCancelGetLastAnswers($event)"
      @getOK="onOKGetLastAnswers($event)"
      v-bind:ControlId="controlID"
      v-bind:PatientCode="PatientCode"
      v-bind:TransDate="TransDate"
    />
    <!-- <MdTextSpeech></MdTextSpeech> -->
  </div>
</template>

<script>
  import Utils from '@/common/utils.js'
  import { EventBus } from '../../event-bus.js';
  import GlobalService from '@/services/global.service'
  import Vue from 'vue';
  import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";

  Vue.use(ContextMenuPlugin);
  export default {
    mixins: [EventBus],
    props: {     
     MaxRows: String,
     Rows: String,
     MaxLength: String,
     isReadOnly:Boolean,
     value:[String],
     Name:String,
     Placeholder:String,
     TransDate: String,
     NoteId: String,
     DateFrom:String,
     id: [String, Number],
     HideToolBar: Boolean,
     PatientCode: String,
     IsCommonPart: Boolean
   },
    data() {
      return {
         Value: this.value,
          NoteIsVoid:false,        
         style: {
          border: 'solid 1px #e0e0e0 !important',
          position:'absolute',
          top: this.$attrs.control.controlTop+'px',          
          left:this.$attrs.control.controlLeft+'px',
          borderColor: '#e0e0e0 !important',
          textAlign:'',         
          borderRadius: '5px',
        },
         Hint: this.$attrs.control.controlHint,
         controlID:String(this.$attrs.control.controlID),
         outComeDescription: Utils.strToTitleCase(this.$attrs.control.outComeDescription),
         outComeTemplateCode:this.$attrs.control.outComeTemplateCode,
         outComeTitle: Utils.strToTitleCase(this.$attrs.control.outComeDescription),
         outComeCode:this.$attrs.control.outComeCode,
         Invalid:false,
         isRequired: this.$attrs.control.isRequired,
         activateModalInsurance:false,
         activateModalContacts:false,
         activateModalDiagCodes:false,
         activateModalAllergies:false,
         activateModalGeneric:false,
         activeModalPainAssessment:false,
         activeModalVitalSign:false,
         showGetLastAnswers: false,
         //PatientCode: this.$route.params.id,
         menuItems:[],
         targetId: "target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
         menuTargetId:"#target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
         //componentClass: this.$attrs.control.outComeCode ? 'outcome' : 'cursor-context-menu',
          componentClass: this.$attrs.control.outComeCode ? 'cutescroll cursor-context-menu outcome' : 'cutescroll cursor-context-menu',
         editorUrl: '/ckeditor/ckeditor.js',
         isLoadingCkeditor: true,
         editorConfig: {
          controlId: this.$attrs.control.controlID,
          noteId: this.NoteId,
          hideTB: this.HideToolBar,
          bodyId: 'ckeditorBody'+this.$attrs.control.controlID,
          on: {
                instanceReady : function ( evt )
                {
                  //Hide toolbar
                  if (evt.editor.config.hideTB) {
                    let edId = evt.editor.id.split('_')[1]
                    document.getElementById( `cke_${edId}_top` ).style.display = 'none';
                  }
                },

                paste: async function(evt) {
                  let rtfData = evt.data.dataTransfer.getData( 'text/rtf', true);
                  const filesCount = evt.data.dataTransfer.getFilesCount()
                  const textData = evt.data.dataTransfer.getData( 'Text', true);
                  const htmlData = evt.data.dataTransfer.getData( 'text/html', true);
                  let rtfContent= { rtf:[] }
                  if(!textData && !htmlData && filesCount > 0){
                    let imagen = evt.data.dataTransfer.getFile(0);
                    let reader = new FileReader();
                    reader.onload = function(e) {
                      let element = evt.editor.document.createElement('img', {
                        attributes: {
                          src: e.target.result
                        }
                      });
                      evt.editor.insertElement(element);             
                    };
                    reader.readAsDataURL(imagen);
                  }

                  if(filesCount == 0 && rtfData && !htmlData){
                    evt.cancel();
                    rtfData = rtfData.replaceAll(/\\\*\\fldinst HYPERLINK .*"/g,'');
                    rtfData = rtfData.replaceAll('>', '');
                    rtfData = rtfData.replaceAll('<', '');
                    rtfContent.rtf[0] = rtfData
                    await GlobalService.RtfToHtml(rtfContent)
                      .then(( response) => {
                        if(response.status == '200'){
                            this.htmlContent = response.data.html[0];
                            evt.editor.insertHtml(this.htmlContent);
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                          evt.cancel();
                        }
                      });
                  }
                  var changed = {
                    "ControlId":this.config.controlId,
                    "Changed":true,
                    "NoteId":this.config.noteId
                  }
                  EventBus.$emit('onControlWasChanged', changed);
                }
              },
          title: false, 
          toolbar: [ 
            {
              name: 'basicstyles',
              items: ['Bold','Italic','Underline','Strike','-','NumberedList','BulletedList']
            },
            {
              name: 'color',
              items: ['TextColor','BGColor']
            },
            {
              name: 'insert',
              items: ['Table','Image']
            },
          ],
          width: this.$attrs.control.controlWidth,
          height: this.$attrs.control.controlHeight,
          extraPlugins: 'tableresize,divarea',
          removePlugins: 'save,smiley,forms,flash,templates,print,preview,iframe,find,exportpdf,\
                          specialchar,pagebreak,wsc,scayt,link,liststyle,div,a11yhelp,\
                          about,language,indentblock,font,bidi,removeformat,sourcearea,\
                          maximize,htmlwriter,horizontalrule,format,resize,showblocks,\
                          stylescombo,blockquote,copyformatting,selectall,newpage,justify,elementspath',
          removeButtons: 'Subscript,Superscript,Cut,Copy,Outdent,Indent,PasteText,PasteFromWord',
          readOnly: this.$attrs.control.isReadOnly || this.NoteIsVoid || this.isReadOnly,
         },
         cmpLoaded:false
      }
    },

  methods:{

     deActivateModal(){
       if(this.outComeTemplateCode === 'TFRMMDPATIENTCONTACTS')
       this.activateModalContacts = false
      else if(this.outComeTemplateCode === 'TFRMPatientInsurances')
       this.activateModalInsurance = false
      else if(this.outComeTemplateCode === 'TFRMPatientDiagCodes')
       this.activateModalDiagCodes = false
      else if(this.outComeTemplateCode === 'TFRMALLERGIESEHR')
       this.activateModalAllergies = false
      else if(this.outComeTemplateCode === 'TfmMdNoteOutComeStudies')
       this.activateModalGeneric = false
      else if(this.outComeTemplateCode === 'TFRMMdPainAssessmentNotes')
       this.activeModalPainAssessment = false
      else if(this.outComeTemplateCode === 'TFRMMdVitalSignsNotes')
       this.activeModalVitalSign = false
     },

     onCancel(){
       this.deActivateModal()
     },

     onEditorInput(value){
      this.$emit('input',value);
      if (!this.isLoadingCkeditor) {
        this.Invalid = value.length == 0 && this.$attrs.control.isRequired ? true : false;
        this.style.borderColor = this.Invalid ? '#dc3545 !important': '#e0e0e0 !important'
        this.setChangedStatus()
      }
     },

     onCodeConditionOk(result) {
       if(result) {
          this.Value = result;
          this.$emit('input',result);
          this.setChangedStatus()
       }
       this.deActivateModal()
     },

       InitializeVariables(){
      // this.style.backgroundColor = this.$attrs.control.isReadOnly ? '#e9ecef !important' :'';
        if(this.$attrs.control.isReadOnly || this.isReadOnly){
         // this.style.backgroundColor = '#dfe4eb !important';
         this.style.backgroundColor = '#e9ecef !important';
        }else{
           this.style.backgroundImage = 'linear-gradient(130deg, white, rgb(241, 253, 255))';
        }
        this.cmpLoaded = 'MdTextSpeech' in Vue.options.components  
       },

     onEditorBlur(){
       this.$emit('blur',this.Value)
       this.Invalid = this.Value.length == 0 && this.isRequired ? true : false;
       this.style.borderColor = this.Invalid ? '#dc3545 !important': '#e0e0e0 !important'
     },

    showModal() {
      if(this.outComeTemplateCode === 'TFRMMDPATIENTCONTACTS')
       this.activateModalContacts = true
      else if(this.outComeTemplateCode === 'TFRMPatientInsurances')
       this.activateModalInsurance = true
      else if(this.outComeTemplateCode === 'TFRMPatientDiagCodes')
       this.activateModalDiagCodes = true
      else if(this.outComeTemplateCode === 'TFRMALLERGIESEHR')
       this.activateModalAllergies = true 
      else if(this.outComeTemplateCode === 'TfmMdNoteOutComeStudies')
       this.activateModalGeneric = true
      else if(this.outComeTemplateCode === 'TFRMMdPainAssessmentNotes')
       this.activeModalPainAssessment = true
      else if(this.outComeTemplateCode === 'TFRMMdVitalSignsNotes')
       this.activeModalVitalSign = true  
    },

    showModalGetLastAnswers() {
      this.showGetLastAnswers = true
    },

    onCancelGetLastAnswers(){
      this.showGetLastAnswers = false
    },

    onOKGetLastAnswers(value) {
      this.Value = value
      this.showGetLastAnswers = false
      this.$emit('input',value);
      this.setChangedStatus()
    },

    handlerClick(evt){
      if(!this.$attrs.control.isReadOnly && !this.isReadOnly) {
        this.$refs.menu.open(evt)
      }
    },

    setValid(state){
        this.style.borderColor = state ? '#dc3545 !important': '#e0e0e0 !important'
    },

    setChangedStatus(){
      var changed = {
        "ControlId":this.$attrs.control.controlID,
        "Changed":true,
        "NoteId":this.NoteId
      }
      EventBus.$emit('onControlWasChanged', changed);
    },

    onEditorReady(editor){
      if (editor.contextMenu) {
        editor.removeMenuItem('cut');
        editor.removeMenuItem('copy');
        editor.removeMenuItem('paste');
      }
      this.isLoadingCkeditor = false
    },

    onSelect: function(args ) {
      if(args.item.id === 'gettenlastans') {
        this.showModalGetLastAnswers()
      }
      if(args.item.id === 'outcome') {
        this.showModal()
      }
      if(args.item.id === 'recording') {
          EventBus.$emit('speechBarLabel', 'stop recording');
          this.$root.$refs.MdTextSpeech.toggleSpeechBar()//Utils.toggleSpeechBar();
          this.$root.$refs.MdTextSpeech.startSpeechToText(this.$attrs.control.controlID)
      }
      if(args.item.id === 'speech') {
          EventBus.$emit('speechBarLabel', 'stop speech');
          this.$root.$refs.MdTextSpeech.toggleSpeechBar();
          this.$root.$refs.MdTextSpeech.startSpeech(this.Value)
      }
    },

    loadMenuItems() {
      this.menuItems = []
      if (!this.IsCommonPart) {
        this.menuItems.push({text: this.$t('Shared.GetLast10'), id: "gettenlastans"})
      }
      if(this.outComeDescription) {
        this.menuItems.push({text: this.outComeDescription, id: "outcome"})
      }
      if (this.cmpLoaded) {
        if(this.$root.$refs.MdTextSpeech.isSpeechRecognitionSupported) {
          this.menuItems.push({text: 'Start/Stop Recording', id: "recording"})
        }
        this.menuItems.push({text: 'Start/Stop Speech', id: "speech"})
      }
    },
    setValueS2T( sp ){
      let sp2t = ''
      if(this.value) {
        sp2t += Array.from (this.value + ""+sp).join("");
      } else {
        sp2t = Array.from (sp).join("");
      }
      this.Value = sp2t
      this.$emit('input', sp2t);
      this.setChangedStatus()
    }
  },

  created(){
    EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
      }.bind(this));
  if(this.$attrs.NoteIsVoid){
    this.NoteIsVoid = this.$attrs.NoteIsVoid;
  }
  EventBus.$on("receivedS2T", function (event) {
      if( event.ControlId == this.$attrs.control.controlID){
        this.setValueS2T( event.finalSentence )
      }
    }.bind(this))
},

  mounted(){
    this.InitializeVariables();
    this.loadMenuItems()
  }
}
</script>

<style>
.scroll{
	overflow: hidden !important;
}

.cke_inner, .cke_wysiwyg_frame, .cke_wysiwyg_div{
  background: transparent !important;
}
.cke_chrome {
  border: 0 !important;
}
</style>