<template>
    <div>
        <a :class="{ 'isDisabled': itemdisabled, rmActiveItem : active_el == it.value  }" v-for="it in this.ItemsListAuthorized" :key="it.value" @click="triggerClick(it.value,it.text,$event)">        
                 {{it.text}}    
        </a>        
    </div>   
</template>

<script>
//import Enum from '@/services/enum.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import LocalStorage from '@/services/storage.service'
import AppointmentService from '@/services/appointment.service'
import ApptCheckinsService from '@/services/appointmentcheckins.service'

import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';

export default {
  name: 'Item',
  mixins: [ShowMessage, DBFunctions],
 data(){
    return{
        ItemsList:[],      
        Class:'',       
        itemdisabled : false,
        Appointment:{},
        CheckInsAppt:{
            timeCheckIn:"",
        },
      
        PatientCode : '',
        ItemsListAuthorized:[],
        parameter: {
          UserId: "",
        },
        active_el:'',
        message:this.$t('Layout.displayedScreen')
    }
  },
 
  methods: {    
  
    triggerClick: function (eventName,text,event){        
        if(event.target.className != 'isDisabled'){
          this.active_el = eventName;
          switch(eventName){
            case 'PatientSearch':
                this.GotoPatientList();
                break;
            case 'AppointmentList':
                this.launchPage(this.$t('MdNotesByAppt.Appointment'),'appointments', true);
                break;
            case 'SchedulerNotes':
               // this.launchPage('Scheduler Notes','scheduler-notes', true);
               this.GotoSchedulerNotes();
                break;
            case 'Activities':               
                this.GotoActivities();
                break;
            case 'ShowUpAppt':                    
                this.ShowUpAppt(text);
                break;   
            case 'ConfirmAppt':             
               this.ConfirmAppt(text);
                break; 
            case 'CancelAppt':             
               this.OpenAppointmentReason(text);
                break;         
            case 'PatientInfo':
                this.GotoPatientInfo();
                this.isActive = !this.isActive;
                break;
            case 'GeneralNotes':
                this.GotoGeneralNotes();
                break;
            case 'PatientAsam':
                this.launchPage(this.$t('Asam.ASAM'),'patient-asam', true);
                break;
            case 'PatientContact':
                this.launchPage(this.$tc('Contact.PatientContact',2),'patient-contacts', true);
                break;
            case 'PatientInsurance':
                this.launchPage(this.$tc('Insurance.Insurance'),'patient-insurance', true);
                break;
            case 'PatientDocument':
                this.launchPage(this.$t('employee.Document'),'patient-document', true);
                break;
            case 'ClinicalNotes':
                this.launchPage(this.$t('ClinicalNotes.ClinicalNotes'), 'Clinical-Notes');
                break;
            case 'PatientListClinical':
                this.GotoClinical();
                break;
            case 'ClinicalAsam':
                 this.launchPage(this.$t('Asam.ASAM'), 'Clinical-Asam');
                break;
            case 'PatientAllergy':
                this.launchPage(this.$t('Allergy.PatientAllergies'),'patient-allergies', true);
                break;
            case 'ClinicalVitalSign':
                this.launchPage(this.$t('vitalSignsRep.ReportTitle'), 'Clinical-vital-sign');
                break;
            case 'Medications':
                this.launchPage(this.$t('Medication.Medications'), 'Clinical-Medications');
                break;
            case 'Admission':
                this.launchPage(this.$t('Admission.Admission'),'patient-admissionList', true);
                break;
            case 'CHARTREVIEWCLINICAL':
                this.launchPage(this.$t('ChartReview.CHARTREVIEW'), 'Clinicalchartreview');
                break;
            case 'ePharmacyPatient':
              this.launchPage(this.$t('ePharmacyBase.ePharmacyTitle'),'patientePharmacy', true);
              break;
            case 'ePharmacyClinical':
              this.launchPage(this.$t('ePharmacyBase.ePharmacyTitle'),'clinicalePharmacy', false);
              break;
             case 'CHARTREVIEWPATIENT':
                this.launchPage(this.$t('ChartReview.CHARTREVIEW'),'patientchartreview', true);
                break;
             case 'Transfer':
                this.launchPage(this.$t('transfer.Transfer'), 'Clinical-Transfer'); 
                break;
            case 'Discharge':
                this.launchPage(this.$t('discharge.Discharge'), 'Clinical-Discharge'); 
                break;
            case 'Diagnosis':
                this.launchPage(this.$t('MdPatientDiagCodes.Diagnosis'),'patient-diagnosis', true);
                break;
            case 'DailyNotes':
                this.launchPage(this.$t('DailyNoteList.DailyNotesTitle'),'Clinical-daily-note', true);
                break;
            case 'LogNotes':
                this.launchPage(this.$t('LogNoteList.LogNotesTitle'),'Clinical-log-note', true);
                break;
            case 'ClaimBatchList':
                this.GotoClaimBatchList();
                break;
            
            case 'DiagnosisClinical':
                this.launchPage(this.$t('MdPatientDiagCodes.Diagnosis'),'Clinical-diagnosis', false);
                break;

            case 'InsuranceClinical':
                this.launchPage(this.$tc('Insurance.Insurance'),'Clinical-insurance', false);
                break;

            case 'AllergyClinical':
                this.launchPage(this.$t('Allergy.PatientAllergies'),'Clinical-allergies', false);
                break;
            case 'incident-categories':
                this.launchPage(this.$t('ClinicalNotes.CATEGORIES'), 'incident-categories'); 
                break;
            case 'DocumentClinical':
                this.launchPage(this.$t('employee.Document'),'Clinical-document', false);
                break;
            case 'ContactClinical':
                this.launchPage(this.$tc('Contact.Contact'),'Clinical-cont', false);
                break;
            case 'ADMISSIONINFOCLINICAL':
                this.launchPage('Admission','admission-clinical', true);
                break;
            case 'TREATMENTPLAN':
                this.launchPage('Treatment Plan','treatmentplan-Clinical', false);
                break;
            case 'SchedulerClinical':
                this.launchPage('Scheduler Notes','SchedulerClinical', false);
                break;
            case 'Appointments':
                this.launchPage('APPOINTMENTS','patient-appoint', false);
                break;
            case 'ClinicalAppointments':
                this.launchPage('APPOINTMENTS','clinical-appoint', false);
                break;     
            default:
                break;
           }
        }
    },

    async GetPermission(){  
         if(this.$route.name.includes('appointment')){
                this.getCheckinsByActivityId();
                this.Appointment = await this.getAppointmentByActId(this.$route.params.actId); 
                !this.Appointment.private ? EventBus.$emit('onVisibleApptType', false) : EventBus.$emit('onVisibleApptType', true);           
            }
        this.GetItemsListAuthorized(); 
         
    },
          
    GotoPatientList(){
       this.$router.push('/app/patients').catch(()=>{});
    },

    GotoActivities(){        
        this.$router.push({ name: "schedulerdb-activitiesList", params: { id: this.$route.params.id,actId:this.$route.params.actId } }).catch(()=>{});
    },

    OpenAppointmentReason(text){       
        if(text === "Cancel"){
            if(!this.Appointment.private){
               // this.ClickedText = text;
                //this.$bvModal.show(this.modalReasonId);
                EventBus.$emit('onAppointmentReasonModal', text);   
            }else{               
                this.$bvToast.toast('The patient is present at the appointment and it cannot be canceled.', {
                    title: this.$t('MdNotesByAppt.Appointment'),
                    autoHideDelay: 5000,
                    variant: "warning"
                });
            }
           
        }else{
            this.CancelAppt(text,'');
        }
         
    },

    GotoGeneralNotes(){
      if(this.$route.params.id != ''){
        this.PatientCode = this.$route.params.id;
      }
      this.$router.push({ name: "patient-generalnotes", params: { id: this.PatientCode } }).catch(()=>{});
    },

    GotoSchedulerNotes(){   
      this.$router.push({ name: "scheduler-appointment-notes", params: { id: this.$route.params.id,actId:this.$route.params.actId } }).catch(()=>{});
    },

    GotoPatientInfo(){
      if(this.$route.params.id != ''){
        this.PatientCode = this.$route.params.id;
      }  

        const pRouteNames = ['patient', 'contact', 'allergy'];
        const cRouteNames = ['Clinical', 'vs', 'lognote-add', 'dailynote-add'];

        if (pRouteNames.some(route => this.$route.name.includes(route))) {
            if (this.$router.currentRoute.name === 'patient-details') {
                this.$bvToast.toast(this.message, {
                title: this.$t('Shared.Details'),
                autoHideDelay: 4000,
                variant: 'success',
                });
            } else {
                this.$router.push({ name: 'patient-details', params: { id: this.PatientCode } });
            }
        }
       
        else if(cRouteNames.some(route => this.$route.name.includes(route))){         
            if( this.$router.currentRoute.name == "Clinical-PatientInfo") {
                this.$bvToast.toast(this.message, {
                    title: this.$t('MdPatientInfoBox.PatientInfo'),
                    autoHideDelay: 4000,
                    variant: "success"
                  });
            } else {
                this.$router.push({ name: "Clinical-PatientInfo", params: { id: this.PatientCode,recordid:this.$route.params.recordid } })
                  .catch(()=>{});
            }
        }

    },

    GotoSchedulerDB(){       
        this.$router.push('/app/appointments').catch(()=>{});
    },

    GotoClinical(){
       this.$router.push('/app/clinical').catch(()=>{});
    },

    ExecuteShowUp(param,text){
        AppointmentService.ShowUp(param)
              .then((response) => {
                if(response.status == '200'){
                    this.GetPermission();
                    if(text === "Undo Show Up"){ 
                        this.$bvToast.toast('The patient is not present.', {
                         title: 'Appointment',
                          autoHideDelay: 5000,
                          variant: "success"
                        });
                    }else{
                        this.$bvToast.toast('confirmed the presence of the patient.', {
                         title: 'Appointment',
                          autoHideDelay: 5000,
                          variant: "success"
                        });
                    }
                   
                }
              })
              .catch(error => {
                if (!error.response) {               
                  this.showMessage("Appointment",this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage("Appointment",error,"error");
                }
              });      
       
    },

    getCheckinsByActivityId(){
        var param = {
            Code:this.$route.params.actId
        }
        ApptCheckinsService.getAll(param)
              .then((response) => {
                if(response.status == '200' && response.data.length > 0){
                    this.CheckInsAppt = response.data[0];                   
                }
              })
              .catch(error => {
                if (!error.response) {               
                  this.showMessage("Appointment",this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage("Appointment",error,"error");
                }
              });
    },

    ShowUpAppt(text){
        var param = {
            PatientCode : this.$route.params.id,
            ActivityId : this.$route.params.actId,
            Action:text,
            DoctorCode:this.Appointment.therapistcode,
        };

        if(text === "Undo Show Up"){           
            param.State = "F";
            if( (this.CheckInsAppt.timeCheckIn === "") || (this.CheckInsAppt.timeCheckIn === null)){
                this.ExecuteShowUp(param,text);
            }else{
                this.$bvToast.toast('The patient is already in the room.', {
                    title: 'Appointment',
                    autoHideDelay: 5000,
                    variant: "danger"
                });
            }            
        }
        else{
            param.State = "T";
            if( (this.Appointment.reason === "") || (this.Appointment.reason === null) ){
                this.ExecuteShowUp(param,text);
            }else{
                this.$bvToast.toast('The appointment is canceled.', {
                    title: 'Appointment',
                    autoHideDelay: 5000,
                    variant: "danger"
                });
            }
        }

    


    },

    ConfirmAppt(text){
        var param = {           
            ActivityIdList: this.$route.params.actId, // must be List          
        };

        if(text === "Undo Confirm"){           
            param.IsConfirmed = "F";
        }
        else{
            param.IsConfirmed = "T";
        }

        AppointmentService.ConfirmAppt(param)
              .then((response) => {
                if(response.status == '200'){
                    this.GetPermission();

                    if(text === "Undo Confirm"){ 
                        this.$bvToast.toast('The patient has not confirmed.', {
                         title: 'Appointment',
                          autoHideDelay: 5000,
                          variant: "success"
                        });
                    }else{
                        this.$bvToast.toast('The patient has confirmed.', {
                         title: 'Appointment',
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                    }

                   
                }
              })
              .catch(error => {
                if (!error.response) {               
                  this.showMessage("Appointment",this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage("Appointment",error,"error");
                }
              });
    },

    CancelAppt(text,reason){
        var param = {           
            ActivityIdList: this.$route.params.actId, // must be List          
        };

        if(text === "Cancel"){           
            param.Reason = reason;
        }
        else{
            param.Reason = "";
        }

        if (param.ActivityIdList) {
            AppointmentService.CancelAppt(param)
              .then((response) => {
                if(response.status == '200'){
                    this.GetPermission();

                    if(text === "Undo Cancel"){ 
                        this.$bvToast.toast('The cancellation of the appointment was undone.', {
                         title: 'Appointment',
                          autoHideDelay: 5000,
                          variant: "success"
                        });
                    }else{
                        this.$bvToast.toast('The appointment has been canceled.', {
                         title: 'Appointment',
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                    }
                }
              })
              .catch(error => {
                if (!error.response) {               
                  this.showMessage("Appointment",this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage("Appointment",error,"error");
                }
              });
        }
    },

    GotoClaimBatchList(){
      this.$router.push('/app/claimbatch').catch(()=>{});
    },

    launchPage(v_title, v_name, v_patient) {
      if(this.$route.params.id != ''){
        this.PatientCode = this.$route.params.id;
      }
      if (this.$router.currentRoute.name == v_name ) {
          this.$bvToast.toast(
            this.message,
            {
              title: v_title,
              autoHideDelay: 4000,
              variant: "success",
            }
          );
        } else {
           if(v_patient) {
                  this.$router.push({ name: v_name, params: { id: this.PatientCode }}).catch(()=>{});
              } else {
                  this.$router.push({ name: v_name, params: { id: this.PatientCode, recordid:this.$route.params.recordid }  }).catch(()=>{});
              }
            
        }
    },
    
    GetItemsListAuthorized(){
        this.ItemsListAuthorized = [];
        this.parameter.UserId = LocalStorage.getUserId(); 
       this.ItemsList.forEach(element => { 
        switch (element.value) {
          case 'PatientSearch':
              this.addMenuItem(element.text,element.value);
              break;
        case 'SchedulerNotes':           
            if(this.Appointment.private) {
                this.addMenuItem(element.text,element.value);
            }              
            break;
        case 'Activities':  
                this.addMenuItem(element.text,element.value);
                break;
        case 'ShowUpAppt': 
            if(this.Appointment.private){
                element.text = "Undo Show Up"
            }else{
                element.text = "Show Up"  
            }
            this.addMenuItem(element.text,element.value);                   
            break;
        case 'CancelAppt': 
            if(this.Appointment.reason !== null && this.Appointment.reason !== ""){
                element.text = "Undo Cancel"
            }else{
                element.text = "Cancel"  
            }
            this.addMenuItem(element.text,element.value);                   
            break;
        case 'ConfirmAppt': 
            if(this.Appointment.isConfirmed){
                element.text = "Undo Confirm"
            }else{
                element.text = "Confirm"  
            }
            this.addMenuItem(element.text,element.value);                   
            break;
        case 'AppointmentList':
              this.addMenuItem(element.text,element.value);
              break;
          case 'PatientInfo':
              if(this.$store.state.isUsePatientInfo) {
                 this.addMenuItem(element.text,element.value);
              }
              break;
          case 'PatientInsurance':
              if(this.$store.state.isUsePatientInsurance) {
                 this.addMenuItem(element.text,element.value);
              }
              break;
          
            case 'PatientDocument':
              if(this.$store.state.isUsePatientDocument) {
                 this.addMenuItem(element.text,element.value);
              }
              break;
          
          case 'PatientContact':
              if(this.$store.state.isUsePatientContact) {
                 this.addMenuItem(element.text,element.value);
              }
              break;
          case 'PatientAsam':
              if(this.$store.state.isUseASAM) {
                 this.addMenuItem(element.text,element.value);                      
              }
              break;
          case 'ClinicalAsam':
              if(this.$store.state.isUseASAM) {
                 this.addMenuItem(element.text,element.value);                      
              }
              break;
          case 'PatientListClinical':                    
              this.addMenuItem(element.text,element.value);
              break;
          case 'ClinicalNotes':
              if(this.$store.state.isUseClinicalNotes) {                    
                 this.addMenuItem(element.text,element.value);
              }
              break;
          case 'PatientAllergy':
              if(this.$store.state.isUsePatientAllergies) {
                this.addMenuItem(element.text,element.value);
              }
              break;
          case 'ClinicalVitalSign':
              if(this.$store.state.isUseVitalSign) {
                  this.addMenuItem(element.text,element.value);                      
              }
              break;
          case 'Medications':           
             if(this.$store.state.isUseMedications) {
                  this.addMenuItem(element.text,element.value);                      
              }             
              break;
          case 'Admission':           
              if(this.$store.state.isUsePatientAdmission) {
                  this.addMenuItem(element.text,element.value);                      
              }
              break;
          case 'CHARTREVIEWCLINICAL':
              if(this.$store.state.isUseChartReview) {
                  this.addMenuItem(element.text,element.value);                      
              }
              break; 
          case 'CHARTREVIEWPATIENT':
              if(this.$store.state.isUseChartReview) {
                  this.addMenuItem(element.text,element.value);                      
              }
              break;  
          case 'ePharmacyPatient':
              if(this.$store.state.isUsepharmacyPatientNewCrop) {
                  this.addMenuItem(element.text,element.value);                      
              }
             break; 
          case 'ePharmacyClinical':
              if(this.$store.state.isUsepharmacyPatientNewCrop) {
                  this.addMenuItem(element.text,element.value);                      
              }
              break;  
          case 'DailyNotes':
              if(this.$store.state.isUseDailyNotes) {
                  this.addMenuItem(element.text,element.value);                      
              }
              break;
          case 'LogNotes':
              if(this.$store.state.isUseLogNotes) {
                  this.addMenuItem(element.text,element.value);                      
              }
              break;
          case 'Transfer':
              if(this.$store.state.isUseTransfer) {
                  this.addMenuItem(element.text,element.value);                      
              }
              break;   
          case 'Discharge':
              if(this.$store.state.isUseDischarge) {
                  this.addMenuItem(element.text,element.value);                      
              }
              break;  
          case 'Diagnosis':
              if(this.$store.state.isUsePatientDiagnosis) {
                  this.addMenuItem(element.text,element.value);                      
              }
              break;  
           case 'ClaimBatchList':
              //if(this.$store.state.isUsePatientDiagnosis) {
                  this.addMenuItem(element.text,element.value);                      
              //}
              break; 
            case 'PatientInfoClaimBatch':
              //if(this.$store.state.isUsePatientDiagnosis) {
                  this.addMenuItem(element.text,element.value);                      
              //}
              break; 
            case 'DiagnosisWorksSheet':
              //if(this.$store.state.isUsePatientDiagnosis) {
                  this.addMenuItem(element.text,element.value);                      
              //}
              break;  
            case 'Doctors':
              //if(this.$store.state.isUsePatientDiagnosis) {
                  this.addMenuItem(element.text,element.value);                      
              //}
              break;    
            case 'Insurances':
              //if(this.$store.state.isUsePatientDiagnosis) {
                  this.addMenuItem(element.text,element.value);                      
              //}
              break;             
            case 'incident-report':
                this.addMenuItem(element.text,element.value);                      
                break;
            case 'incident-dashboard':
                this.addMenuItem(element.text,element.value);                      
                break;
            case 'incident-report-general-info':
                this.addMenuItem(element.text,element.value);                      
                break;
            case 'incident-categories':
                this.addMenuItem(element.text,element.value);                      
                break;
            case 'incident-involved':
                this.addMenuItem(element.text,element.value);                      
                break;
            case 'incident-participants':
                this.addMenuItem(element.text,element.value);                      
                break;
                case 'DiagnosisClinical': 
              if(this.$store.state.isUsePatientDiagnosis) {            
                  this.addMenuItem(element.text,element.value); 
              }
                  break; 
            case 'InsuranceClinical':
              if(this.$store.state.isUsePatientInsurance) {
                  this.addMenuItem(element.text,element.value); 
              }
              break;
            case 'AllergyClinical':
              if(this.$store.state.isUsePatientAllergies) {
                this.addMenuItem(element.text,element.value);
              }
              break;

            case 'ContactClinical':
              if(this.$store.state.isUsePatientContact) {
                 this.addMenuItem(element.text,element.value);
              }
              break;

            case 'DocumentClinical':
              if(this.$store.state.isUsePatientDocument) {
                 this.addMenuItem(element.text,element.value);
              }
              break; 
            case 'ADMISSIONINFOCLINICAL':
             if(this.$store.state.isUsePatientAdmission) {
                 this.addMenuItem(element.text,element.value);
             }
              break;             
            case 'TREATMENTPLAN':               
            if(this.$store.state.isUseTreatmentPlanClinical) {
                 this.addMenuItem(element.text,element.value);
             }
              break;
              case 'SchedulerClinical':
             if(this.$store.state.isUseSchedulerClinical) {
                 this.addMenuItem(element.text,element.value);
             }
              break;
              case 'Appointments':
              if(this.$store.state.isAppointments) {
                 this.addMenuItem(element.text,element.value);
             }
              break;
              case 'ClinicalAppointments':
              if(this.$store.state.isAppointments) {
                 this.addMenuItem(element.text,element.value);
             }
              break;  
           default:
              break;
        }
      });
    },
   addMenuItem(text,value) {
      let items = {};
      items['text'] = text;
      items['value'] = value;
      this.ItemsListAuthorized.push(items);
    },
},

created(){
    EventBus.$on("onSelectAppointmentReason", function (param) { 
		this.CancelAppt(param.Text,param.Reason);
    }.bind(this));
}, 

updated(){
this.itemdisabled = this.$attrs.itemdisabled;
this.PatientCode = this.$attrs.PatientCode;
},

beforeCreate(){
this.itemdisabled = this.$attrs.itemdisabled;
this.PatientCode = this.$attrs.PatientCode;
},

 mounted() {
    this.itemdisabled = this.$attrs.itemdisabled;
    this.PatientCode = this.$attrs.PatientCode;   
    this.ItemsList = this.$attrs.ItemList;   
     this.GetPermission();
 }
}
    

</script>

<style>
.isDisabled {
  cursor: not-allowed!important;
  opacity: 0.5!important;
}
.isDisabled > a {
  color: currentColor!important;
  display: inline-block!important;  /* For IE11/ MS Edge bug */
  pointer-events: none!important;
  text-decoration: none!important;
}
.rmActiveItem {
  background-position: -230px !important;
  color: #FFF !important;
}
</style>


