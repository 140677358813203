<template>
    <div id="ContainerPatient">
      <div class="row">
          <div class="col-12">
            <MdTitle v-bind:Text="'Department of Children Services Dashboard'"/>
            <div class="row">
                <div class="col-sm-12 col-md-3">                      
                    <MdButton
                      v-bind:Label="'Add Export'"
                      v-bind:ClassProp="false ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                      v-on:click="GetAddPatient"
                      v-bind:IconClass="'ti-plus pr-1'"
                      v-bind:isReadOnly="false"
                      v-bind:Name="'btn_add_patient'">
                    </MdButton>
                </div>
                <div class="col-sm-12 col-md-9 mb-4">
                    <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                        <div class="input-group">
                            <input 
                              type="text" 
                              class="form-control"
                              v-model="parameter.SearchCriteria"
                              @keyup.enter="SearchPatient()"
                            >
                            <div class="input-group-append">
                                <MdButton 
                                  v-bind:Name="'btnsearchinput'"
                                  v-bind:VariantProp="'btn btn-sm search-attached'"
                                  v-bind:IconClass="'mdi mdi-magnify'"
                                  v-on:click="SearchPatient">
                                </MdButton>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
            <b-toast id="error-toast" title="Error" static no-auto-hide>
              {{ error_toast }}  
            </b-toast>
          </div>
      </div>
      <div class="row">
          <div class="col-12">
              <div>
                <MdTitle v-bind:Text="'DCS List'" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/> 
                        <MdTable 
                        v-bind:fields="fields"
                        v-bind:items="items"
                        v-bind:totalRows="totalRows"
                        v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                        v-bind:currentPage="currentPage"
                        v-bind:perPage="perPage"
                        v-bind:pageOptions="pageOptions"
                        v-bind:refTable="'tablePatientdcs'"                      
                        v-bind:VisibleDownloadIcon="true"                       
                        :downloadIconEvent="DownloadIcon"                       
                        :onRowSelected="onRowSelected"
                        :onFiltered="onFiltered"
                        :pageChanged="pageChanged"
                        :perPageFunction="PerPageFunction">
                      </MdTable>                    
              </div>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  
  //import PatientService from '@/services/patient.service'
  import EnumService from '@/services/enum.service' 
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import DBFunctions from '@/common/DBFunctions.js'
  //import Utils from '@/common/utils'
  //import Patient from '@/components/pages/patients/Patient'
  
  export default {
    beforeRouteEnter(to, from, next) {
          next(async vm => {
              const iPI = await vm.CanView();
              if(iPI) next()
              else next('/accessdenied');
          }) 
    },
    mixins: [ShowMessage, DBFunctions],
    name: 'DcsList',
    components: {
     // Patient
    },
    data() {
      return { 
        search_criteria: "",
        error_toast: "",
        ShowMessageIsEmpty:false,
        Menu : this.$store.state.leftMenu,
        permission: [],
        Loading:true,
        Mode:'',  
        FieldsDateArr:['dob'],
        status:{
          selected:true,
          value:true,
          text:this.$t('Shared.Active')
        },
        PatientListStatus:EnumService.PatientListStatus,       
        parameter:{
          SearchCriteria:'',
          PageNumber : 0,
          PageSize:0,
          Status: true,       
        },
        OptionsRightMenu:{
              visible:true,
              ItemsList:[],
              btnvisible:false,
              itemdisabled: false,
              PatientCode:'',
              Mode:this.edit_mode,
              favButtonAtion:false          
          },
       
        fields: [
          { key: 'year', sortable: true, label: 'Year', class: 'text-rigth' },  
          { key: 'month', sortable: true, label: 'Month', class: 'text-rigth' },
          { key: 'version', sortable: true, label: 'Version', class: 'text-rigth' },
          { key: 'totalcharges', sortable: true, label: 'Total Charges', class: 'text-rigth' },  
          { key: 'notransaction', sortable: true, label: 'Transactions', class: 'text-rigth' },
          { key: 'exporteddate', sortable: true, label: 'Exported Date', class: 'text-rigth' }, 
          { key: 'groupCreationId', sortable: true, label: 'Group Creation ID', class: 'text-rigth' }, 
          ],
  
        pageOptions: [5, 10, 15 ],
        totalRows: 4,
        currentPage: 1,
        perPage: 10,
        filter: null,
        filterOn: [],
        items: [
            {
                groupCreationId:"433338909017261 ",
                month:"November",
                year:"2023",
                version:"1",
                notransaction:"180",
                totalcharges:"$1,450.00", 
                exporteddate:"11/30/2023 04:46 PM"  
            },
            {
                groupCreationId:"451574070709265 ",
                month:"October",
                year:"2023",
                version:"1",
                notransaction:"150",
                totalcharges:"$850.00", 
                exporteddate:"10/30/2023 11:45 AM"  
            },
            {
                groupCreationId:"452644483707061 ",
                month:"September",
                year:"2023",
                version:"2",
                notransaction:"160",
                totalcharges:"$950.00", 
                exporteddate:"09/30/2023 04:16 PM"  
            }, 
            {
                groupCreationId:"440416073665168 ",
                month:"September",
                year:"2023",
                version:"1",
                notransaction:"120",
                totalcharges:"$450.00", 
                exporteddate:"09/30/2023 10:30 AM"  
            },                                   
            {
                groupCreationId:"449055684833687 ",
                month:"August",
                year:"2023",
                version:"1",
                notransaction:"110",
                totalcharges:"$950.00", 
                exporteddate:"08/31/2023 09:30 AM"  
            }
        ],
        selected: [],
        Header:{},
        isReadOnly: false,
        refTable: 'selectableTable',
        disableAddPatient: false
      }
    },
  
    computed: {
        sortOptions() {
          // Create an options list from our fields
          return this.fields
            .filter(f => f.sortable)
            .map(f => {
              return { text: f.label, value: f.key }
            });
        }
    },
      
    methods: {
  
      pageChanged (page) {            
        this.currentPage = page;
        //this.getPatients();
      },
  
      CanView(){
       // return this.permission[0].roleCanView;
       return true;
    },
  
      PerPageFunction(perpage){
        this.perPage = perpage;
        this.currentPage = 1;
       // this.getPatients();
      },
  
     /* getPatients() {               
        this.parameter.PageSize = this.perPage;
         this.parameter.PageNumber = this.currentPage;   
        this.parameter.Status = this.status.value;
        if(this.permission.length > 0 && this.permission[0].roleCanView){
        this.$emit('load', true)
        PatientService.getAll(this.parameter)
        .then((response) => {
         if(response.status == '200' && response.data && response.data.length > 0){
          this.items = response.data; 
          this.items.forEach(function(element) {
                          if (element.dob)	
                element.dob = Utils.formatterDateToString(element.dob); 
                
              element.PatientName = element.firstname + " " + element.lastname;   
            });
           this.$emit('load', false);
          let headerString = response.headers["x-pagination"];
          this.Header = JSON.parse(headerString);
          this.totalRows = this.Header.TotalCount;
          }
          //this.Loading = false;
          this.$emit('load', false);
          if(this.items.length === 0){
            this.ShowMessageIsEmpty = true;
          } 
        })
        .catch(error => {
          //this.Loading = false;
          this.$emit('load', false);
          if (!error.response) {
              // network error
              this.showMessage(this.$tc('Shared.Patient',2),this.$t('Msg.retrieveInfo'),"error");
          } else {
              this.showMessage(this.$tc('Shared.Patient',2),error,"error");
          }
        });
      }
      },*/
  
      /*PatientSummarie(item) {
        this.Mode= "Edit";
        this.$router.push({ name: "patient-summary", params: { id: item.patientcode } });
  
        this.OptionsRightMenu.btnvisible = true;
        this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
        // console.log(this.OptionsRightMenu.ItemsList)
  
        let layoutStatus = this.$store.state.layoutStatus;
        switch(layoutStatus) {
          case 'L0R0':
              this.$store.commit('setlayoutStatus', 'L0R1');
          break;
          default:
              this.$store.commit('setlayoutStatus', 'L1R1');
        }
        this.$emit('getVisibleMenu', this.OptionsRightMenu); 
      },*/
  
      focusInput() {
        this.$refs.SearchPatient.focus();
      },

      DownloadIcon(row){  
        console.log(row);  
    //  this.getDocument(row);
    },

      onRowSelected(items) {
        this.selected = items
      },
  
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },

      Error(row){
        console.log(row);
     /* this.TitleError = this.$t('claimbatchList.ClaimError') + " - "+row.patientName +" - " + row.patientCode + " - "+ row.dateFrom + " - "+ row.dateTo + " - "+ " " + this.$t('Shared.CPT') + " "+row.courseCode;
      this.ErrorList = row.errorList; 
     var iArray = [];
     for(var i = 0; i < this.ErrorList.length; i++){
       iArray.push({description:this.ErrorList[i]});
     }

      EventBus.$emit('onClaimError', iArray);
      this.$bvModal.show(this.modalIdError);   */   
    },

    Warning(row){
        console.log(row);
     /* this.TitleWarning = this.$t('claimbatchList.ClaimWarning') + " - "+row.patientName +" - " + row.patientCode + " - "+ row.dateFrom + " - "+ row.dateTo + " - "+ " " + this.$t('Shared.CPT') + " "+row.courseCode;
       this.WarningList = row.warningList; 
     var iArray = [];
     for(var i = 0; i < this.WarningList.length; i++){
       iArray.push({description:this.WarningList[i]});
     }

      EventBus.$emit('onClaimWarning', iArray);
      this.$bvModal.show(this.modalIdWarning);  */
    },
  
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
  
      selectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.selectRow(2)
      },
  
      unselectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.unselectRow(2)
      },
  
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
  
      GetAddPatient(){
        this.Mode="Add"; 
        this.OptionsRightMenu.btnvisible = true;
        this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
        this.OptionsRightMenu.Mode = "Add";
        this.$emit('getBtnRightMenu', this.OptionsRightMenu);      
        this.$router.push({ name: "dcs-add"}).catch(()=>{});
      },
  
      info(item, index) {
        alert(item.FirstName,index);
      },
  
      SearchPatient(){
        this.items = []
        this.currentPage = 1;
        this.parameter.PageNumber = 1;
        this.totalRows = this.items.length;
        this.getPatients();
      },
  
      ChangeStatus(status){
       // this.status.value = status ? status.value : '';
        this.status = { value:''};
        if(status){
             this.status.value = status.value;
             this.status.text = status.text;
             this.status.selected = status.selected;
        }
        this.items = [];
        this.totalRows = 0;
        this.getPatients();
      }
    },
  
    created(){
     /*await this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
        if(this.permission.length == 0){
          this.$emit('load', false);
          this.$router.push({name: "Main"});    
            setTimeout(() => 
              this.showPermissionMessage()
            ,10);  
        }*/
    },
  
    mounted() {
     // this.getPatients();
      this.OptionsRightMenu.ItemsList = []
      let layoutStatus = this.$store.state.layoutStatus;
      switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = true;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
     
     // this.isReadOnly = this.$store.state.isPatientInfoEHRReadOnly
    //  this.disableAddPatient = this.$store.state.isUsePatientInfo
    },
  
    updated() {
      this.OptionsRightMenu.btnvisible = false;
      this.OptionsRightMenu.ItemsList = [];
      this.$emit('getBtnRightMenu', this.OptionsRightMenu);    
    },
  
  };
  </script>
  
  <style>
   @import '../../../assets/css/Patients/PatientList.css';
  </style>
  